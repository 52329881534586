export function getUserPermissions() {
  return localStorage.getItem('userPermissions')
}

export function checkUserPermissions(permission, additionalPermissions = []) {
  let addPermissions = []

  if (additionalPermissions) {
    addPermissions = additionalPermissions
  }
  const mergedPermissions = [
    ...new Set([...JSON.parse(getUserPermissions()), ...addPermissions]),
  ]

  // console.log('ADDITIONAL PERMISSIONS:', addPermissions)

  return mergedPermissions.includes(permission)
}

export function getNavbarLinkPrefix(user) {
  if (user === 'admin') {
    return '/admin'
  }
  return '/request'
}
