import React from 'react'

import './Navbarmodule.css'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import GridViewIcon from '@mui/icons-material/GridView'
import SettingsIcon from '@mui/icons-material/Settings'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import { Link } from 'react-router-dom'
import { Report, Support } from '@mui/icons-material'
import { checkUserPermissions } from '../../utils/UserUtils'

const NavBar = ({ user }) => {
  const canViewContracts = checkUserPermissions('menu.contract')
  const canViewUsers = checkUserPermissions('menu.user')
  const canViewSupport = checkUserPermissions('menu.support')
  const canViewReports = checkUserPermissions('menu.report')
  return (
    // <div className="p-3">
    //   <div className="navstyle  mt-3 mt-sm-0 m-0">
    //     {user == 'admin' ? (
    //       <>
    //         <div className="nav-item py-sm-0 mt-2">
    //           <Link
    //             to="/admin/request"
    //             className={`${
    //               window.location.href.includes('/admin/request')
    //                 ? 'activeNav'
    //                 : 'nav-link'
    //             }`}
    //             aria-current="page"
    //           >
    //             <TextSnippetIcon />{' '}
    //             <span className="d-none d-sm-inline">Contracts</span>
    //           </Link>
    //         </div>
    //         <div className="nav-item py-sm-0 mt-2">
    //           <Link
    //             to="/admin/candidate-list"
    //             className={`${
    //               window.location.href.includes('/admin/candidate')
    //                 ? 'activeNav'
    //                 : 'nav-link'
    //             }`}
    //             aria-current="page"
    //           >
    //             <PersonSearchIcon />{' '}
    //             <span className="d-none d-sm-inline">Users</span>
    //           </Link>
    //         </div>
    //         <div className="nav-item py-sm-0 mt-2">
    //           <Link
    //             to="/admin/support"
    //             className={`${
    //               window.location.href.includes('/admin/support')
    //                 ? 'activeNav'
    //                 : 'nav-link'
    //             }`}
    //             aria-current="page"
    //           >
    //             <Support /> <span className="d-none d-sm-inline">Support</span>
    //           </Link>
    //         </div>
    //         <div className="nav-item py-sm-0 mt2">
    //           <Link
    //             to="/admin/reports/detailed"
    //             className={`${
    //               window.location.href.includes('/admin/reports/detailed')
    //                 ? 'activeNav'
    //                 : 'nav-link'
    //             }`}
    //             aria-current="page"
    //           >
    //             <Report /> <span className="d-none d-sm-inline">Reports</span>
    //           </Link>
    //         </div>
    //         {/* <div className="nav-item py-sm-0 mt2">
    //           <Link
    //             to="/admin/reports/summary"
    //             className={`${
    //               window.location.href.includes('/admin/reports/summary')
    //                 ? 'activeNav'
    //                 : 'nav-link'
    //             }`}
    //             aria-current="page"
    //           >
    //             <Report />{' '}
    //             <span className="d-none d-sm-inline">Summary Reports</span>
    //           </Link>
    //         </div> */}
    //       </>
    //     ) : (
    //       <>
    //         <div className="nav-item py-sm-0 mt-2">
    //           <Link
    //             to="/request/request-list"
    //             className={`${
    //               window.location.href.includes('/request')
    //                 ? 'activeNav'
    //                 : 'nav-link'
    //             }`}
    //             aria-current="page"
    //           >
    //             <TextSnippetIcon />{' '}
    //             <span className="d-none d-sm-inline">Contracts</span>
    //           </Link>
    //         </div>
    //       </>
    //     )}
    //   </div>
    // </div>
    <nav className="p-2 px-lg-4 py-lg-3">
      {canViewContracts && (
        <Link
          to={user === 'admin' ? '/admin/request' : '/request/request-list'}
          className={
            window.location.href.includes('/admin/request')
              ? 'activeNav'
              : 'nav-link'
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            width: '100%',
          }}
        >
          <TextSnippetIcon />
          <span className="d-none d-lg-inline">Contracts</span>
        </Link>
      )}
      {canViewUsers && (
        <>
          <Link
            to="/admin/candidate-list"
            className={
              window.location.href.includes('/admin/candidate')
                ? 'activeNav'
                : 'nav-link'
            }
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              width: '100%',
            }}
          >
            <PersonSearchIcon />
            <span className="d-none d-lg-inline">Users</span>
          </Link>
        </>
      )}
      {canViewSupport && (
        <>
          <Link
            to="/admin/support"
            className={
              window.location.href.includes('/admin/support')
                ? 'activeNav'
                : 'nav-link'
            }
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              width: '100%',
            }}
          >
            <Support />
            <span className="d-none d-lg-inline">Support</span>
          </Link>
        </>
      )}
      {canViewReports && (
        <>
          <Link
            to="/admin/reports/detailed"
            className={
              window.location.href.includes('/admin/reports/detailed')
                ? 'activeNav'
                : 'nav-link'
            }
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              width: '100%',
            }}
          >
            <Report />
            <span className="d-none d-lg-inline">Reports</span>
          </Link>
        </>
      )}
    </nav>
  )
}

export default NavBar
