import { Formik } from "formik";
import React, { memo } from "react";
import TextField from "../TextField/TextField";
import ReactSelectField from "../ReactSelectField/ReactSelectField";
import {
  MHFAGroupsList,
  customStyles,
  dietaryRestriction,
  mfaConnect,
  usStatesList,
} from "../../utils/common";
import RadioButton from "../RadioButton/RadioButton";
import Button from "../Button/Button";
import Spinner from "../Spinner/Spinner";
import ReactSelect from "react-select";
import PropTypes from "prop-types";
import { userRegistrationFormSchema } from "../../utils/ValidationUtil";

const UserRegistrationForm = ({
  email,
  userInformation,
  handleRegistration,
  loading,
}) => {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          email,
          firstName: userInformation ? userInformation.first_name : "",
          lastName: userInformation ? userInformation.last_name : "",
          mhfaConnect: userInformation?.mhfa_connect
            ? {
                value: userInformation?.mhfa_connect,
                label: userInformation?.mhfa_connect,
              }
            : "",
          addressline1: userInformation ? userInformation.addressline1 : "",
          addressline2: userInformation ? userInformation.addressline2 : "",
          state: userInformation?.state
            ? {
                value: userInformation?.state,
                label: userInformation?.state,
              }
            : "",
          city: userInformation?.city,
          zipcode: userInformation?.zipcode,
          phoneNumber: userInformation?.phone_number,
          mfaOptions: userInformation?.mfa_options
            ? {
                label: userInformation.mfa_options,
                value: userInformation.mfa_options,
              }
            : MHFAGroupsList[0],
          isPartOfOrganization:
            userInformation?.is_part_of_organization ?? false,
          organization: userInformation?.organization,
          jobTitle: userInformation?.job_title,
          getNewsletter: userInformation?.get_news_letter ?? false,
          publicProfile:
            userInformation?.public_profile === "true" ? true : false,
          dietaryRestrictions: userInformation
            ? {
                label: userInformation.dietary_restrictions,
                value: userInformation.dietary_restrictions,
              }
            : "",
          describeDietaryRestrictions:
            userInformation?.describe_dietary_restrictions,
          groupId: userInformation?.groupId,
        }}
        validationSchema={userRegistrationFormSchema}
        onSubmit={(values) => {
          handleRegistration(values);
        }}
      >
        {({
          errors,
          values,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="container p-3">
                <div className="row">
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <TextField
                      name="email"
                      placeholder="Email"
                      value={
                        values?.email
                          ? values?.email?.trim()?.toLowerCase()
                          : "test@test.com"
                      }
                      label="Email address"
                      disabled
                      error={errors.email}
                      touched={touched.email}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <TextField
                      id="firstName"
                      name="firstName"
                      placeholder="firstName"
                      value={values.firstName}
                      label="First Name *"
                      error={errors.firstName}
                      touched={touched.firstName}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <TextField
                      id="lastName"
                      name="lastName"
                      placeholder="lastName"
                      value={values.lastName}
                      label="Last Name"
                      error={errors.lastName}
                      touched={touched.lastName}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <TextField
                      name="addressline1"
                      placeholder="Work Address Line 1"
                      label="Work Address Line 1 *"
                      value={values.addressline1}
                      error={errors.addressline1}
                      touched={touched.addressline1}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <TextField
                      name="addressline2"
                      placeholder="Work Address Line 2"
                      label="Work Address Line 2"
                      value={values.addressline2}
                      error={errors.addressline2}
                      touched={touched.addressline2}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <TextField
                      name="city"
                      placeholder="Work Address City"
                      label="Work Address City *"
                      value={values.city}
                      error={errors.city}
                      touched={touched.city}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <ReactSelectField
                      label="Work Address State *"
                      placeholder="Work Address State *"
                      clear={false}
                      inputId="state"
                      mandatory={1}
                      error={errors.state}
                      touched={touched.state}
                      value={values.state}
                      options={usStatesList}
                      onChange={(text) => {
                        setFieldValue("state", text);
                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <TextField
                      name="zipcode"
                      placeholder="Work Address Zip Code"
                      label="Work Address Zip Code *"
                      value={values.zipcode}
                      error={errors.zipcode}
                      touched={touched.zipcode}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <TextField
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      value={values.phoneNumber}
                      label="Phone Number *"
                      error={errors.phoneNumber}
                      touched={touched.phoneNumber}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <b className="inputLabel">
                      Do you teach MHFA in or for one the following groups?
                      (Select N/A if you do not teach MHFA in or for any of the
                      groups) *
                    </b>

                    <ReactSelect
                      className={`${
                        errors.mfaOptions &&
                        touched.mfaOptions &&
                        "focusInputError"
                      }`}
                      inputId="groupId"
                      value={values.mfaOptions}
                      placeholder="Teaching for Organization or Independently"
                      options={MHFAGroupsList}
                      handleBlur={handleBlur}
                      name="mfaOptions"
                      onChange={(text) => {
                        if (text) setFieldValue("mfaOptions", text);
                      }}
                      styles={customStyles}
                    />
                    {errors.mfaOptions && touched.mfaOptions && (
                      <p className="error-message">{errors.mfaOptions}</p>
                    )}
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6 mb-3  d-flex flex-column">
                    <b className="inputLabel">
                      Are you a part of National Council for Mental Wellbeing
                      member organization?
                    </b>
                    <div className="d-flex  ">
                      <RadioButton
                        id="yes"
                        error={errors.isPartOfOrganization}
                        name="isPartOfOrganization"
                        value={values.isPartOfOrganization}
                        setFieldValue={setFieldValue}
                        checkBoxLabel="Yes"
                        val={true}
                      />
                      <RadioButton
                        error={errors.isPartOfOrganization}
                        name="isPartOfOrganization"
                        id="no"
                        value={values.isPartOfOrganization}
                        setFieldValue={setFieldValue}
                        checkBoxLabel="No"
                        val={false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <TextField
                      name="organization"
                      placeholder="Organization"
                      label="Organization"
                      value={values.organization}
                      error={errors.organization}
                      touched={touched.organization}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      disabled={!values.isPartOfOrganization}
                    />
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <ReactSelectField
                      label="What brings you to MHFA Connect? *"
                      placeholder="What brings you to MHFA Connect? *"
                      clear={false}
                      inputId="mhfaConnect"
                      mandatory={1}
                      error={errors.mhfaConnect}
                      touched={touched.mhfaConnect}
                      value={values.mhfaConnect}
                      options={mfaConnect}
                      onChange={(text) => {
                        if (text) setFieldValue("mhfaConnect", text);
                      }}
                      name="mhfaConnect"
                    />
                  </div>
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <b className="inputLabel">
                      Instructor Dietary Restrictions
                    </b>
                    <ReactSelect
                      className={`${
                        errors.mfaOptions &&
                        touched.mfaOptions &&
                        "focusInputError"
                      }`}
                      inputId="dietaryRestrictions"
                      options={dietaryRestriction}
                      onChange={(text) => {
                        if (text) setFieldValue("dietaryRestrictions", text);
                      }}
                      name="dietaryRestrictions"
                      placeholder="Instructor Dietary Restrictions"
                      value={values.dietaryRestrictions}
                      error={errors.dietaryRestrictions}
                      touched={touched.dietaryRestrictions}
                      handleBlur={handleBlur}
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3  d-flex flex-column">
                    <b className="inputLabel">Instructor Public Profile</b>
                    <div className="d-flex  ">
                      <RadioButton
                        id="publicProfile1"
                        error={errors.publicProfile}
                        name="publicProfile"
                        value={values.publicProfile}
                        setFieldValue={setFieldValue}
                        checkBoxLabel="Yes"
                        val={true}
                      />
                      <RadioButton
                        error={errors.publicProfile}
                        name="publicProfile"
                        id="publicProfile2"
                        value={values.publicProfile}
                        setFieldValue={setFieldValue}
                        checkBoxLabel="No"
                        val={false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <TextField
                      name="jobTitle"
                      placeholder="Job Title"
                      label="Job Title"
                      value={values.jobTitle}
                      error={errors.jobTitle}
                      touched={touched.jobTitle}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 flex-column d-flex">
                    <TextField
                      name="describeDietaryRestrictions"
                      placeholder="Instructor Dietary Restrictions - If Allergies/Other Preferences ,please describe"
                      label="Instructor Dietary Restrictions - If Allergies/Other Preferences ,please describe"
                      value={values.describeDietaryRestrictions}
                      error={errors.describeDietaryRestrictions}
                      touched={touched.describeDietaryRestrictions}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3  d-flex flex-column">
                    <b className="inputLabel">
                      Would you like to recieve news and updates from MHFA and
                      the National Council for Mental WellBeing
                    </b>
                    <div className="d-flex  ">
                      <RadioButton
                        id="yes1"
                        name="getNewsletter"
                        setFieldValue={setFieldValue}
                        checkBoxLabel="Yes"
                        val={true}
                        value={values.getNewsletter}
                      />
                      <RadioButton
                        name="getNewsletter"
                        id="no1"
                        setFieldValue={setFieldValue}
                        checkBoxLabel="No"
                        val={false}
                        value={values.getNewsletter}
                      />
                    </div>
                  </div>
                </div>
                <Button type="submit">
                  {loading ? (
                    <Spinner
                      size="20px"
                      sx={{
                        ".MuiCircularProgress-root": {
                          color: "white",
                        },
                      }}
                      thickness={5}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
UserRegistrationForm.propTypes = {
  email: PropTypes.string,
  userInformation: PropTypes.object,
  handleRegistration: PropTypes.func,
  loading: PropTypes.bool,
};

export default memo(UserRegistrationForm);
