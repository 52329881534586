/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from 'prop-types'
import React from 'react'
import './style.css'

export const Chip = ({
  status,
  className,
  divClassName,
  text = 'Submitted',
}) => {
  return (
    <div className={`chip ${status}`}>
      <div className={`completed `}>
        {status === 'accepted' && <>Completed</>}
        {status === 'completed' && <>Completed</>}
        {status === 'rejected' && <>Rejected</>}
        {status === 'submission' && <>Submitted</>}
        {status === 'failed' && <>Failed</>}
        {status === 'pending' && <>Pending</>}
        {status === 'contracted' && <>Contracted</>}
        {status === 'notcontracted' && <>Not Contracted</>}
        {status === 'scheduling' && <>Scheduling</>}
        {status === 'enrolling' && <>Enrolling</>}
        {status === 'cancelled' && <>Cancelled</>}
        {status === 'clicked' && <>Clicked</>}
        {status === 'passed' && <>Passed</>}
        {status === 'invited' && <>Invited</>}
        {status === 'enrolled' && <>Enrolled</>}
        {status === 'not_sent' && <>Not Sent</>}
        {status === 'started' && <>Started</>}
      </div>
    </div>
  )
}

Chip.propTypes = {
  status: PropTypes.oneOf([
    'submission',
    'completed',
    'accepted',
    'rejected',
    'failed',
    'pending',
    'contracted',
    'notContracted',
    'scheduling',
    'enrolling',
    'cancelled',
    'clicked',
    'passed',
    'invited',
    'enrolled',
    'not_sent',
  ]),
  text: PropTypes.string,
}
