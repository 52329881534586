import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { Chip } from '../../components/Chip'
import Table from '../../components/Table/Table'
import Spinner from '../../components/Spinner/Spinner'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import api, {
  GET_TRAINING_BY_ID,
  GET_LEARNER_BY_TRAINING_ID,
  Create_Learner,
  Delete_Learner,
  Update_Learner,
  RESEND_INVITE,
  INVITE_ALL_LEARNER,
  UPLOAD_CSV_MUTATION,
  GET_USER_CERTIFICATES,
} from '../../api/graphql'

import Header from '../../components/Header/Header'
import './AdminTrainingViewModule.css'
import { checkEmailRegex } from '../../utils/ValidationUtil'
import DeleteIcon from '@mui/icons-material/DeleteForever'

import Button from '../../components/Button/Button'
import MenuListComposition from '../../components/MenuBar'
import { useMutation, useQuery } from '@apollo/client'
import NavBar from '../../components/Navbar/Navbar'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import {
  Modal,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material'
import TextField from '../../components/TextField/TextField'
import { toast } from 'react-toastify'
import moment from 'moment'
import { MoreVert } from '@mui/icons-material'
import ActionMenu from '../../components/ActionsMenu/ActionMenu'
import UploadCSV from './UploadCSV'
import { checkUserPermissions } from '../../utils/UserUtils'

const FormSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().required('Last Name is required'),
  email: Yup.string()
    .email()
    .trim()
    .test('is-valid-email', 'Invalid email format', (value) => {
      // This regex checks for the presence of a period in the domain part of the email
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
      return emailRegex.test(value)
    })
    .required('Email is required'),
})

const AdminTrainingView = () => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState()
  const [learnerData, setLearnerData] = useState()
  const [uploadCSVLoad, setUploadCSVLoad] = useState(false)
  const [csvOpen, setCSVOpen] = useState(false)
  const [editItem, setEditItem] = useState({})
  const [uploadCSV, { loading: csvUploadLoading }] =
    useMutation(UPLOAD_CSV_MUTATION)
  const [openUploadCSV, setOpenUploadCSV] = useState(false)
  const [csvData, setCSVData] = useState([])
  const [csvError, setCsvError] = useState([])

  const [anchorEl, setAnchorEl] = useState(null)
  const openMenuBar = Boolean(anchorEl)

  const userType = localStorage.getItem('userType')

  const location = useLocation()
  const navigate = useNavigate()
  const { loading: trainingLoading, refetch } = useQuery(GET_TRAINING_BY_ID, {
    variables: {
      trainingId: location.state.id,
    },
  })
  const { loading: learnerLoading, refetch: refetchLearner } = useQuery(
    GET_LEARNER_BY_TRAINING_ID,
    {
      variables: {
        trainingId: location.state.id,
      },
    }
  )
  const [CREATE_LEARNER, { loading }] = useMutation(Create_Learner)
  const [UPDATE, { loading: updateLearner }] = useMutation(Update_Learner)
  const [RESEND, { loading: resendLearner }] = useMutation(RESEND_INVITE)
  const [INVITE_ALL, { loading: loadingInvite }] =
    useMutation(INVITE_ALL_LEARNER)

  const [canCSVSubmit, setCanCSVSubmit] = useState(false)

  const { loading: certificatesLoading, refetch: fetchCertificates } = useQuery(
    GET_USER_CERTIFICATES
  )

  const [preReqChecks, setPreReqChecks] = useState([])
  const [preReqErrors, setPreReqErrors] = useState([])
  const [preReqLoading, setPreReqLoading] = useState(false)

  useEffect(() => {
    fetchAllUserCerts()
  }, [learnerData])

  function getCellColor(status) {
    return status ? 'transparent' : 'transparent'
  }

  async function fetchAllUserCerts() {
    if (learnerData?.length > 0) {
      const checksPerformed = []
      const certErrors = []
      setPreReqErrors([])
      try {
        setPreReqLoading(true)
        await learnerData?.map(async (item) => {
          // if item.user_is not in checks performed
          if (!checksPerformed.find((i) => item.user_id === i.userId)) {
            console.log('CHECKING ITEM', item)
            const certData = await fetchCertificates({
              userId: item?.user_id,
            })
            if (item?.waive_precheck) {
              console.log('THIS ITEM IS PASSED', item)
              checksPerformed.push({
                userId: item?.user_id,
                data: certData?.data?.GetUserCertificates?.data,
                passed: true,
              })
            } else {
              // NOTE: HERE IS THE PRE_REQ CHECK
              if (
                !certData?.data?.GetUserCertificates?.data?.find(
                  (i) =>
                    parseInt(i?.certificate_id) ===
                    parseInt(data?.training_course_id)
                )
              ) {
                certErrors.push(`Prerequisite not found for ${item?.email}.`)
              }
              checksPerformed.push({
                userId: item?.user_id,
                data: certData?.data?.GetUserCertificates?.data,
                passed: certData?.data?.GetUserCertificates?.data?.find(
                  (i) =>
                    parseInt(i?.certificate_id) ===
                    parseInt(data?.training_course_id)
                ),
              })
            }

            // wait 3 seconds and then do a function
            await setTimeout(() => {
              setPreReqChecks(checksPerformed)
              setPreReqLoading(false)
              setPreReqErrors(certErrors)
            }, 5000)
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleClose = () => {
    setEditItem({})
    setOpen(false)
  }

  const handleCloseCSV = () => {
    setOpenUploadCSV(false)
  }

  const handleEmailClick = (email) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        console.log('Email copied to clipboard')
      })
      .catch((error) => {
        console.error('Failed to copy email to clipboard:', error)
      })
  }

  function isActionMenu() {
    if (checkUserPermissions('training.copy_invite', data?.permissions || [])) {
      return true
    } else if (
      checkUserPermissions('training.resend_invite', data?.permissions || [])
    ) {
      return true
    } else if (
      checkUserPermissions('training.edit_learner', data?.permissions || [])
    ) {
      return true
    } else if (
      checkUserPermissions('training.delete_learner', data?.permissions || [])
    ) {
      return true
    } else {
      return false
    }
  }

  const sampleColumns = isActionMenu()
    ? [
        {
          path: 'index',
          header: '#',
          width: '5%',
          content: (item) => (
            <div
              style={{
                backgroundColor: getCellColor(
                  !preReqChecks.find((i) => i.userId === item.user_id)?.passed
                ),
              }}
            >
              {item?.index}
            </div>
          ),
        },
        {
          path: 'name',
          header: "Learner's Name",
          width: '5%',
          content: (item) => (
            <div
              style={{
                backgroundColor: getCellColor(
                  !preReqChecks.find((i) => i.userId === item.user_id)?.passed
                ),
              }}
            >
              {item?.name}
            </div>
          ),
          // icon: <ImportExportIcon fontSize="12" />,
        },
        {
          path: 'email',
          header: "Learner's Email",
          width: '5%',
          // icon: <ImportExportIcon fontSize="12" />,
          content: (item) => (
            <div
              style={{
                cursor: 'pointer',
                backgroundColor: getCellColor(
                  !preReqChecks.find((i) => i.userId === item.user_id)?.passed
                ),
              }}
              onClick={() => handleEmailClick(item.email)}
            >
              {item.email}
            </div>
          ),
        },

        {
          path: 'status',
          header: 'Status',
          width: '5%',
          // icon: <ImportExportIcon fontSize="12" />,
          content: (item) => (
            <Chip
              className="chip-instance"
              divClassName="chip-2"
              status={item?.status?.toLowerCase()}
              text={item.status}
            />
          ),
        },
        {
          path: 'id',
          header: 'Actions',
          width: '5%',
          content: (item) => (
            <>
              <MenuListComposition
                handleSendAgain={(e) => handleSendAgain(item)}
                handleEditLearner={(e) => handleEditLearner(item)}
                handleDeleteLearner={(e) => handleDeleteLearner(item?.id)}
                copyInviteLink={(e) => copyInviteLink(item)}
                additionalPermissions={data?.permissions || []}
              />
            </>
          ),
        },
      ]
    : [
        {
          path: 'index',
          header: '#',
          width: '5%',
          content: (item) => (
            <div
              style={{
                backgroundColor: getCellColor(
                  !preReqChecks.find((i) => i.userId === item.user_id)?.passed
                ),
              }}
            >
              {item?.index}
            </div>
          ),
        },
        {
          path: 'name',
          header: "Learner's Name",
          width: '5%',
          content: (item) => (
            <div
              style={{
                backgroundColor: getCellColor(
                  !preReqChecks.find((i) => i.userId === item.user_id)?.passed
                ),
              }}
            >
              {item?.name}
            </div>
          ),
          // icon: <ImportExportIcon fontSize="12" />,
        },
        {
          path: 'email',
          header: "Learner's Email",
          width: '5%',
          // icon: <ImportExportIcon fontSize="12" />,
          content: (item) => (
            <div
              style={{
                cursor: 'pointer',
                backgroundColor: getCellColor(
                  !preReqChecks.find((i) => i.userId === item.user_id)?.passed
                ),
              }}
              onClick={() => handleEmailClick(item.email)}
            >
              {item.email}
            </div>
          ),
        },

        {
          path: 'status',
          header: 'Status',
          width: '5%',
          // icon: <ImportExportIcon fontSize="12" />,
          content: (item) => (
            <Chip
              className="chip-instance"
              divClassName="chip-2"
              status={item?.status?.toLowerCase()}
              text={item.status}
            />
          ),
        },
      ]

  const handleSendPendingInvites = async () => {
    const { data } = await INVITE_ALL({
      variables: {
        trainingId: location.state.id,
      },
    })
    console.log(data)

    if (data?.inviteAllLearner?.success) {
      getList()
    } else {
      toast.error(data.InviteAllLearner.error)
    }
  }

  const handleRequestForm = async (values) => {
    if (Object.keys(editItem).length) {
      const { data } = await UPDATE({
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email?.trim()?.toLowerCase(),
          learnerId: editItem?.id,
          waivePrecheck: values.waivePrecheck || false,
        },
      })
      if (!data.updateLearner.success) {
        setCsvError([
          data?.updateLearner?.error || data?.updateLearner?.message,
        ])
        // toast.error(data?.updateLearner?.error || data?.updateLearner?.message)
      }
    } else {
      // check if learnerData has the same email
      if (learnerData?.some((item) => item.email === values.email)) {
        setCsvError(['Learner email already exists'])
        handleClose()
        return
      }

      const { data } = await CREATE_LEARNER({
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email?.trim()?.toLowerCase(),
          trainingId: location.state.id,
          waivePrecheck: values.waivePrecheck || false,
        },
      })
      console.log('CREATE LEARNER', data)
      if (!data.inviteLearner.success) {
        setCsvError([
          data?.inviteLearner?.error || data?.inviteLearner?.message,
        ])
        // toast.error(data?.inviteLearner?.error || data?.inviteLearner?.message)
      }
    }
    handleClose()
    getList()
  }

  const createLerner = async (values) => {
    const { data } = await CREATE_LEARNER({
      variables: {
        firstName: values[0],
        lastName: values[1],
        email: values[2]?.trim()?.toLowerCase(),
        trainingId: location.state.id,
        waivePrecheck: values[3],
      },
    })
    if (!data.inviteLearner.success) {
      getList()
      console.log(
        'Invite Learner Error',
        data?.inviteLearner?.error || data?.inviteLearner?.message
      )
      // setCsvError(data?.inviteLearner?.error || data?.inviteLearner?.message)
      // toast.error(data?.inviteLearner?.error || data?.inviteLearner?.message)
      return
    }
  }

  const handleDeleteLearner = async (id) => {
    try {
      const deleteLearnerRes = await Delete_Learner(id)
      if (deleteLearnerRes?.deleteLearner?.success) {
        await getList()
        // toast.success('Learner deleted')
      } else {
        // toast.error(
        //   deleteLearnerRes?.deleteLearner?.error || 'Something went wrong'
        // )
      }
    } catch (error) {}
  }

  const getList = async () => {
    if (!location.state || !location.state.id) {
      navigate(
        userType === 'admin' ? '/admin/request' : '/request/request-list'
      )
      return
    }
    // console.log("-----------", location.state);
    let { data } = await refetch({
      trainingId: location.state.id,
    })
    setData(data.GetTrainingDetails.data)

    let { data: data1 } = await refetchLearner({
      trainingId: location.state.id,
    })
    const sampleData = []
    if (data1.GetLearnerList.data.length > 0) {
      data1.GetLearnerList.data.map((item, index) => {
        sampleData.push({
          index: data1.GetLearnerList.data.length - index,
          name: item.first_name + ' ' + item.last_name,
          email: item.email,
          status: item.status,
          id: item.id,
          first_name: item.first_name,
          last_name: item.last_name,
          waive_precheck: item.waive_pre_check,
          training_id: location.state.id,
          user_id: item.user_id,
          // link: (
          //   )
        })
      })
    }
    setLearnerData(sampleData.reverse())
  }

  function doesCSVHasDuplicates(csvData) {
    // check if the CSV has duplicate records on email
    console.log('DOES CSV HAS DUPLICATES', csvData)
    const emailSet = []
    let isError = false
    let duplicateErrors = []
    csvData?.map((data, index) => {
      if (index !== 0) {
        if (emailSet.includes(data.email?.toLowerCase())) {
          duplicateErrors = [
            ...duplicateErrors,
            `The file contains duplicate rows ${index + 2}.`,
          ]
          isError = true
          emailSet.push(data.email?.toLowerCase())
        } else {
          emailSet.push(data.email?.toLowerCase())
        }
      } else {
        emailSet.push(data.email?.toLowerCase())
      }
      return data
    })
    setCsvError([...csvError, ...duplicateErrors])

    return isError
  }

  function doesCSVHasListDuplicates(csvData) {
    // check if email exist in the learner list
    let isError = false
    let duplicateErrors = []
    csvData?.map((data, index) => {
      if (
        learnerData?.some(
          (item) => item.email?.toLowerCase() === data?.email?.toLowerCase()
        )
      ) {
        duplicateErrors = [
          ...duplicateErrors,
          `The file has a duplicate user in the current list in row ${
            index + 2
          }.`,
        ]
        isError = true
      }
    })
    setCsvError([...csvError, ...duplicateErrors])
    return isError
  }

  const handleFileUpload = async (event) => {
    setCsvError([])
    const file = event.target.files[0]
    if (!file) {
      return
    }
    const reader = new FileReader()

    reader.onload = async (e) => {
      setUploadCSVLoad(true)
      const preCsvData = csvToArr(e.target.result, ',')
      // remove any empty lines
      const csvData = preCsvData.filter((item) => {
        if (item?.email && item?.first_name && item?.last_name) {
          return item
        }
      })
      // csvData after ignoring any empty lines
      // FIXME: Only ignore lines without email right now
      // const csvData = oldCsvData.filter((item) => item?.email)

      let validCSVFile = false

      if (csvData.length === 0) {
        setCsvError([
          ...csvError,
          'No learner data found in the file. Please check the file type, format and contents.',
        ])
      }

      // check if file is larger than 10KB
      if (file.size > 10000000) {
        setCsvError([
          ...csvError,
          'The file size is greater than the maximum 10KB file size allowed.',
        ])
      }
      // check if the file is of type csv
      if (file.type !== 'text/csv') {
        setCsvError([...csvError, 'The file type must be in CSV format.'])
      }
      // check if the csv file has the required headers
      if (
        !csvData[0]?.first_name &&
        !csvData[0]?.last_name &&
        !csvData[0]?.email
      ) {
        // ignore any empty lines
        validCSVFile = true
        // setCanCSVSubmit(true)
      }
      if (doesCSVHasDuplicates(csvData)) {
        // logging for duplicates here
      }
      if (doesCSVHasListDuplicates(csvData)) {
        // logging for list duplicates here
      }
      // Validate CSV
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

      let emailErrors = []

      if (csvError?.length === 0) {
        validCSVFile = true
      }

      csvData.map((data, index) => {
        // validCSVFile = true
        if (!data?.first_name || !data?.last_name || !data?.email) {
          validCSVFile = false
          emailErrors = [
            ...emailErrors,
            `The file is missing required values in row ${index + 2}.`,
          ]
        }
        if (!emailRegex.test(data?.email)) {
          validCSVFile = false
          emailErrors = [
            ...emailErrors,
            `The file has an invalid email address in row ${index + 2}.`,
          ]
        }
        if (
          data?.email &&
          learnerData?.some(
            (item) => item.email?.toLowerCase() === data?.email?.toLowerCase()
          )
        ) {
          validCSVFile = false
          emailErrors = [
            ...emailErrors,
            `The file has a duplicate user in the current list in row ${
              index + 2
            }.`,
          ]
        }

        setCsvError([...csvError, ...emailErrors])
      })

      if (validCSVFile) {
        setCanCSVSubmit(true)
        setCSVData(csvData)
      } else {
        setCSVData()
        // handleCloseCSV()
      }
    }
    reader.readAsText(file)
  }

  const handleCSVSubmit = async () => {
    setUploadCSVLoad(false)
    let isError = false
    try {
      if (csvData?.length > data?.max_learner - learnerData?.length) {
        setCsvError([
          ...csvError,
          'The file exceeds the maximum number of Learners allowed.',
        ])
        isError = true
      }
      if (csvData?.length > 50) {
        // TODO: Add a the length default config above this
        setCsvError([
          ...csvError,
          'The file size is greater than the maximum 50 rows allowed.',
        ])
        isError = true
      }
      if (csvData?.length > 0) {
        if (csvError?.length === 0 && !isError) {
          const { data } = await uploadCSV({
            variables: { csvData: { csvData }, trainingId: location.state.id },
          })
          if (data?.inviteMultipleLeaner.success) {
            setCsvError([])
            // toast.success('CSV uploaded Successfully')
            getList()
          } else {
            // toast.error(data?.inviteMultipleLeaner?.error)
            setCsvError([
              ...csvError,
              'There was an error uploading the file. Please check the file type, format and contents. Contact support if you cannot find the issue.',
            ])
          }
        }
      } else {
        // FIXME: No rows in the CSV Here, get copy from NC.
        setCsvError([
          ...csvError,
          'No learner data found in the file. Please check the file type, format and contents.',
        ])
      }
    } catch (error) {
      // FIXME: Catches any error that's not caught otherwise, get copy from NC.
      setCsvError([
        ...csvError,
        'There was an error uploading the file. Please check the file type, format and contents. Contact support if you cannot find the issue.',
      ])
      console.error('Error uploading CSV:', error)
    } finally {
      setOpenUploadCSV(false)
    }
  }

  function csvToArr(stringVal, splitter) {
    setCsvError([])
    const [keys, ...rest] = stringVal
      .trim()
      .split('\n')
      .map((item) => item.split(splitter).map((val) => val.replace(/\r/g, '')))

    if (
      keys.includes('waive_pre_check') &&
      keys.includes('email') &&
      keys.includes('first_name') &&
      keys.includes('last_name')
    ) {
      // no CSV Error
    } else {
      setCsvError([...csvError, 'The file does not have column headers.'])
    }

    const formedArr = rest.map((item) => {
      const object = {}
      keys.forEach((key, index) => {
        const csvKey = key?.trim()
        if (csvKey === 'waive_pre_check') {
          if (checkUserPermissions('training.pre_waive')) {
            object[csvKey] =
              item[index]?.toLowerCase()?.trim()[0] === 'y' ? true : false
          } else {
            object[csvKey] = false
          }
        } else if (csvKey === 'email') {
          object[csvKey] = item[index]?.toLowerCase()?.trim()
        } else {
          object[csvKey] = item[index]?.trim()
        }
      })
      return object
    })
    return formedArr
  }

  const handleSendAgain = async (item) => {
    try {
      const { data } = await RESEND({
        variables: {
          learnerId: item?.id,
        },
      })
      if (!data.reInviteLearner.success) {
        toast.error(
          data?.reInviteLearner?.error || data?.reInviteLearner?.message
        )
      } else {
        toast.success('Invitation Re-Send Successfully')
        getList()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const copyInviteLink = (item) => {
    // console.log('COPY INVITE LINK', item, process.env)
    const url = `${process.env.REACT_APP_URL}?userId=${item.user_id}&invitationId=${item.id}`
    navigator.clipboard
      .writeText(url)
      .then(() => {
        // toast.success('Invitation Link Copied')
      })
      .catch((error) => {
        console.error('Failed to copy email to clipboard:', error)
      })
  }

  const handleDownload = () => {
    // Generate CSV content

    let csvContent = ''

    if (checkUserPermissions('training.pre_waive')) {
      csvContent =
        'email,first_name,last_name,waive_pre_check\nxyz@gmail-com,xyz,xyzlastname,yes\nabc@xyz.com,Abc,abclastname,no'
    } else {
      csvContent =
        'email,first_name,last_name\nxyz@gmail-com,xyz,xyzlastname\nabc@xyz.com,Abc,abclastname'
    }

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv' })

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob)

    // Create a link element
    const link = document.createElement('a')

    // Set the link's properties
    link.href = url
    link.setAttribute(
      'download',
      `${data?.lms_course_id}-${data?.training_type}-${moment(
        data?.start_date
      ).format('YYYY-MM-DD')}.csv`
    )

    // Append the link to the DOM
    document.body.appendChild(link)

    // Click the link programmatically to trigger the download
    link.click()

    // Cleanup
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const handleEditLearner = async (item) => {
    setEditItem(item)
    setOpen(true)
  }
  useEffect(() => {
    getList()
  }, [])

  const isSendInviteDisabled = useMemo(() => {
    const isNotSent = learnerData?.some((_l) => _l?.status === 'not_sent')
    return !learnerData?.length || !isNotSent
  }, [learnerData])

  // useEffect(() => {
  //   let timer
  //   if (csvError) {
  //     timer = setTimeout(() => {
  //       setCsvError('')
  //     }, 30000) // 1000 ms = 1 sec
  //   }

  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [csvError])

  return (
    <>
      <div className="row p-0 no-gutters nav-area">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0  no-gutters">
            <div className="col-2">
              <NavBar user={userType} />
            </div>
            <div className="col-10 row admin-request">
              <div className="mb-2 p-0">
                <a
                  href="javascript:void(0)"
                  onClick={(e) =>
                    navigate(
                      userType === 'admin'
                        ? `/admin/request/view-request`
                        : '/request/view-request',
                      {
                        state: { id: location.state.requestId },
                      }
                    )
                  }
                  className="back-link"
                >
                  <ChevronLeftIcon />
                  Back
                </a>
              </div>
              {trainingLoading || !data ? (
                <Spinner sx={{ marginTop: 4 }} />
              ) : (
                <div className="row">
                  <div className="col-xl-6 regBox p-3">
                    <div className="row p-3">
                      <div className="col-lg-9 requestTitle">
                        Training Details
                      </div>

                      <div className="col-lg-3 text-right">
                        {localStorage
                          .getItem('userPermissions')
                          ?.includes('training.edit') && (
                          <Button
                            type="button"
                            className="button-base-instance-grey"
                            onClick={() =>
                              navigate(
                                userType === 'admin'
                                  ? '/admin/request/edit-training'
                                  : '/request/edit-training',
                                {
                                  state: {
                                    id: data.id,
                                    requestId: location.state.requestId,
                                  },
                                }
                              )
                            }
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="container p-3">
                      <div className="row">
                        <label className="view-label mb-2">Status</label>
                        <div className="view-content">
                          <Chip
                            className="chip-instance"
                            divClassName="chip-2"
                            status={data?.status}
                            text={data?.status}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label className="view-label mt-2">Training Type</label>
                        <div className="view-content">
                          {data?.training_type}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6">
                          <label className="view-label mb-2">Start Date</label>
                          <div className="view-content">
                            {data?.start_date
                              ? moment(data?.start_date).format('DD MMM yyyy')
                              : ''}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label className="view-label mb-2">End Date</label>
                          <div className="view-content">
                            {data?.end_date
                              ? moment(data?.end_date).format('DD MMM yyyy')
                              : ''}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6">
                          <label className="view-label mb-2">Start Time</label>
                          <div className="view-content">
                            {data?.start_time || ''}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label className="view-label mb-2">End Time</label>
                          <div className="view-content">
                            {data?.end_time || ''}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <label className="view-label mb-2">Timezone</label>
                        <div className="view-content">
                          {data?.time_zone || ''}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <label className="view-label mb-2">
                          Max # Learners
                        </label>
                        <div className="view-content">{data?.max_learner}</div>
                      </div>

                      <div className="row mt-2">
                        <label className="view-label mb-2">Course Id</label>
                        <div className="view-content">
                          {data?.lms_course_id}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <label className="view-label mb-2">Address</label>
                        <div className="view-content">
                          {data?.address || ''}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <label className="view-label mb-2">City</label>
                        <div className="view-content">{data?.city || ''}</div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6">
                          <label className="view-label mb-2">State</label>
                          <div className="view-content">
                            {data?.state || ''}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label className="view-label mb-2">Zipcode</label>
                          <div className="view-content">
                            {data?.zipcode || ''}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <label className="view-label mb-2">Location</label>
                        <div className="view-content">
                          {data?.location || ''}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <label className="view-label mb-2">Notes</label>
                        <div className="view-content">{data?.notes || ''}</div>
                      </div>
                      {checkUserPermissions('training.edit') && (
                        <div className="row mt-2">
                          <label className="view-label mb-2">
                            Allow invite Learners?
                          </label>
                          <div className="view-content">
                            {data?.permissions
                              ? data?.permissions.length > 0
                                ? 'Yes'
                                : 'No'
                              : 'No'}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {data?.status?.toLowerCase() === 'enrolling' &&
                    checkUserPermissions(
                      'training.view_learners',
                      data?.permissions || []
                    ) && (
                      <div className="col-xl-6 mt-sm-2">
                        <div className="row regBox mx-lg-3">
                          <div className="container p-3">
                            <div className="row pt-3">
                              <div className="col-md-3 requestTitle">
                                Learners
                              </div>
                              {/* {data?.max_learner > learnerData?.length ? (
                            <div className="col-lg-3">
                              <input
                                type="file"
                                accept=".csv"
                                name=""
                                id="input"
                                onChange={(e) => {
                                  handleUploadCSV(e.target.files[0])
                                }}
                                style={{ display: 'none' }}
                              />

                              <label htmlFor="input">
                                <div className="p-2 button-base-instance-grey">
                                  {uploadCSVLoad ? <Spinner /> : 'Upload CSV'}
                                </div>
                              </label>
                            </div>
                          ) : (
                            <div className="col-lg-3">
                              <Button disabled type="button">
                                Upload CSV
                              </Button>
                            </div>
                          )} */}
                              <div className="col-lg-9 mt-sm-2">
                                {checkUserPermissions(
                                  'training.invite',
                                  data.permissions
                                ) && (
                                  <div className="row">
                                    <div
                                      className="col-4 mt-sm-2 mt-md-0"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                      }}
                                      onClick={handleDownload}
                                    >
                                      Download Template
                                    </div>

                                    <div className="col-4 mt-sm-2 mt-md-0">
                                      <Button
                                        disabled={
                                          data?.max_learner <=
                                          learnerData?.length
                                        }
                                        type="button"
                                        onClick={(e) => {
                                          setCsvError([])
                                          setOpenUploadCSV(true)
                                        }}
                                      >
                                        Upload CSV
                                      </Button>
                                    </div>
                                    <div className="col-4 mt-sm-2 mt-md-0">
                                      <Button
                                        disabled={
                                          data?.max_learner <=
                                          learnerData?.length
                                        }
                                        type="button"
                                        onClick={(e) => setOpen(true)}
                                      >
                                        Invite
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row px-3 mt-2">
                              <hr />
                            </div>

                            {!uploadCSVLoad &&
                              csvError &&
                              csvError?.length > 0 && (
                                <ul className="row">
                                  {csvError?.map((error, index) => (
                                    <li
                                      key={index}
                                      style={{
                                        color: '#BB0000',
                                      }}
                                    >
                                      {error}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            {preReqLoading ? (
                              <p
                                style={{
                                  color: '#BB0000',
                                }}
                              >
                                Checking learners...
                              </p>
                            ) : preReqErrors?.length > 0 ? (
                              <ul className="row">
                                {preReqErrors?.map((error, index) => (
                                  <li
                                    key={`pre-req-error-${index}`}
                                    style={{
                                      color: '#BB0000',
                                    }}
                                  >
                                    {error}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p
                                style={{
                                  color: '#BB0000',
                                }}
                              >
                                Learners check completed.
                              </p>
                            )}

                            {learnerLoading || !learnerData ? (
                              <Spinner sx={{ marginTop: 4 }} />
                            ) : (
                              <>
                                <div className="row no-gutters table-responsive">
                                  <Table
                                    data={learnerData?.sort(
                                      // sort by email
                                      (a, b) => {
                                        if (a.email < b.email) {
                                          return -1
                                        }
                                        if (a.email > b.email) {
                                          return 1
                                        }
                                        return 0
                                      }
                                    )}
                                    columns={sampleColumns}
                                    className="insCertTable"
                                  />
                                </div>
                                {checkUserPermissions(
                                  'training.invite',
                                  data.permissions || []
                                ) && (
                                  <div
                                    className="row pt-4
                            "
                                  >
                                    <div className="col-lg-6"></div>
                                    <div className="col-lg-6">
                                      <Button
                                        onClick={handleSendPendingInvites}
                                        disabled={isSendInviteDisabled}
                                      >
                                        Send Pending Invites
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableBackdropClick={true}
            >
              <Box
                sx={{
                  position: 'absolute',
                  maxwidth: 550,
                  bgcolor: 'background.paper',
                  border: '0.5px solid #000',
                  borderRadius: '10px',
                  p: 4,
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <h5>Invite a Learner</h5>
                <p>
                  Your new project has been created. Invite colleagues to
                  collaborate on this project
                </p>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    firstName: editItem?.first_name ?? '',
                    lastName: editItem?.last_name ?? '',
                    email: editItem?.email ?? '',
                    waivePrecheck: editItem?.waive_precheck || false,
                  }}
                  validationSchema={FormSchema}
                  onSubmit={handleRequestForm}
                >
                  {({
                    errors,
                    values,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row">
                          <div className="col-md-6 mb-3 flex-column d-flex">
                            <TextField
                              id="firstName"
                              name="firstName"
                              placeholder="First Name"
                              value={values.firstName}
                              label="First Name *"
                              error={errors.firstName}
                              touched={touched.firstName}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6 mb-3 flex-column d-flex">
                            <TextField
                              id="lastName"
                              name="lastName"
                              placeholder="Last Name"
                              value={values.lastName}
                              label="Last Name *"
                              error={errors.lastName}
                              touched={touched.lastName}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3 flex-column d-flex">
                            <TextField
                              name="email"
                              placeholder="Email"
                              value={values.email?.trim()?.toLowerCase()}
                              label="Email address *"
                              error={errors.email}
                              touched={touched.email}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                              disabled={Object.keys(editItem).length}
                            />
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            display: checkUserPermissions('training.pre_waive')
                              ? 'flex'
                              : 'none',
                          }}
                        >
                          <div className="col-md-12 mb-3 flex-column d-flex">
                            <div className="row align-items-center">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={
                                      !checkUserPermissions(
                                        'training.pre_waive'
                                      )
                                    }
                                    label="Waive Pre-check"
                                    inputProps={{
                                      'aria-label': 'controlled',
                                    }}
                                    checked={values.waivePrecheck}
                                    name="waivePrecheck"
                                    id="waivePrecheck"
                                    onClick={(e) => {
                                      setFieldValue(
                                        'waivePrecheck',
                                        !values.waivePrecheck
                                      )
                                    }}
                                  />
                                }
                                label="Waive Pre-Check"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-3 flex-column d-flex">
                            <Button
                              className="button-base-instance-grey"
                              variant="contained"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="col-md-6 mb-3 flex-column d-flex">
                            <Button type="submit">
                              {loading || updateLearner ? (
                                <Spinner
                                  size="20px"
                                  sx={{
                                    '.MuiCircularProgress-root': {
                                      color: 'white',
                                    },
                                  }}
                                  thickness={5}
                                />
                              ) : Object.keys(editItem).length ? (
                                'Edit Learner'
                              ) : (
                                'Add Invitation'
                              )}
                            </Button>
                          </div>
                        </div>
                      </form>
                    )
                  }}
                </Formik>
              </Box>
            </Modal>

            {openUploadCSV && (
              <UploadCSV
                handleFileUpload={handleFileUpload}
                openUploadCSV={openUploadCSV}
                handleCloseCSV={handleCloseCSV}
                csvUploadLoading={csvUploadLoading}
                handleCSVSubmit={handleCSVSubmit}
                csvData={csvData}
                canCSVSubmit={canCSVSubmit}
                csvErrors={uploadCSVLoad ? csvError : []}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminTrainingView
