import React, { useEffect, useState } from 'react'
import { Chip } from '../../components/Chip'
import { Modal, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import { toast } from 'react-toastify'

import { Get_Request_Of_Requester, Create_Request } from '../../api/graphql'

import Button from '../../components/Button/Button'
import Header from '../../components/Header/Header'
import Table from '../../components/Table/Table'
import Spinner from '../../components/Spinner/Spinner'
import Navbar from '../../components/Navbar/Navbar'

import './RequestListModule.css'
import NewRequest from '../../components/NewRequest/NewRequest'

import Tick from '../../assets/svg/Tick.svg'
import { capitalizeFirst } from '../../utils/StringUtils'
import { checkUserPermissions } from '../../utils/UserUtils'

const RequestList = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [requestSent, setRequestSent] = useState(false)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const userId = userData.id
  const { loading: requestLoading, refetch } = useQuery(
    Get_Request_Of_Requester,
    {
      variables: {
        requesterId: userId,
      },
    }
  )

  const [requestList, setRequestList] = useState([])
  const [loggedEmail, setLoggedEmail] = useState()
  const [CREATE_REQUEST, { loading }] = useMutation(Create_Request)

  const handleClose = () => {
    setOpen(false)
  }

  const sampleColumns = [
    {
      path: 'trainingType',
      header: 'Training Type',
      width: '10%',
    },
    {
      path: 'subDate',
      header: 'Submission Date',
      width: '15%',
    },
    {
      path: 'trainings',
      header: '# Trainings',
      width: '10%',
      content: (item) => (
        <span>
          {item.current_training} / {item.max_training}
        </span>
      ),
    },
    {
      path: 'status',
      header: 'Status',
      width: '15%',
      content: (item) => (
        <Chip
          className="chip-instance"
          divClassName="chip-2"
          status={item.status?.toLowerCase()}
          text={item.status}
        />
      ),
    },
    {
      path: 'link',
      header: 'Details',
      align: 'center',
      width: '15%',
    },
  ]

  const handleRequestForm = async (values) => {
    try {
      const { data } = await CREATE_REQUEST({
        variables: {
          email: values.email?.trim()?.toLowerCase(),
          firstName: values.firstName,
          lastName: values.lastName,
          status: 'pending', // values.status.value,
          phoneNumber: values.phoneNumber,
          jobTitle: values.jobTitle,
          organization: values.organization,
          city: values.city,
          state: values.state.label,
          zipcode: values.zipcode,
          submissionDate: new Date(),
          intrestedTraining: values.intrestedTraining,
          courseId: values.course.value,
          courseName: values.course.label,
          isNationalCouncilMember: values.isNationalCouncilMember,
          maxTraining: values.maxTraining,
          haveFunding: values.haveFunding,
        },
      })
      if (data?.createRequest?.success) {
        toast.success('Request added')
        getList()
        setOpen(false)
        setRequestSent(true)
      } else {
        toast.error(data?.createRequest?.message)
      }
    } catch (error) {
      console.log('catch------', error)
    }
  }

  const getList = async () => {
    const email = localStorage.getItem('emailKey')

    if (email == '') {
      navigate('/')
      return
    }
    setLoggedEmail(email)

    const { data } = await refetch()

    const requestsData = data.GetRequestOfRequester?.data

    const sampleData = requestsData.map((item, index) => {
      const date = moment(item?.submission_date)
      const formattedDate = date.format('YYYY-MM-DD')
      const intrested_training = item.intrested_training.map((str) =>
        capitalizeFirst(str)
      )

      return {
        orgName: item.organization ?? '-',
        id: index + 1,
        subDate: formattedDate !== 'Invalid date' ? formattedDate : '-',
        name: item.first_name + ' ' + item.last_name,
        status: item.status,
        trainingType: intrested_training?.toString(),
        trainings: item?.max_training ?? 0,
        max_training: item?.max_training ?? 0,
        current_training: item?.instructorTrainingCount ?? 0,
        link: (
          <a
            className="request-detail-link"
            href="javascript:void(0)"
            onClick={() =>
              navigate(`/request/view-request`, {
                state: { id: item.id },
              })
            }
          >
            Details
          </a>
        ),
      }
    })

    setRequestList(sampleData)
  }

  useEffect(() => {
    getList()
  }, [])

  return (
    <>
      <div className="row p-0 nav-area no-gutters">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0 no-gutters">
            <div className="col-2">
              <Navbar user={'host'} />
            </div>
            <div className="col-10 row admin-request">
              <div className="col-lg-10">
                <div className="instructor-title">Contracts</div>

                {/* <p className="there-are-many">
                  Review all the submitted interest forms
                </p> */}
              </div>

              <div className="col-lg-2 mt-2">
                {checkUserPermissions('request.create') && (
                  <Button
                    onClick={(e) => {
                      setRequestSent(false)
                      setOpen(true)
                    }}
                    icon="false"
                    text={'New Request'}
                  />
                )}
              </div>
              {requestLoading ? (
                <Spinner sx={{ marginTop: 4 }} />
              ) : (
                <div className="row no-gutters table-responsive mt-3">
                  <Table
                    data={requestList}
                    columns={sampleColumns}
                    className="insCertTable"
                  />
                </div>
              )}
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableBackdropClick={true}
            >
              <Box className="modal-box">
                <h5>Interest Form</h5>
                <p>Complete this interest form for the training course</p>
                <NewRequest
                  loading={loading}
                  handleClose={handleClose}
                  handleRequestForm={handleRequestForm}
                  loggedEmail={loggedEmail}
                />
              </Box>
            </Modal>
            <Modal
              open={requestSent}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableBackdropClick={true}
            >
              <Box className="modal-box" style={{ height: '400px' }}>
                <div style={{ textAlign: 'center' }}>
                  <div className="row mt-5">
                    <img src={Tick} width={80} height={80} />
                  </div>
                  <h5 className="mt-2">Thanks for your interest. </h5>
                  <p className="mt-3 mb-5">
                    We will reach out via email about the next steps.
                  </p>
                  <Button
                    type={'button'}
                    onClick={(e) => {
                      setOpen(false)
                      setRequestSent(false)
                    }}
                    text={'Okay'}
                  />
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </>
  )
}
export default RequestList
