import React from 'react'
import './FindCourse.css'

import Layout from '../../layouts/Layout'
import Support from '../Support/Support'
import Button from '../../components/Button/Button'

const FindCourse = () => {
  const email = localStorage.getItem('emailKey')
  return (
    <>
      <Layout title={''} disableLogoClick={true}>
        {/* <div className="instructorTitle">FIND A COURSE</div>
        <p className="there-are-many">
          Adult Mental Health First Aid (MHFA) courses teach adult learners how
          to assist fellow adults, while Youth MHFA courses teach adult learners
          how to assist adolescents between the ages of 12 and 18.
        </p>
        <p className="there-are-many">
          These courses are available completely in-person through an
          Instructor-led training, partially in-person (called Blended
          In-person) or completely virtually (called Blended Virtual).
          <p className="there-are-many"></p>
          The location for Blended Virtual courses will appear as “N/A” as they
          can be taken from anywhere in the United States with an Internet
          connection.
        </p>
        &nbsp;
        <div className="row no-gutters ">
          <p className="there-are-many">
            Please Complete the MHFA First-Aider certification or a MHFA
            Instructor Certification to apply for Instructor training. &nbsp;
            <a
              href={`mailto:${
                process.env.REACT_APP_LMS_SUPPORT_MAIL ||
                'mhfaapplications@thenationalcouncil.org'
              }`}
              target="_blank"
            >
              Contact Administration for Support
            </a>
          </p>
        </div> */}
        <section
          className="row px-2 py-4"
          style={{
            borderRadius: '5px',
            backgroundColor: '#f6f3ee',
            textAlign: 'center',
            marginBottom: '0.5rem',
          }}
        >
          <h3 style={{ color: '#ea4b22' }}>Required certification not found</h3>
          <p>
            We were unable to verify your completion of an MHFA course. To
            continue with the application process, you can choose one of the
            following options.
          </p>
        </section>
        <section className="row">
          <div className="col-lg-6">
            <Support email={email} />
          </div>
          <div className="col-lg-6 row">
            <div className="p-3">
              <h6 style={{ color: '#345E88', fontWeight: 'bold' }}>Option 2</h6>
              <h4 style={{ color: '#ea4b22' }}>Contact Support</h4>
              <p>
                Please click the button below to contact Support by email.
                Include your name, state, a previous email if used for First
                Aider certification, and the organization you work for in the
                email. Thank you.
              </p>
              <div className="row">
                <div className="col-lg-6 col-10">
                  <Button
                    onClick={() => {
                      // open an email url
                      window.open(
                        `mailto:${
                          process.env.REACT_APP_LMS_SUPPORT_MAIL ||
                          'mhfaapplications@thenationalcouncil.org'
                        }`
                      )
                    }}
                  >
                    Contact Support
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default FindCourse
