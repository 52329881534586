const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

const durations = (start, stop, step) => {
  let arr = []
  for (let i = start; i <= stop; i = i + step) {
    var myObject = {
      id: i,
      name: i,
    }
    arr.push(myObject)
  }
  return arr
}

function checkEmptyArray(arr) {
  // check is arr is an array
  if (!Array.isArray(arr)) {
    // check if the value is null / undefined / empty string
    return arr === null || arr === undefined || arr === ''
  }
  const newArr = arr?.filter(
    (el) => el !== null && el !== undefined && el !== ''
  )
  // is newArr empty?
  return newArr?.length === 0
}

export { range, durations, checkEmptyArray }
