import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { ButtonBase } from '../../components/ButtonBase'
import { Chip } from '../../components/Chip'
import './InstructorApplicationModule.css'

import {
  Get_Assestment,
  GET_ENROLLMENT_COURSES,
  GET_ALL_ASSESSMENT_PROGRESS,
  CRM_PRE_CHECK,
  GET_COURSE,
  GET_USER_CERTIFICATES,
} from '../../api/graphql'

import line191svg from '../../assets/svg/line-19-1.svg'
import line192svg from '../../assets/svg/line-19-2.svg'
import Table from '../../components/Table/Table'
import Spinner from '../../components/Spinner/Spinner'
import { useMutation, useQuery } from '@apollo/client'
import storage from '../../auth/storage'
import { useUser } from '../../auth/AuthState'
import Layout from '../../layouts/Layout'
import { USER_ACTIVITY_STATUS } from '../../utils/common'
import { useUpdateUserActivity } from '../../hooks/useUpdateUserActivity'
import Support from '../Support/Support'
import ButtonComponent from '../../components/Button/Button'

const InstructorApplication = () => {
  const navigate = useNavigate()
  const [updatedCourse, setUpdatedCourse] = useState([])
  const [application, setApplication] = useState([])
  const [userData, setUserData] = useState([])
  const email = localStorage.getItem('emailKey')
  const [checkEnrollmentLoading, setEnrollmentLoading] = useState(true)
  const [assessmentStartLoading, setAssessmentStartLoading] = useState(false)
  const [progressData, setProgressData] = useState([])
  const [assessmentLogData, setAssessmentLogData] = useState([])
  const { userState } = useUser()
  const { updateUserActivity, createUserLog } = useUpdateUserActivity()
  const Button = React.memo(ButtonBase)

  // Queries
  const [courses, setCourses] = useState([])
  const [userCourseId, setUserCourseId] = useState()
  const [userCourseName, setUserCourseName] = useState()
  const isPrivateUser = localStorage.getItem('isInvited') ? true : false

  const { loading: coursesLoading, refetch: fetchCourses } =
    useQuery(GET_COURSE)

  // Queries
  const { loading, refetch } = useQuery(Get_Assestment, {
    variables: { email },
  })
  // const { loading: mhfaLoading, refetch: certificationRefetch } = useQuery(
  //   GET_ENROLLMENT_COURSES,
  //   { variables: { email } }
  // )

  const { loading: usercertsLoading, refetch: userCertsRefetch } = useQuery(
    GET_USER_CERTIFICATES,
    {
      variables: {
        userId: 0,
      },
    }
  )
  const { loading: progressLoading, refetch: refetchProgress } = useQuery(
    GET_ALL_ASSESSMENT_PROGRESS,
    { variables: { userId: userState?.user?.id } }
  )
  const [CRM_CHECK] = useMutation(CRM_PRE_CHECK)

  useEffect(() => {
    getAssestment()
    getCourses()
  }, [])

  const getCourses = async () => {
    const userData = JSON.parse(await storage.getField('userData'))
    setUserData(userData)
    const pData = await refetchProgress({ userId: userData?.id })
    setProgressData(pData?.data?.GetAllAssessmentProgress?.data || [])

    const assessmentLog = pData?.data?.GetAllAssessmentProgress?.data?.filter(
      (_a) => _a.status === 'Passed' || _a.status === 'Failed'
    )
    setAssessmentLogData(assessmentLog)

    // const certificateData = await certificationRefetch({ email })
    const certificates = await userCertsRefetch({
      userId: userData?.id,
    })

    // const courseData = certificateData?.data?.CheckUserIsCeritifiedMHFA?.data

    console.log('CERTIFICATES', certificates)

    const courseData = certificates?.data?.GetUserCertificates?.data.map(
      (d) => {
        return {
          id: parseInt(d?.course_id),
          Active: d?.Active,
          description: d?.description,
          name: d?.name,
          created_at: d?.created_at,
          updated_at: d?.updated_at,
        }
      }
    )
    const data = await checkEnrollments(courseData, assessmentLog)
    setUpdatedCourse(data)
    setEnrollmentLoading(false)
  }

  const checkEnrollments = async (courseData, assessmentProg) => {
    const { data } = await fetchCourses()
    const networkCourses = data?.GetCourse?.data
    setCourses(networkCourses)

    if (courseData?.length) {
      return courseData
    }
    // else {
    //   const { data } = await CRM_CHECK({ variables: { email } })
    //   if (data?.CheckCRM?.iSCRM) {
    //     return data?.CheckCRM?.crmPassedCourses
    //   }
    // }

    // console.log('networkCourses', networkCourses)
    // find the courses that has assessment progress
    const courseIds = assessmentProg?.map((item) => parseInt(item.course_id))
    const updatedCourses = networkCourses?.filter((course) =>
      courseIds?.includes(course.id)
    )

    if (updatedCourses?.length) {
      return updatedCourses
    } else {
      return []
    }
  }

  const getAssestment = async () => {
    const newData = await refetch({ email })
    const result = newData?.data?.GetAssestment?.data?.map((data, index) => ({
      id: index + 1,
      certification_name: data?.course?.name,
      start_time: moment(data?.start_time).format('YYYY-MM-DD HH:mm:ss') || '-',
      end_time: data?.end_time,
      status: data?.status,
      course_id: data?.course_id,
      assessment_score: data?.assessment_score,
      reassessment_date: data?.reassessment_date,
      is_profile_completed: data?.is_profile_completed,
    }))
    // const assessmentLog = result?.filter((_a) => _a.end_time !== null)
    // setAssessmentLogData(assessmentLog)
    setApplication(result)
  }

  function canUserTakeCourse(course_id) {
    // console.log('CAN USER TAKE COURSE START')
    // check if updatedCourses contain the course_id
    const course = updatedCourse.find(
      (item) => parseInt(item.id) === parseInt(course_id)
    )
    // console.log('updatedCourse', updatedCourse)
    // console.log('course', course)
    if (course !== undefined) {
      return true
    } else {
      return false
    }
  }

  // const disabledReApply = useCallback(
  //   (courseName, courseID) => {
  //     const currentDate = moment();
  //     const isApplied = application.find((data) => {
  //       const isSameCourse = data?.course_id === courseID;
  //       const isBeforeReassessment = currentDate.isBefore(
  //         data?.reassessment_date
  //       );

  //       return isSameCourse && isBeforeReassessment;
  //     });

  //     return !isApplied;
  //   },
  //   [application]
  // );

  const isResume = useCallback(
    (course_id) => {
      const currentDate = moment()

      const isProgress = progressData?.find((progress) => {
        const isSameCourse = parseInt(progress?.course_id) === course_id
        // FIXME: Reassessment Date with currentdate.isBefore() function;
        const isFailed = progress?.status === 'Failed'

        // FIXME: Quick fixing right now with end_date
        const isPassed = progress?.status === 'Passed'

        return isSameCourse && (isFailed || isPassed)
      })

      const progress = progressData?.find(
        (_item) => parseInt(_item?.course_id) === course_id
      )
      return progress?.answers?.length > 0 || isProgress

      // return false
      // const currentDate = moment()
      // const isApplied = application.find((data) => {
      //   const isSameCourse = data?.course_id === course_id
      //   const isBeforeReassessment = currentDate.isBefore(
      //     data?.reassessment_date
      //   )
      //   const isFailed = data?.status === 'Failed'

      //   return isSameCourse && isBeforeReassessment && isFailed
      // })

      // const data = progressData?.find((_item) => _item?.course_id === course_id)
      // return data?.answers?.length > 0 || isApplied
    },
    [progressData]
  )

  const checkRedirectToInstructorRegistration = useCallback(
    (courseID) => {
      // FIXME: Quick Fix for redirecting to Instructor Registration
      const progress = progressData?.find(
        (_p) => parseInt(_p?.course_id) === parseInt(courseID)
      )
      return progress?.status === 'Passed' || progress?.status === 'Failed'
      // const assessment = application.find((_c) => _c?.course_id === courseID)
      // return assessment?.end_time
    },
    [progressData]
  )

  const checkUserFailed = useCallback(
    (courseID) => {
      const progress = progressData?.find(
        (_p) => parseInt(_p?.course_id) === parseInt(courseID)
      )
      return progress?.status === 'Failed'
      // const assessment = application.find((_c) => _c?.course_id === courseID)
      // return assessment?.status === 'Failed'
    },
    [progressData]
  )

  const onStart = async (courseName, courseId) => {
    setAssessmentStartLoading(true)
    localStorage.setItem('courseName', courseName)
    const timeData = progressData.find(
      (_item) => parseInt(_item?.course_id) === parseInt(courseId)
    )

    // if starting an assessment
    if (!isResume(courseId)) {
      await createUserLog(
        USER_ACTIVITY_STATUS.STARTED,
        `Assessment ${courseName} ${USER_ACTIVITY_STATUS.STARTED}`,
        userState?.user?.id
      )
      await updateUserActivity(
        courseId,
        userState?.user?.id,
        USER_ACTIVITY_STATUS.STARTED
      )
    }

    // if resuming an assessment
    if (isResume(courseId)) {
      await createUserLog(
        USER_ACTIVITY_STATUS.RESUMED,
        `Assessment ${courseName} ${USER_ACTIVITY_STATUS.RESUMED}`,
        userState?.user?.id
      )
    }

    if (checkRedirectToInstructorRegistration(courseId)) {
      navigate('/score', {
        state: {
          course_id: courseId,
          isFailerPage: checkUserFailed(courseId),
        },
      })
      return
    }

    const currentDate = moment()
    const formattedDate = currentDate.format('YYYY-MM-DD HH:mm:ss')

    localStorage.setItem('startTime', timeData?.start_time || formattedDate)
    localStorage.setItem('isExamStarted', 'true')
    setAssessmentStartLoading(false)
    navigate(`/instructor-assessment/${courseId}`)
  }

  useEffect(() => {
    localStorage.removeItem('isExamStarted')
  }, [])

  const InstructorAssessmentBaseColumns = [
    { path: 'id', header: '#', width: '5%' },
    {
      path: 'certificationName',
      header: 'Instructor Certification',
      width: '25%',
    },
    { path: 'certificationDate', header: 'Certification Date', width: '15%' },
    { path: 'expiryDate', header: 'Expiry Date', width: '15%' },

    {
      path: 'status',
      header: 'Status',
      width: '10%',
      content: (item) => (
        <Chip
          className="chip-instance"
          divClassName="chip-2"
          status={item.status?.toLowerCase()}
          text={item.status}
        />
      ),
    },
  ]

  const InstructorAssessmentColumn = [
    { path: 'id', header: '#', width: '5%' },
    {
      path: 'course_id',
      header: 'Certification Application',
      width: '25%',
      // FIXME: Temporary Fix For Certification name
      content: (item) => (
        <div>
          {parseInt(item?.course_id) === 1
            ? 'Adult Mental Health First Aid'
            : 'Youth Mental Health First Aid'}
        </div>
      ),
    },
    {
      path: 'start_date',
      header: 'Start Time',
      width: '15%',
      content: (item) => (
        <div>{moment(item?.start_date).format('MM-DD-YYYY HH:mm:ss')}</div>
      ),
    },
    {
      path: 'end_date',
      header: 'End Time',
      width: '15%',
      content: (item) => (
        <div>{moment(item?.end_date).format('MM-DD-YYYY HH:mm:ss')}</div>
      ),
    },

    {
      path: 'status',
      header: 'Status',
      width: '10%',
      content: (item) => (
        <Chip
          className="chip-instance"
          divClassName="chip-2"
          status={item.status === 'Failed' ? 'failed' : 'passed'}
          text={item.text}
        />
      ),
    },
  ]

  return (
    <>
      <Layout
        title={courses?.length ? 'INSTRUCTOR APPLICATION' : ''}
        disableLogoClick={false}
        shouldReload={true}
      >
        {checkEnrollmentLoading || loading || progressLoading ? (
          <Spinner sx={{ marginTop: 4 }} />
        ) : !userCourseId && courses && courses?.length > 0 ? (
          <>
            <p className="ptext mt-2">
              You’ve been invited to earn Adult/Youth Mental Health First Aid
              (MHFA) Instructor certification. However, before participating in
              an Instructor training course, you must first earn your
              Adult/Youth MHFA First Aider Certificate.
            </p>
            <p className="ptext">
              Adult Mental Health First Aid (MHFA) courses teach adult learners
              how to assist other adults, while Youth MHFA courses teach adult
              learners how to assist adolescents ages 12-18. To qualify for an
              Instructor training you must be certified as a First Aider in the
              same curricula. These courses are available as 100% in-person
              Instructor-led training (called In-person), with online pre-work
              and a virtual Instructor-led training (called Blended Virtual) or
              with a combination of online pre-work and in-person Instructor-led
              training (called Blended In-person).
            </p>
            <div className="text-wrapper-3">Apply For</div>
            <div>
              <img className="line" alt="Line" src={line192svg} />
            </div>
            <div className="row">
              {courses?.length ? (
                courses.map((item, index) => (
                  <div
                    className="col-lg-5 col-xxl-3 col-md-6 col-sm-6"
                    key={index}
                  >
                    <div className="frame-5 mt-1">
                      <div className="text-wrapper-4">{item?.name}</div>
                      <p className="ptext">{item?.description}</p>

                      <div
                        onClick={(e) => {
                          const hasUserPrewaived = progressData?.find(
                            (_a) =>
                              parseInt(_a?.course_id) === parseInt(item?.id)
                          )

                          if (
                            hasUserPrewaived &&
                            (hasUserPrewaived?.prerequisite_status ===
                              'Waive User' ||
                              (hasUserPrewaived?.prerequisite_status ===
                                'Waive Admin' &&
                                isPrivateUser))
                          ) {
                            onStart(item?.name, item?.id)
                          } else if (canUserTakeCourse(item?.id)) {
                            localStorage.setItem('pre_req_met', item?.id)
                            onStart(item?.name, item?.id)
                          } else if (isPrivateUser) {
                            // move to /find-course
                            navigate('/find-course')
                          } else {
                            setUserCourseId(item?.id)
                            setUserCourseName(item?.name)
                          }
                        }}
                      >
                        <ButtonBase
                          className="button-base-instance"
                          icon="false"
                          size="lg"
                          text={isResume(item?.id) ? 'Resume' : 'Apply'}
                          textClassName="design-component-instance-node"
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p> No Course </p>
              )}
            </div>

            <div className="text-wrapper-3 mt-4">Instructor Certifications</div>
            <div>
              <img className="line" alt="Line" src={line191svg} />
            </div>
            <div className="row no-gutters table-responsive">
              <Table
                data={[]}
                columns={InstructorAssessmentBaseColumns}
                className="insCertTable"
              />
            </div>
            <div className="text-wrapper-3 mt-4">Past Applications</div>
            <div>
              <img className="line" alt="Line" src={line191svg} />
            </div>
            {loading ? (
              <Spinner sx={{ marginTop: 4 }} />
            ) : (
              <div className="row no-gutters table-responsive">
                <Table
                  data={assessmentLogData}
                  columns={InstructorAssessmentColumn}
                  className="insCertTable"
                />
              </div>
            )}
          </>
        ) : (
          <>
            <section
              className="row px-2 py-4"
              style={{
                borderRadius: '5px',
                backgroundColor: '#f6f3ee',
                textAlign: 'center',
                marginBottom: '0.5rem',
              }}
            >
              {/* <h3 style={{ color: '#ea4b22' }}>
                Required certification not found
              </h3> */}
              <p>
                We were unable to verify your completion of an MHFA course. To
                continue with the application process, you can choose one of the
                following options.
              </p>
            </section>
            <section className="row">
              <div className="col-lg-6">
                <Support email={email} />
              </div>
              <div className="col-lg-6 row">
                <div className="p-3">
                  <h6 style={{ color: '#345E88', fontWeight: 'bold' }}>
                    Option 2
                  </h6>

                  {courses?.length &&
                    courses[parseInt(userCourseId) - 1]?.settings?.[
                      'user:self_waive'
                    ] && (
                      <>
                        <h4 style={{ color: '#ea4b22' }}>
                          Continue to the Instructor training application
                        </h4>
                        <p>
                          Record of course completion not found? Don&apos;t
                          worry. You can move forward with Instructor training
                          without it.
                        </p>
                        <p>
                          <b>
                            But, please note that in order for you to receive
                            your Instructor certification, you must complete a
                            First Aider course within 30 days of completing
                            Instructor training.
                          </b>
                        </p>
                        <div className="row">
                          <div className="col-6 col-lg-4">
                            <ButtonComponent
                              onClick={() => {
                                // let user take assessment
                                // add to localstorage that the user is self-waived for this course_id
                                localStorage.setItem('selfWaived', userCourseId)
                                onStart(userCourseName, userCourseId)
                              }}
                            >
                              Continue
                            </ButtonComponent>
                          </div>
                        </div>
                      </>
                    )}
                  <>
                    <h4
                      style={{
                        color: '#ea4b22',
                        marginTop:
                          courses?.length &&
                          courses[parseInt(userCourseId) - 1]?.settings?.[
                            'user:self_waive'
                          ]
                            ? '2rem'
                            : '0',
                      }}
                    >
                      Take First Aider certification course
                    </h4>
                    <p>
                      {courses?.length &&
                      courses[parseInt(userCourseId) - 1]?.settings?.[
                        'user:self_waive'
                      ] ? (
                        <>
                          We strongly encourage you to register for both courses
                          now so that you meet all requirements. Find a First
                          Aider training to meet the prerequisite.
                        </>
                      ) : (
                        <>
                          Use the find-a-Course page to find the most convenient
                          Mental Health First Aid course option for you
                        </>
                      )}
                    </p>
                    <div className="row">
                      <div className="col-6 col-lg-4">
                        <ButtonComponent
                          onClick={() => {
                            // open an external link
                            window.open(
                              'https://www.mentalhealthfirstaid.org/take-a-course/find-a-course/',
                              '_blank'
                            )
                          }}
                        >
                          Find-a-course
                        </ButtonComponent>
                      </div>
                    </div>
                  </>
                </div>
                {/* <div className="p-3">
                  <h6 style={{ color: '#345E88', fontWeight: 'bold' }}>
                    Option 3
                  </h6>
                  
                </div> */}
              </div>
            </section>
          </>
        )}
      </Layout>
    </>
  )
}
export default InstructorApplication
