import React, { useEffect, useRef, useState } from 'react'
import { Chip } from '../../components/Chip'

import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import { DateCustomInput } from '../../utils/common'
import * as Yup from 'yup'
import { Formik } from 'formik'

import Header from '../../components/Header/Header'
import './AdminCandidateList.css'

import { GET_CANDIDATE } from '../../api/graphql'

import { useQuery } from '@apollo/client'

import Table from '../../components/Table/Table'
import NavBar from '../../components/Navbar/Navbar'
import ReactSelectField from '../../components/ReactSelectField/ReactSelectField'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import moment from 'moment'
import Spinner from '../../components/Spinner/Spinner'
import TextField from './../../components/TextField/TextField'
import DateField from '../../components/DatePickerField/DatePickerField'
import SortIcon from '../../assets/svg/sort.svg'
import { SvgIcon } from '@mui/material'

const AdminRequest = () => {
  const navigate = useNavigate()
  const { loading: requestLoading, refetch } = useQuery(GET_CANDIDATE)

  const [requestList, setRequestList] = useState([])
  const [course, setCourse] = useState()
  const [loadSearches, setLoadSearches] = useState(false)
  const [sortColumn, setSortColumn] = useState()
  const [sortDirection, setSortDirection] = useState(true)
  const formikRef = useRef()
  // console.log('%cAdminCandidateList.jsx line:35 sortColumn, ', 'color: #007acc;', sortColumn, sortDirection);

  const sampleColumns = [
    {
      path: 'id',
      header: '#',
      width: '5%',
    },
    {
      path: 'first_name',
      header: 'First Name',
      icon: <img onClick={(e) => handleSort('first_name')} src={SortIcon} />,
      width: '25%',
    },
    {
      path: 'last_name',
      header: 'Last Name',
      icon: <img onClick={(e) => handleSort('last_name')} src={SortIcon} />,
      width: '25%',
    },
    {
      path: 'email',
      header: 'Email',
      icon: <img onClick={(e) => handleSort('email')} src={SortIcon} />,
      width: '25%',
    },
    {
      path: 'link',
      header: 'Details',
      width: '15%',
    },
  ]

  const handleSort = async (sortBy) => {
    if (formikRef.current) {
      if (formikRef.current.values.sortedData === sortBy) {
        formikRef.current.setFieldValue('sortDirection', 'DESC')
      } else {
        formikRef.current.setFieldValue('sortDirection', 'ASC')
      }
      formikRef.current.setFieldValue('sortedData', sortBy)
      setTimeout(() => {
        getList(formikRef.current.values)
      }, 0)
    }
  }

  const getList = async (values) => {
    console.log('values---', values)
    setLoadSearches(true)
    const { data } = await refetch({
      searchTerm: values?.searchText,
      orderField: values?.sortedData ?? 'email',
      orderDirection: values?.sortDirection ?? 'ASC',
    })
    // console.log("data-----------", data);

    const sampleData = []
    data.GetCandidate?.data?.map((item, index) => {
      const date = moment(item?.submission_date)

      sampleData.push({
        id: index + 1,
        first_name: item?.first_name,
        last_name: item?.last_name,
        email: item.email,
        link: (
          <a
            className="request-detail-link"
            href="javascript:void(0)"
            onClick={() =>
              navigate(`/admin/candidate/view`, {
                state: { id: item.id },
              })
            }
          >
            Details
          </a>
        ),
      })
    })
    setRequestList(sampleData)
    setLoadSearches(false)
  }

  useEffect(() => {
    getList()
  }, [])
  return (
    <>
      <div className="row p-0 nav-area no-gutters">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0 no-gutters">
            <div className="col-2">
              <NavBar user={'admin'} />
            </div>
            <div className="col-10 row admin-request">
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={{
                  fromDate: '',
                  toDate: '',
                  searchText: '',
                  sortedData: 'email',
                  sortDirection: 'ASC',
                }}
                // validationSchema={FormSchema}
                onSubmit={getList}
              >
                {({
                  errors,
                  values,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                }) => {
                  return (
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="instructor-title">Users</div>

                          {/* <p className="there-are-many">
                            Study your time tasks and track them at one place
                          </p> */}
                        </div>
                        <div className="col-lg-1 mt-2"></div>

                        <div
                          className="col-lg-4 mt-2 -ml-4"
                          style={{
                            marginRight: '1.5rem',
                          }}
                        >
                          <TextField
                            placeholder="Search"
                            inputId="course"
                            mandatory={1}
                            value={values.searchText}
                            onChange={(e) => {
                              setFieldValue('searchText', e.target.value)
                            }}
                            style={{
                              width: '100%',
                            }}
                          />
                        </div>
                        {/* <div className="col-lg-2 pe-2"> */}
                        {/* <DateField
                            inputId="fromDate"
                            error={errors.fromDate}
                            touched={touched.fromDate}
                            maxDate={new Date()}
                            minDate={new Date(moment().subtract(3, "month"))}
                            selected={values.fromDate}
                            customInput={
                              <DateCustomInput
                                id="date"
                                touchId="date"
                                setFieldTouched={setFieldTouched}
                              />
                            }
                            onChange={(date) => {
                              setFieldValue("fromDate", date);
                            }}
                          /> */}
                        {/* </div> */}
                        {/* <div className="col-lg-2 pe-2"> */}
                        {/* <DateField
                            inputId="toDate"
                            error={errors.toDate}
                            touched={touched.toDate}
                            maxDate={new Date()}
                            minDate={new Date(moment().subtract(3, "month"))}
                            selected={values.toDate}
                            customInput={
                              <DateCustomInput
                                id="toDate"
                                touchId="toDate"
                                setFieldTouched={setFieldTouched}
                              />
                            }
                            onChange={(date) => {
                              setFieldValue("toDate", date);
                            }}
                          /> */}
                        {/* </div> */}
                        <div className="col-lg-2 mt-2">
                          <Button icon="false" text={'Search'} />
                        </div>
                      </div>
                    </form>
                  )
                }}
              </Formik>
              {loadSearches ? (
                <Spinner sx={{ marginTop: 4 }} />
              ) : (
                <div className="row no-gutters table-responsive mt-3">
                  <Table
                    data={requestList}
                    columns={sampleColumns}
                    // setSortDirection={setSortDirection}
                    // setSortColumn={setSortColumn}
                    className="insCertTable"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AdminRequest
