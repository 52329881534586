import React from "react";
import ReactSelect from "react-select";
import styles from "./ReactSelectField.module.css";

const ReactSelectField = ({
  inputId,
  placeholder,
  label,
  error,
  onChange,
  onBlur,
  touched,
  mandatory,
  options,
  errors,
  value,
  style,
  isTable = false,
  onInputChange,
  disabled = false,
  search = true,
  clear = true,
  ...otherProps
}) => {
  return (
    <div className={`row no-gutters`}>
      {label && <b className={styles.inputLabel}>{label}</b>}

      <div className="row no-gutters">
        <div style={style}>
          <ReactSelect
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: `solid ${
                  error && touched ? "2px red" : "1px var(--grey-2)"
                }`,
                borderRadius: "6px",
                height: "45px",
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                zIndex: 1999,
              }),
            }}
            className="custom-select-control" // Apply the control class
            classNamePrefix="custom-select"
            inputId={inputId}
            placeholder={placeholder}
            options={options}
            error={errors}
            touched={touched}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            isDisabled={disabled}
            onInputChange={onInputChange}
            {...otherProps}
            isClearable={clear ? true : false}
            isSearchable={search ? true : false}
          />
        </div>
        {error && touched && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default ReactSelectField;
