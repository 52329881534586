import React from 'react'
import DatePicker from 'react-datepicker'

const DateField = ({
  inputId,
  label,
  error,
  handleBlur,
  touched,
  errors,
  setFieldValue,
  minDate,
  maxDate,
  customInput,
  selected,
  onChange,
  isTable = false,
  ...otherProps
}) => {
  return (
    <div className={`row no-gutters mt-2`}>
      {label && <span className="fs-8">{label}</span>}
      <div className="row no-gutters mt-1">
        <DatePicker
          id={inputId}
          name={inputId}
          minDate={minDate}
          maxDate={maxDate}
          selected={selected}
          onChange={onChange}
          handleBlur={handleBlur}
          dateFormat="MM-dd-yyyy"
          customInput={customInput}
          error={errors}
          touched={touched}
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          {...otherProps}
        />
        {error && touched && <p className="error-message">{error}</p>}
      </div>
    </div>
  )
}

export default DateField
