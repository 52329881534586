import { gql } from '@apollo/client'
import client from '../config/apolloConfig'

const getUsers = async () => {
  try {
    const GET_USERS = gql`
      query Query {
        GetUser {
          id
          email
          password
        }
      }
    `

    const { data, error } = await client.query({ query: GET_USERS })
    if (error) return error
    return data
  } catch (error) {
    console.log('catch---', error)
  }
}

export const Get_Request = async () => {
  try {
    const GET_REQUEST = gql`
      query GetInstructorTrainingRequest {
        GetInstructorTrainingRequest {
          message
          success
          data {
            id
            email
            organization
            training_type
            status
            submission_date
            created_at
            updated_at
          }
          error
        }
      }
    `

    const { data, error } = await client.query({ query: GET_REQUEST })
    if (error) return error
    return data
  } catch (error) {
    console.log('catch---', error)
  }
}

export const Delete_Learner = async (learnerId) => {
  const DELETE_LEARNER = gql`
    mutation Mutation($learnerId: Int) {
      deleteLearner(learner_id: $learnerId) {
        message
        success
        error
      }
    }
  `
  const { data, error } = await client.mutate({
    mutation: DELETE_LEARNER,
    variables: {
      learnerId,
    },
  })
  if (error) return error
  return data
}

export const Create_Learner = gql`
  mutation InviteLearner(
    $firstName: String
    $lastName: String
    $email: String
    $waivePrecheck: Boolean
    $trainingId: Int
  ) {
    inviteLearner(
      firstName: $firstName
      lastName: $lastName
      email: $email
      waivePrecheck: $waivePrecheck
      training_id: $trainingId
    ) {
      message
      success
      error
    }
  }
`

export const Update_Learner = gql`
  mutation UpdateLearner(
    $firstName: String
    $lastName: String
    $status: String
    $learnerId: Int
    $waivePrecheck: Boolean
  ) {
    updateLearner(
      firstName: $firstName
      lastName: $lastName
      status: $status
      learner_id: $learnerId
      waivePrecheck: $waivePrecheck
    ) {
      message
      success
      error
      status
    }
  }
`

export const CHECK_LMS_USER = gql`
  query CheckLMSUser($email: String) {
    CheckLMSUser(email: $email) {
      message
      success
      error
      status
    }
  }
`

export const CRM_PRE_CHECK = gql`
  mutation CheckCRM($email: String) {
    CheckCRM(email: $email) {
      message
      success
      error
      status
      iSCRM
      crmPassedCourses {
        id
        name
        description
        Active
        created_at
        updated_at
      }
    }
  }
`

export const GET_USER_CERTIFICATES = gql`
  query GetUserCertificates($userId: Int) {
    GetUserCertificates(user_id: $userId) {
      message
      success
      status
      error
      data
    }
  }
`

export const INVITED_USER_RESULT = gql`
  query GetInvitedUserResult($invitationId: Int, $userId: Int) {
    GetInvitedUserResult(invitationId: $invitationId, userId: $userId) {
      message
      success
      error
      status
      data {
        id
        status
        training_id
      }
    }
  }
`
const checkLMSUser = async (email) => {
  try {
    const { data, error } = await client.query({
      query: CHECK_LMS_USER,
      variables: { email },
    })
    if (error) return error
    return data
  } catch (error) {
    console.log('catch---', error)
  }
}

export const Invite_Learner = async (
  firstName,
  lastName,
  email,
  trainingId
) => {
  const INVITE_LEARNER = gql`
  mutation InviteLearner($firstName: String, $lastName: String, $email: String, $waivePrecheck: Boolean, $trainingId: Int) {
    inviteLearner(firstName: $firstName, lastName: $lastName, email: $email, waivePrecheck: $waivePrecheck, training_id: $trainingId) {
      message
      success
      error
    }
  `
  const { data, error } = await client.mutate({
    mutation: INVITE_LEARNER,
    variables: {
      firstName,
      lastName,
      email,
      trainingId,
    },
  })
  if (error) return error
  return data
}

export const Update_Training = gql`
  mutation UpdateTraining(
    $maxLearner: Int
    $status: String
    $courseId: Int
    $trainingId: Int
    $startDate: String
    $endDate: String
    $startTime: String
    $endTime: String
    $timeZone: String
    $address: String
    $state: String
    $city: String
    $zipcode: String
    $location: String
    $notes: String
    $permissions: [String]
  ) {
    updateTraining(
      max_learner: $maxLearner
      status: $status
      course_id: $courseId
      training_id: $trainingId
      start_date: $startDate
      end_date: $endDate
      start_time: $startTime
      end_time: $endTime
      time_zone: $timeZone
      address: $address
      state: $state
      city: $city
      zipcode: $zipcode
      location: $location
      notes: $notes
      permissions: $permissions
    ) {
      message
      success
      error
      status
    }
  }
`

export const Create_Training = gql`
  mutation CreateTraining(
    $maxLearner: Int
    $requestId: Int
    $status: String
    $trainingType: String
    $courseId: Int
    $trainingCourseId: Int
    $startDate: String
    $endDate: String
    $startTime: String
    $endTime: String
    $timeZone: String
    $address: String
    $state: String
    $city: String
    $location: String
    $notes: String
    $zipcode: String
    $permissions: [String]
  ) {
    createTraining(
      max_learner: $maxLearner
      request_id: $requestId
      status: $status
      training_type: $trainingType
      course_id: $courseId
      training_course_id: $trainingCourseId
      start_date: $startDate
      end_date: $endDate
      start_time: $startTime
      end_time: $endTime
      time_zone: $timeZone
      address: $address
      state: $state
      city: $city
      location: $location
      notes: $notes
      zipcode: $zipcode
      permissions: $permissions
    ) {
      message
      success
      error
      status
    }
  }
`
export const INVITE_ALL_LEARNER = gql`
  mutation InviteAllLearner($trainingId: Int) {
    inviteAllLearner(training_id: $trainingId) {
      message
      success
      error
      status
    }
  }
`

export const RESEND_INVITE = gql`
  mutation ReInviteLearner($learnerId: Int) {
    reInviteLearner(learner_id: $learnerId) {
      message
      success
      error
      status
    }
  }
`

export const Update_Request = gql`
  mutation UpdateRequest(
    $email: String!
    $firstName: String!
    $lastName: String!
    $maxTraining: Int
    $intrestedTraining: [String]
    $requestId: Int
    $haveFunding: String
    $submissionDate: String
    $status: String
    $isNationalCouncilMember: String
    $trainingType: String
    $instructorTrainingCount: String
    $jobTitle: String
    $organization: String
    $zipcode: String
    $city: String
    $state: String
    $addressline1: String
    $phoneNumber: String
    $permissions: [String]
  ) {
    updateRequest(
      email: $email
      firstName: $firstName
      lastName: $lastName
      maxTraining: $maxTraining
      intrested_training: $intrestedTraining
      request_id: $requestId
      haveFunding: $haveFunding
      submissionDate: $submissionDate
      status: $status
      isNationalCouncilMember: $isNationalCouncilMember
      trainingType: $trainingType
      instructorTrainingCount: $instructorTrainingCount
      jobTitle: $jobTitle
      organization: $organization
      zipcode: $zipcode
      city: $city
      state: $state
      addressline1: $addressline1
      phoneNumber: $phoneNumber
      permissions: $permissions
    ) {
      message
      success
      error
    }
  }
`

export const UPLOAD_CSV_MUTATION = gql`
  mutation InviteMultipleLeaner($csvData: csvInput, $trainingId: Int) {
    inviteMultipleLeaner(csvData: $csvData, training_id: $trainingId) {
      message
      success
      error
      status
    }
  }
`

export const Create_Request = gql`
  mutation CreateRequest(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String
    $addressline1: String
    $state: String
    $city: String
    $zipcode: String
    $organization: String
    $jobTitle: String
    $instructorTrainingCount: String
    $trainingType: String
    $isNationalCouncilMember: String
    $status: String
    $submissionDate: String
    $haveFunding: String
    $intrestedTraining: [String]
    $maxTraining: Int
    $permissions: [String]
  ) {
    createRequest(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      addressline1: $addressline1
      state: $state
      city: $city
      zipcode: $zipcode
      organization: $organization
      jobTitle: $jobTitle
      instructorTrainingCount: $instructorTrainingCount
      trainingType: $trainingType
      isNationalCouncilMember: $isNationalCouncilMember
      status: $status
      submissionDate: $submissionDate
      haveFunding: $haveFunding
      intrested_training: $intrestedTraining
      maxTraining: $maxTraining
      permissions: $permissions
    ) {
      message
      success
      error
    }
  }
`

export const Create_Registration = gql`
  mutation UserRegistration(
    $email: String!
    $firstName: String!
    $lastName: String
    $phoneNumber: String
    $mhfaConnect: String
    $addressline1: String
    $addressline2: String
    $state: String
    $city: String
    $zipcode: String
    $mfaOptions: String
    $isPartOfOrganization: Boolean
    $organization: String
    $jobTitle: String
    $getNewsletter: Boolean
    $publicProfile: Boolean
    $dietaryRestrictions: String
    $lmsCourseId: Int
    $tag: String
    $confirmPassword: String
    $password: String
    $describeDietaryRestrictions: String
  ) {
    userRegistration(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      mhfaConnect: $mhfaConnect
      addressline1: $addressline1
      addressline2: $addressline2
      state: $state
      city: $city
      zipcode: $zipcode
      mfaOptions: $mfaOptions
      isPartOfOrganization: $isPartOfOrganization
      organization: $organization
      jobTitle: $jobTitle
      getNewsletter: $getNewsletter
      publicProfile: $publicProfile
      dietaryRestrictions: $dietaryRestrictions
      lmsCourseId: $lmsCourseId
      tag: $tag
      confirmPassword: $confirmPassword
      password: $password
      describeDietaryRestrictions: $describeDietaryRestrictions
    ) {
      message {
        isError
        message
        text
      }
      success
      status
      token
      data {
        id
        email
        is_active
        is_council_member
      }
    }
  }
`

export const CREATE_ASSESSMENT_LOG = gql`
  mutation updateAssestment_log(
    $email: String!
    $course_id: Int!
    $inputData: [AssessmentInput]!
    $reassessment_date: String!
  ) {
    updateAssestment_log(
      email: $email
      course_id: $course_id
      inputData: $inputData
      reassessment_date: $reassessment_date
    ) {
      message
      status
      success
      data {
        id
        status
        course {
          id
          name
          description
        }
        user_id
        start_time
        end_time
      }
    }
  }
`

export const UPDATE_CANDIDATE_STATUS = gql`
  mutation UpdateCandidateStatus(
    $courseId: Int
    $userId: Int
    $status: String
  ) {
    UpdateCandidateStatus(
      course_id: $courseId
      user_id: $userId
      status: $status
    ) {
      message
      success
      error
      status
    }
  }
`

export const CREATE_USER_LOG = gql`
  mutation CreateOrUpdateUserLog(
    $logModule: String
    $logDescription: String
    $userId: Int
  ) {
    CreateOrUpdateUserLog(
      log_module: $logModule
      log_description: $logDescription
      user_id: $userId
    ) {
      message
      success
      error
      status
    }
  }
`
const otpVerify = async (email, otp, userType) => {
  const VERIFY_OTP = gql`
    mutation VerifyOtp($email: String!, $otp: String!, $userType: String) {
      VerifyOtp(email: $email, otp: $otp, userType: $userType) {
        message
        success
        status
      }
    }
  `
  const { data, error } = await client.mutate({
    mutation: VERIFY_OTP,
    variables: {
      otp,
      email,
      userType,
    },
  })
  if (error) return error
  return data
}

const resendOtp = async (email) => {
  const VERIFY_OTP = gql`
    mutation ResendOtp($email: String!) {
      ResendOtp(email: $email) {
        is_email_sent
        message
        status
        success
      }
    }
  `
  const { data, error } = await client.mutate({
    mutation: VERIFY_OTP,
    variables: {
      email,
    },
  })
  if (error) return error
  return data
}

const checkUser = async (email, password, userType) => {
  try {
    const GET_USER_BY_ID = gql`
      query CheckUserDetails(
        $email: String!
        $userType: String!
        $password: String!
      ) {
        CheckUserDetails(
          email: $email
          userType: $userType
          password: $password
        ) {
          message
          success
          status
          token
          data {
            id
            phone_number
            email
          }
        }
      }
    `
    const { data, error } = await client.query({
      query: GET_USER_BY_ID,
      variables: { email, password, userType },
    })
    if (error) return error
    return data
  } catch (error) {
    console.log('catch---', error)
  }
}

export const GET_LEARNER_BY_TRAINING_ID = gql`
  query GetLearnerList($trainingId: Int!) {
    GetLearnerList(training_id: $trainingId) {
      message
      success
      data {
        id
        email
        first_name
        last_name
        status
        created_at
        updated_at
        user_id
        waive_pre_check
      }
      error
    }
  }
`

export const GET_CANDIDATE_DETAIL = gql`
  query GetCandidate($getCandidateDetailsId: Int) {
    GetCandidateDetails(id: $getCandidateDetailsId) {
      message
      success
      error
      status
      candidateData {
        first_name
        last_name
        email
        id
      }
      AssessmentData {
        id
        status
        course_id
        user_id
        start_time
        end_time
        reassessment_date
        assessment_score
        course {
          id
          name
          description
          Active
          created_at
          updated_at
        }
      }
    }
  }
`

export const GET_REQUEST_BY_ID = gql`
  query Query($requestId: Int!) {
    GetInstructorTrainingRequestBYId(request_id: $requestId) {
      message
      success
      error
      data {
        id
        email
        organization
        training_type
        status
        submission_date
        created_at
        updated_at
        first_name
        last_name
        job_title
        phone_number
        city
        state
        zipcode
        addressline1
        isNationalCouncilMember
        intrested_training
        max_training
        have_funding
        permissions
      }
    }
  }
`

export const GET_TRAINING_BY_REQUEST = gql`
  query Query($requestId: Int!) {
    GetTrainingList(request_id: $requestId) {
      error
      message
      success
      data {
        id
        training_type
        status
        created_at
        updated_at
        lms_course_id
        request_id
        max_learner
        training_course_id
        start_date
        end_date
        start_time
        end_time
        time_zone
        address
        state
        city
        zipcode
        location
        notes
      }
    }
  }
`

export const GET_USER_BY_ID = gql`
  query GetUserDetailByUserId($userId: Int!) {
    GetUserDetailByUserId(user_id: $userId) {
      data {
        addressline1
        addressline2
        city
        created_date
        describe_dietary_restrictions
        dietary_restrictions
        get_news_letter
        id
        isActive
        is_active
        is_council_member
        is_email_verified
        is_part_of_organization
        job_title
        mfa_options
        mhfa_connect
        first_name
        last_name
        organization
        otp
        phone_number
        public_profile
        state
        updated_date
        zipcode
        isMHA
      }
    }
  }
`

const GET_USER_BY_USER_ID = async (userId) => {
  try {
    const { data, error } = await client.query({
      query: GET_USER_BY_ID,
      variables: { userId },
    })
    if (error) return error
    return data
  } catch (error) {
    console.log('catch---', error)
  }
}

export const GET_TRAINING_BY_ID = gql`
  query GetTrainingDetails($trainingId: Int) {
    GetTrainingDetails(training_id: $trainingId) {
      message
      success
      error
      data {
        id
        training_type
        status
        created_at
        updated_at
        lms_course_id
        request_id
        max_learner
        training_course_id
        start_date
        end_date
        start_time
        end_time
        time_zone
        address
        state
        city
        zipcode
        location
        notes
        permissions
      }
    }
  }
`

export const GET_USER_LOGS = gql`
  query GetUserLogs(
    $userId: Int
    $startDate: String
    $endDate: String
    $limit: Int
  ) {
    GetUserLogs(
      user_id: $userId
      start_date: $startDate
      end_date: $endDate
      limit: $limit
    ) {
      message
      success
      error
      status
      data {
        id
        user_id
        log_description
        log_module
        log_type
        timestamp
      }
    }
  }
`

export const CHECK_USER = gql`
  mutation CheckUserEmail($email: String!, $mappingId: Int, $userId: Int) {
    CheckUserEmail(email: $email, mappingId: $mappingId, userId: $userId) {
      message
      success
      status
      is_email_sent
      isMHA
      learnUponUserId
      isInvited
      courseId
      learnerStatus
      lmsCourseId
      waivePreCheck
      isProfileCompleted
      data {
        id
        email
      }
    }
  }
`

export const GET_QUESTION = gql`
  query GetQuestionByCourse($id: Int!) {
    GetQuestionByCourse(id: $id) {
      message
      status
      data {
        course {
          code
          display
          description
          mastery_score
          question_randomize
          questions_used
          scoring_type
          status
          id
          name
        }
        question {
          code
          correct_answers
          id
          mastery_score
          max_points
          name
          options
          question
          question_group {
            code
            description
            id
            mastery_score
            name
          }
          question_points
          status
          test_code
          type
        }
      }
    }
  }
`

export const Get_Assestment = gql`
  query GetAssestment($email: String!) {
    GetAssestment(email: $email) {
      message
      status
      data {
        id
        status
        course_id
        user_id
        start_time
        end_time
        assessment_score
        reassessment_date
        is_profile_completed
        course {
          id
          name
        }
      }
    }
  }
`
export const CHECK_ADMIN_REQUEST_EMAIL = gql`
  mutation Mutation($email: String!, $roleId: Int) {
    CheckAdminOrRequestEmail(email: $email, role_id: $roleId) {
      message
      success
      error
      isMHA
      permissions
      data {
        id
        first_name
        last_name
        otp
        email
        is_council_member
        is_email_verified
        phone_number
        isActive
        created_date
        updated_date
        is_active
        mhfa_connect
        addressline1
        addressline2
        state
        city
        zipcode
        mfa_options
        is_part_of_organization
        organization
        job_title
        get_news_letter
        public_profile
        dietary_restrictions
        describe_dietary_restrictions
        isMHA
      }
    }
  }
`

export const GET_ENROLLMENT_COURSES = gql`
  query CheckUserIsCeritifiedMHFA($email: String!, $courseId: Int) {
    CheckUserIsCeritifiedMHFA(email: $email, course_id: $courseId) {
      message
      success
      status
      data {
        id
        name
        description
        Active
        created_at
        updated_at
      }
    }
  }
`

const getEnrollmentCourse = async (email, course_id) => {
  try {
    const { data, error } = await client.query({
      query: GET_ENROLLMENT_COURSES,
      variables: { email, courseId: course_id },
    })
    if (error) return error
    return data
  } catch (error) {
    console.log('catch---', error)
  }
}

export const GET_CANDIDATE = gql`
  query GetCandidate(
    $searchTerm: String
    $orderField: String
    $orderDirection: String
  ) {
    GetCandidate(
      searchTerm: $searchTerm
      orderField: $orderField
      orderDirection: $orderDirection
    ) {
      message
      success
      error
      status
      data {
        first_name
        last_name
        email
        id
      }
    }
  }
`

export const Get_Request_Of_Requester = gql`
  query GetRequestOfRequester($requesterId: Int) {
    GetRequestOfRequester(requester_id: $requesterId) {
      message
      success
      error
      status
      data {
        id
        email
        organization
        training_type
        status
        submission_date
        created_at
        updated_at
        first_name
        last_name
        job_title
        phone_number
        city
        state
        zipcode
        addressline1
        isNationalCouncilMember
        instructorTrainingCount
        intrested_training
        max_training
        have_funding
      }
    }
  }
`

export const GET_REQUEST = gql`
  query GetInstructorTrainingRequest {
    GetInstructorTrainingRequest {
      message
      success
      data {
        id
        email
        organization
        training_type
        status
        submission_date
        created_at
        updated_at
        first_name
        last_name
        job_title
        phone_number
        city
        state
        zipcode
        addressline1
        isNationalCouncilMember
        intrested_training
        instructorTrainingCount
        max_training
        have_funding
      }
      error
    }
  }
`

export const Get_LMS_Course = gql`
  query Query {
    GetLMSCourse {
      data {
        id
        name
      }
    }
  }
`

export const GET_COURSE = gql`
  query GetCourse {
    GetCourse {
      message
      success
      status
      data {
        id
        code
        name
        description
        status
        display
        mastery_score
        scoring_type
        question_randomize
        questions_used
        created_at
        Active
        updated_at
        settings
      }
    }
  }
`

export const GET_LMS_COURSE = gql`
  query GetLMSCourse {
    GetLMSCourse {
      message
      success
      data {
        id
        name
        source_id
        keywords
        number_of_modules
        owner_first_name
        owner_last_name
        owner_email
        owner_id
        created_at
        date_published
        description_text
        difficulty_level
      }
      error
    }
  }
`

export const CREATE_SUPPORT = gql`
  mutation CreateSupport(
    $email: String!
    $firstName: String
    $lastName: String
    $state: String
    $newEmail: String
  ) {
    CreateSupport(
      email: $email
      firstName: $firstName
      lastName: $lastName
      state: $state
      newEmail: $newEmail
    ) {
      message
      success
      status
      error
    }
  }
`
export const UPDATE_SUPPORT = gql`
  mutation UpdateSupport(
    $firstName: String
    $lastName: String
    $email: String
    $state: String
    $supportId: Int
    $status: String
  ) {
    UpdateSupport(
      firstName: $firstName
      lastName: $lastName
      email: $email
      state: $state
      support_id: $supportId
      status: $status
    ) {
      message
      success
      status
      error
    }
  }
`

export const GET_REPORT = gql`
  query GetAssessmentProgressReport(
    $startDate: String
    $endDate: String
    $timeOffSet: Int
  ) {
    GetAssessmentProgressReport(
      start_date: $startDate
      end_date: $endDate
      timeOffSet: $timeOffSet
    ) {
      success
      message
      data {
        id
        status
        waive_status
        isCompleted
        course_id
        user_id
        bookmark
        question_id
        questionNumber
        answers
        question_order
        start_date
        user {
          id
          first_name
          last_name
          otp
          email
          is_council_member
          is_email_verified
          phone_number
          isActive
          created_date
          updated_date
          is_active
          mhfa_connect
          addressline1
          addressline2
          state
          city
          zipcode
          mfa_options
          is_part_of_organization
          organization
          job_title
          get_news_letter
          public_profile
          dietary_restrictions
          describe_dietary_restrictions
          isMHA
        }
        score
        end_date
        start_time
        created_at
        updated_at
        prerequisite_status
      }
      error
      status
    }
  }
`

export const GET_SUPPORT = gql`
  query GetSupport(
    $searchTerm: String
    $startDate: String
    $endDate: String # $status: String
  ) {
    GetSupport(
      searchTerm: $searchTerm
      startDate: $startDate
      endDate: $endDate # status: $status
    ) {
      message
      success
      status
      data {
        id
        first_name
        last_name
        state
        is_deleted
        email
        created_at
        status
        prevEmail
      }
    }
  }
`

export const GET_SUPPORT_BY_ID = gql`
  query Data($supportId: Int) {
    GetSupportById(support_id: $supportId) {
      data {
        id
        first_name
        state
        email
        is_deleted
        created_at
        status
        prevEmail
        notes
        last_name
      }
      message
      success
      status
      error
    }
  }
`

const getAssessmentDetail = async (assessment_id) => {
  const GET_FIELDS = gql`
    query Query($assessment_id: Int!) {
      GetAssessmentDetail(assessment_id: $assessment_id) {
        id
        assessment_id
        qn_type
        qn
        opt1
        opt2
        opt3
        opt4
        answer
        opt_right
        weightage
        status
        creation
      }
    }
  `
  const { data, error } = await client.query({
    query: GET_FIELDS,
    variables: { assessment_id },
  })
  if (error) return error
  return data
}

export const INTEGTRATE_SHOPIFY = gql`
  mutation IntegrateShopify($email: String) {
    IntegrateShopify(email: $email) {
      message {
        isError
        message
        text
      }
      success
      status
      data
      error
    }
  }
`

const integrateShopify = async (email, tagName) => {
  const { data, error } = await client.query({
    query: INTEGTRATE_SHOPIFY,
    variables: { email: email, tagName: tagName },
  })
  if (error) return error
  return data
}

export const GET_ASSESSMENT_PROGRESS = gql`
  query GetAssessmentProgress($userId: Int, $courseId: Int) {
    GetAssessmentProgress(user_id: $userId, course_id: $courseId) {
      data
      message
      status
      success
    }
  }
`
/** {
        question_id
        course_id
        user_id
        isCompleted
        questionNumber
        answers
      } */

export const CREATE_ASSESSMENT_PROGRESS = gql`
  mutation CreateAssessment_progress(
    $isResumeAssessment: Boolean
    $questionOrder: JSONB
    $isLastQuestion: Boolean
    $answer: JSONB
    $userId: Int
    $courseId: Int
    $startTime: String
    $questionNumber: Int
    $isCompleted: Boolean
    $questionId: Int
  ) {
    createAssessment_progress(
      isResumeAssessment: $isResumeAssessment
      question_order: $questionOrder
      isLastQuestion: $isLastQuestion
      answer: $answer
      user_id: $userId
      course_id: $courseId
      start_time: $startTime
      questionNumber: $questionNumber
      isCompleted: $isCompleted
      question_id: $questionId
    ) {
      message
      success
      status
      data
      error
    }
  }
`

export const GET_ALL_ASSESSMENT_PROGRESS = gql`
  query GetAllAssessmentProgress($userId: Int) {
    GetAllAssessmentProgress(user_id: $userId) {
      message
      success
      status
      data
    }
  }
`

/** 
{
        question_id
        course_id
        user_id
        isCompleted
        start_time
        answers
        questionNumber
      } 
*/

export const GET_ALL_SUPPORT = gql`
  query Query {
    GetSupport {
      data {
        email
        first_name
        id
        is_deleted
        last_name
        state
        status
        prevEmail
      }
    }
  }
`

export const UPDATE_SELF_WAIVE_ASSESSMENT = gql`
  mutation UpdateSelfWaiveForAssessment(
    $userId: Int
    $courseId: Int
    $status: String
  ) {
    UpdateSelfWaiveForAssessment(
      user_id: $userId
      course_id: $courseId
      status: $status
    ) {
      message
      success
      error
      status
    }
  }
`

const getAllAssessmentProgress = async (user_id) => {
  const { data, error } = await client.query({
    query: GET_ALL_ASSESSMENT_PROGRESS,
    variables: { userId: user_id },
  })
  if (error) return error
  return data
}

export default {
  getUsers,
  checkUser,
  getAssessmentDetail,
  otpVerify,
  resendOtp,
  GET_USER_BY_USER_ID,
  getAllAssessmentProgress,
  getEnrollmentCourse,
  checkLMSUser,
  integrateShopify,
}
