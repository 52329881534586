import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import depCounsilSvg from '../../assets/png/Missouri-01.png'
import illustrationSvg from '../../assets/png/Maryland-01.png'
import logoPng from '../../assets/png/site-logo.png'
import downSvg from '../../assets/svg/down-arrow.svg'

import storage, { storeToken } from '../../auth/storage'
import api, {
  CHECK_USER,
  CRM_PRE_CHECK,
  GET_ALL_ASSESSMENT_PROGRESS,
  GET_USER_CERTIFICATES,
} from '../../api/graphql'
// import "./BecomeAnInstructormodule.css";
import { isEmail } from '../../utils/ValidationUtil'
import { toast } from 'react-toastify'
import { focusInputError } from '../../utils/inputUtil'
import { useMutation, useQuery } from '@apollo/client'
import { useUser } from '../../auth/AuthState'
import moment from 'moment'
import OtpVerify from '../../components/OtpVerify/OtpVerify'
import PasswordVerify from '../../components/PasswordVerify/PasswordVerify'
import EmailVerify from '../../components/EmailVerify/EmailVerify'
import { useUpdateUserActivity } from '../../hooks/useUpdateUserActivity'
import { USER_ACTIVITY_STATUS } from '../../utils/common'

const BecomeInstructor = () => {
  const navigate = useNavigate()
  const [tabSelected, setTabSelected] = useState(1)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const [showOTTProcess, setShowOTTProcess] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [otpLoading, setOTPLoading] = useState(false)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const { updateUserActivity } = useUpdateUserActivity()

  const paramUserId = urlSearchParams.get('userId')
  const paramsMappingId = urlSearchParams.get('invitationId')

  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [otpError, setOtpError] = useState(false)

  const [password, setPassword] = useState('')
  const [passError, setPassError] = useState(false)
  const [userData, setUserData] = useState([])
  const [CHECK_USER_EMAIL] = useMutation(CHECK_USER)
  const [CRM_CHECK] = useMutation(CRM_PRE_CHECK)

  const { dispatch, userState } = useUser()

  // Queries
  const { loading: progressLoading, refetch: refetchProgress } = useQuery(
    GET_ALL_ASSESSMENT_PROGRESS,
    { variables: { userId: userState?.user?.id } }
  )

  const { loading: certificatesLoading, refetch: refetchCertificates } =
    useQuery(GET_USER_CERTIFICATES, {
      variables: {
        userId: 0,
      },
    })

  const checkEnrollments = async (courseData) => {
    const certificates = refetchCertificates({
      userId: userState?.user?.id,
    })
    if (certificates?.data?.GetUserCertificates?.data.length) {
      // If it found in lms then allow for assessment
      return true
    }
    // else {
    //   const { data } = await CRM_CHECK({ variables: { email } }) // check in crm

    //   if (data?.CheckCRM?.iSCRM) {
    //     if (data?.CheckCRM?.crmPassedCourses?.length)
    //       // checking crm course
    //       return true
    //   }
    // }
    return false
  }

  //naviagate private user  to particular assessment based on course Id
  const navigateToAssessment = async () => {
    await updateUserActivity(
      userState?.inviteCourse?.courseId,
      userState?.user?.id,
      USER_ACTIVITY_STATUS.STARTED
    )
    navigate(`/instructor-assessment/${userState?.inviteCourse?.courseId}`)
  }

  // navigate user based on Invitation status
  const navigateInvitedUser = async () => {
    const { data: fetchedProgress } = await refetchProgress()
    // check if user has a progress with this as course_id userState?.inviteCourse?.courseId
    const userProgress =
      fetchedProgress?.GetAllAssessmentProgress?.data?.filter(
        (item) =>
          parseInt(item.course_id) ===
          parseInt(userState?.inviteCourse?.courseId)
      )
    // console.log(userProgress)
    const learnerStatus = userState?.inviteCourse?.learnerStatus
    const isProfileCompleted = userState?.inviteCourse?.isProfileCompleted
    const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss')
    localStorage.setItem('isInvited', 'true')
    localStorage.setItem('startTime', formattedDate)
    localStorage.setItem('isExamStarted', 'true')
    localStorage.setItem(
      'courseId',
      parseInt(userState?.inviteCourse?.courseId)
    )
    localStorage.setItem(
      'pre_req_met',
      parseInt(userState?.inviteCourse?.courseId)
    )

    localStorage.setItem(
      'isAdminWaived',
      userState?.inviteCourse?.waivePreCheck
    )

    if (userState?.inviteCourse?.waivePreCheck) {
      localStorage.setItem('adminWaived', userState?.inviteCourse?.courseId)
    }

    if (
      (learnerStatus === 'invited' ||
        learnerStatus === 'clicked' ||
        learnerStatus === 'not_sent' ||
        learnerStatus === 'started') &&
      // FIXME: Only checking if the user has any progress here
      userProgress?.length === 0
    ) {
      await navigateToAssessment() // navigate user based on assessment
    } else if (
      (learnerStatus === 'invited' ||
        learnerStatus === 'clicked' ||
        learnerStatus === 'not_sent' ||
        learnerStatus === 'started') &&
      // FIXME: Only checking if the user has any progress here and the status is started
      userProgress?.length > 0 &&
      userProgress[0]?.status === 'Started'
    ) {
      await navigateToAssessment() // navigate user based on assessment
    } else {
      // check if user profile has been completed or not
      if (
        !isProfileCompleted &&
        userProgress &&
        userProgress[0]?.status === 'Passed'
        // learnerStatus === 'passed' // if user is passed and Profile Pending
      ) {
        navigate('/score', {
          state: {
            course_id: userState?.inviteCourse?.courseId,
            isFailerPage: false,
          },
        })
      } else if (
        isProfileCompleted &&
        userProgress &&
        userProgress[0]?.status === 'Passed'
        // learnerStatus === 'passed' // if user is passed and Profile Completed
      ) {
        navigate('/score', {
          state: {
            course_id: userState?.inviteCourse?.courseId,
            isFailerPage: false,
          },
        })
      } else {
        navigate('/score', {
          state: {
            course_id: userState?.inviteCourse?.courseId,
            isFailerPage: true,
          },
        })
      }
    }
  }

  //check for Invited user
  const checkInvitedUser = async () => {
    if (userState?.inviteCourse?.invitedUser) {
      if (!userState?.inviteCourse?.waivePreCheck) {
        await checkConditionForPrivateUser(email)
      } else {
        await navigateInvitedUser()
      }
    } else {
      navigate('/instructor-application')
    }
  }

  //check Pre- Req check
  const checkConditionForPrivateUser = async (email) => {
    const certificateData = await api.getEnrollmentCourse(
      email,
      userState?.inviteCourse?.courseId
    )
    const courseData = certificateData?.CheckUserIsCeritifiedMHFA?.data // checking for invited course pre-req check at LMS
    const isEnrollmentExistForCurrentUser = await checkEnrollments(courseData)

    if (isEnrollmentExistForCurrentUser) {
      navigateInvitedUser()
    } else {
      navigate('/find-course')
    }
  }

  const checkPassword = async () => {
    setPasswordLoading(true)
    const response = await api.checkUser(
      email,
      password,
      userState?.inviteCourse?.invitedUser ? 'Private' : 'Public'
    )
    setPasswordLoading(false)
    if (
      response.CheckUserDetails.success &&
      response.CheckUserDetails.status === 200
    ) {
      localStorage.setItem('userType', 'candidate')
      dispatch({ type: 'USER_TYPE', payload: 'candidate' })
      localStorage.setItem('password', password)
      localStorage.setItem('isPasswordVerify', true)
      await storage.storeUserData([userData])
      await checkInvitedUser() // handle case for invited user
      storeToken(response?.CheckUserDetails?.token)
    } else {
      setPassError(true)
    }
  }

  const handleOtpChange = (index, value) => {
    setOtpError(false)
    const newOtp = [...otp]
    newOtp[index] = value

    if (index < 5 && value) {
      const nextInput = document.getElementById(`otp${index + 2}`)
      if (nextInput) {
        nextInput.focus()
      }
    }

    setOtp(newOtp)
  }

  const handleOtpVerify = async () => {
    if (otp?.length < 6) {
      setOtpError(true)
      return
    }
    setOTPLoading(true)
    const res = await api.otpVerify(
      email,
      otp?.join(''),
      userState?.inviteCourse?.invitedUser ? 'Private' : 'Public'
    )
    if (res.VerifyOtp.success && res.VerifyOtp.status === 200) {
      localStorage.setItem('otpVerified', true)
      localStorage.setItem('userType', 'candidate')
      dispatch({ type: 'USER_TYPE', payload: 'candidate' })
      await checkInvitedUser() // handle case for invited user
      setOtpError(false)
      setOTPLoading(false)
    } else {
      setOtpError(true)
      setOTPLoading(false)
    }
    setOTPLoading(false)
  }

  const handleChangeEmail = () => {
    setOtp(['', '', '', '', '', ''])
    setEmail('')
    setShowOTTProcess(false)
    document.getElementById('emailId') &&
      document.getElementById('emailId').focus()
    return
  }

  const handleResend = async () => {
    setOtp(['', '', '', '', '', ''])
    setOtpError(false)
    const res = await api.resendOtp(email)
    if (res.ResendOtp.success && res.ResendOtp.status === 200) {
      toast.success('Otp send successfully in Email')
    } else {
      toast.error('Something went to wrong please try again')
    }
  }

  const sendEmail = async () => {
    setEmailError(false)
    if (email == '' || !isEmail(email)) {
      focusInputError('emailId', true)
      setEmailError(true)
      return
    }
    setEmailLoading(true)
    dispatch({ type: 'EMAIL', payload: email })

    if (paramUserId && paramsMappingId) {
      const { data } = await CHECK_USER_EMAIL({
        variables: {
          email: email?.trim()?.toLowerCase(),
          userId: +paramUserId,
          mappingId: +paramsMappingId,
        },
      })
      localStorage.setItem('invitationId', paramsMappingId)
      dispatch({ type: 'USER_DATA', payload: data.CheckUserEmail.data })

      localStorage.setItem('userData', JSON.stringify(data.CheckUserEmail.data))

      if (data?.CheckUserEmail?.learnUponUserId) {
        dispatch({
          type: 'CHECK_LMS',
          payload: true,
        })
        localStorage.setItem(
          'learnUponUserId',
          JSON.stringify(data.CheckUserEmail.learnUponUserId)
        )
      }

      if (data.CheckUserEmail?.lmsCourseId) {
        localStorage.setItem('lmsCourseId', data.CheckUserEmail?.lmsCourseId)
      }

      if (
        data.CheckUserEmail?.isInvited &&
        data.CheckUserEmail?.status === 200
      ) {
        dispatch({
          type: 'SET_COURSE',
          payload: {
            courseId: data.CheckUserEmail?.courseId,
            invitedUser: true,
            learnerStatus: data.CheckUserEmail?.learnerStatus,
            lmsCourseId: data.CheckUserEmail?.lmsCourseId,
            waivePreCheck: data.CheckUserEmail?.waivePreCheck,
            isProfileCompleted: data.CheckUserEmail?.isProfileCompleted,
          },
        })
        if (data?.CheckUserEmail?.isMHA) {
          setShowPass(true)
          setOtpError(false)
        } else {
          setShowOTTProcess(true)
        }
        setEmailLoading(false)
      } else {
        setEmailLoading(false)
        toast.error(data.CheckUserEmail?.message)
        return
      }
    } else {
      const { data } = await CHECK_USER_EMAIL({
        variables: {
          email,
        },
      })

      if (data?.CheckUserEmail?.data) {
        dispatch({ type: 'USER_DATA', payload: data.CheckUserEmail.data })

        localStorage.setItem(
          'userData',
          JSON.stringify(data.CheckUserEmail.data)
        )
      }
      if (data?.CheckUserEmail?.learnUponUserId) {
        localStorage.setItem(
          'learnUponUserId',
          JSON.stringify(data.CheckUserEmail.learnUponUserId)
        )
      }
      if (data?.CheckUserEmail?.isMHA) {
        dispatch({
          type: 'CHECK_LMS',
          payload: true,
        })
        setShowPass(true)
        setOtpError(false)
      } else {
        setShowOTTProcess(true)
      }
    }

    setEmailLoading(false)
    localStorage.setItem('emailKey', email)
  }

  const parseParams = async (querystring) => {
    const params = new URLSearchParams(querystring)
    let obj = { email: '', course: '' }
    for (const key of params.keys()) {
      if (key == 'email') {
        obj.email = params.get(key)
        setEmail(params.get(key))
      } else if (key == 'course') {
        obj.course = params.get(key)
      }
    }
    localStorage.setItem('adData', obj)
  }

  useEffect(() => {
    parseParams(window.location.search)
  }, [])

  return (
    <>
      <div className="row no-gutters pt-2 mb-3">
        <div className="col-4 ps-5">
          <div className="row no-gutters">
            <a
              href="https://www.mentalhealthfirstaid.org/become-an-instructor/"
              target="_blank"
            >
              <img
                alt="main-side"
                src={require('../../assets/png/header-logo.png')}
                height={'50px'}
              />
            </a>
          </div>
        </div>
      </div>
      <div className={'row no-gutters rowReverse'}>
        {showOTTProcess ? (
          <>
            <OtpVerify
              email={email}
              otpError={otpError}
              handleOtpVerify={handleOtpVerify}
              handleResend={handleResend}
              handleChangeEmail={handleChangeEmail}
              handleOtpChange={handleOtpChange}
              otp={otp}
              otpLoading={otpLoading}
            />
          </>
        ) : (
          <>
            <div className="col-lg-6 px-5  bgGradMaskGroupBg">
              <div className="row no-gutters  px-4 py-4">
                <div className="row no-gutters head pb-3 mt-2">
                  Become an Adult or Youth MHFA Instructor
                </div>

                <div className="row no-gutters p-1">
                  {tabSelected == 1 ? (
                    <>
                      <div className="row no-gutters">
                        <p className="ptext">
                        Earn your <a
                            href="https://www.mentalhealthfirstaid.org/population-focused-modules/adults/"
                            target="_blank"
                          >
                            Adult Mental Health First Aid
                          </a>{' '}
                          or{' '}
                          <a
                            href="https://www.mentalhealthfirstaid.org/population-focused-modules/youth/"
                            target="_blank"
                          >
                            Youth MHFA
                          </a>{' '} Instructor certification and empower your community to address mental health and substance use challenges head-on with an evidence-based, nationally recognized program. Take our three-day training and learn how to facilitate the curriculum with self-paced pre-work and expert-led coaching. You’ll wrap up by presenting a section of the course for evaluation and leave feeling confident in your ability to teach life-saving skills to others.
                        </p>
                      </div>
                      <div className="row no-gutters">
                        <div className="col-lg-12">
                          <div className="secHead">Pricing</div>
                          <div>
                            <p className="ptext">
                              National Council member: $2,000 |{' '} Nonmember: $2,200
                            </p>
                            <p className="ptext">
                              Existing Instructors certified to teach Adult,
                              Youth or teen MHFA receive a $200 discount when
                              registering for another Instructor certification
                              training.
                            </p>
                            <p className="ptext" style={{ fontWeight: 'bold' }}>
                              If your organization is considering training six
                              or more Instructors, ask about scheduling an{' '}
                              <a
                                href="https://www.mentalhealthfirstaid.org/hosted-private-instructor-training/"
                                target="_blank"
                              >
                                On-site Private Instructor Training
                              </a>.
                            </p>
                            <p className="ptext">
                            	<div className="secHead">Learn more:</div>
                              <ul>
                                <li>
                                  Attend an{' '}
                                  <a
                                    href="https://thenationalcouncil.swoogo.com/become_a_new_instructor/register?ref=application-page"
                                    target="_blank"
                                  >
                                    informational webinar
                                  </a>.
                                </li>
                                <li>
                                  {' '}
                                  <a
                                    href="https://go.thenationalcouncil.org/NzczLU1KRi0zNzkAAAGRrtYuruVhz8ztnmttX21XWUFRce16nSOS3ja6pmNFFB8Tb5OrQhthgDyqIO-wlDhA-oLSUyA="
                                    target="_blank"
                                  >
                                    Review the journey
                                  </a>{' '}
                                  from Instructor training to teaching that
                                  first class.{' '}
                                </li>
                                <li>
                                  {' '}
                                  <a
                                    href="https://www.mentalhealthfirstaid.org/become-an-instructor/certification-process/?mkt_tok=NzczLU1KRi0zNzkAAAGRrtYurvxE15y-99I9XIENp9ymwEVTa4Ebh-HBAs7C5ERzLL2Zj9u3dUIyYOUqFEazljS96R1e72cWoQJB0PQ"
                                    target="_blank"
                                  >
                                    Find more information
                                  </a>{' '}
                                  about pricing and requirements.{' '}
                                </li>
                              </ul>
                            </p>
                            <p className="ptext">
                              <b>
                                If you’re interested in becoming a teen MHFA Instructor{' '}
                                <a
                                  href="https://www.mentalhealthfirstaid.org/become-an-instructor/applying-to-teen-mental-health-first-aid-information-and-resources/"
                                  target="_blank"
                                >
                                  learn more about the program
                                </a>.
                              </b>
                            </p>
                            <p className="ptext">
                              Maryland residents only: Visit the{' '}
                              <a
                                href="https://www.mhamd.org/what-we-do/training/mental-health-first-aid-maryland/"
                                target="_blank"
                              >
                                MHFA Maryland website
                              </a>{' '}
                              to apply for in-state Instructor trainings.
                            </p>
                            <p className="ptext">
                              Missouri residents only: Visit the{' '}
                              <a
                                href="http://mhfamissouri.org/"
                                target="_blank"
                              >
                                MHFA Missouri website
                              </a>{' '}
                              to apply for in-state Instructor trainings.
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : tabSelected == 2 ? (
                    <>
                      <div className="row no-gutters">
                        <p className="ptext">
                          Interested in applying to become an Adult or Youth
                          MHFA Instructor? This document lays out the process
                          from beginning to end.
                        </p>
                        <p className="ptext">
                          Start by creating an account in MHFA Connect the go-to
                          platform for completing your certification, managing
                          your future First Aider courses and accessing
                          resources. If you already have a MHFA Connect account,
                          please email MHFAApplications@TheNationalCouncil.org
                          for access to the application.
                        </p>
                        <p className="ptext">
                          Current Mental Health First Aid Instructors seeking
                          certification in teen Mental Health First Aid must
                          complete the tMHFA interest form to start the process.
                          Whether you are an independent Instructor or are
                          planning to teach tMHFA on behalf of an organization,
                          you must speak with a member of our team before you
                          can apply to become a tMHFA Instructor. Our team can
                          assist in answering any questions you may have and
                          advise you on next steps.
                        </p>
                      </div>
                      <div className="row no-gutters">
                        <div className="steps">
                          STEP 1: CREATE AN ACCOUNT
                          <img src={downSvg} style={{ float: 'right' }} />
                        </div>
                        <div className="steps mt-1">
                          STEP 2: CONFIRM YOUR ACCOUNT
                          <img src={downSvg} style={{ float: 'right' }} />
                        </div>
                        <div className="steps mt-1">
                          STEP 3: COMPLETE THE APPLICATION
                          <img src={downSvg} style={{ float: 'right' }} />
                        </div>
                        <div className="steps mt-1">
                          STEP 4: REGISTER FOR A COURSE
                          <img src={downSvg} style={{ float: 'right' }} />
                        </div>
                        <div className="steps mt-1">
                          STEP 5: ORDER MATERIALS AND START YOUR PRE-WORK
                          <img src={downSvg} style={{ float: 'right' }} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row no-gutters">
                        <p className="ptext">
                          To earn a second certification in either Adult or
                          Youth Mental Health First Aid, please email
                          MHFAApplications@TheNationalCouncil.org for access to
                          the appropriate application.
                        </p>
                        <p className="ptext">
                          Current Mental Health First Aid Instructors seeking
                          certification in teen Mental Health First Aid must
                          complete the tMHFA interest form to start the process.
                          Whether you are an independent Instructor or are
                          planning to teach tMHFA on behalf of an organization,
                          you must speak with a member of our team before you
                          can apply to become a tMHFA Instructor. Our team can
                          assist in answering any questions you may have and
                          advise you on next steps.
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-3 ">
              <div className="row no-gutters">
                <div className="formRight">
                  <div className="ptext" style={{ textAlign: 'right' }}>
                    Need assistance?{' '}
                    <a
                      href={`mailto:${
                        process.env.REACT_APP_LMS_SUPPORT_MAIL ||
                        'mhfaapplications@thenationalcouncil.org'
                      }`}
                      className="orangeText"
                    >
                      Contact us for help
                    </a>
                  </div>
                  <div className="mt-5 row no-gutters">
                    <div>
                      <img src={logoPng} width={'200'} />
                    </div>
                  </div>

                  {showPass ? (
                    <>
                      <PasswordVerify
                        password={password}
                        passError={passError}
                        passwordLoading={passwordLoading}
                        setPassError={setPassError}
                        setPassword={setPassword}
                        checkPassword={checkPassword}
                      />
                    </>
                  ) : (
                    <>
                      <EmailVerify
                        email={email}
                        emailError={emailError}
                        emailLoading={emailLoading}
                        setEmail={setEmail}
                        setEmailError={setEmailError}
                        focusInputError={focusInputError}
                        isEmail={isEmail}
                        sendEmail={sendEmail}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default BecomeInstructor
