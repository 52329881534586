import React from "react";
import PersonImg from "../../assets/png/ellipse-1652.png";
import { ArrowChevronDown2 } from "../../assets/icons/ArrowChevronDown2";
import styles from "./UserBadge.module.css";
import "./UserBadge.css";
import { Image } from "@mui/icons-material";
import ProfileIcon from '../../assets/png/profile.png'
const UserBadge = ({ handleOpen }) => {
  return (
    <div className={`overlap-group ${styles.userBadge}`} onClick={handleOpen}>
      <img src={ProfileIcon} alt="profile"/>
      <b className="text-wrapper-2"></b>
      <ArrowChevronDown2 className="arrow-chevron-down" />
    </div>
  );
};

export default UserBadge;
