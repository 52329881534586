import AsyncStorage from "@react-native-async-storage/async-storage";

const tokenkey = "TOKEN_ID_DOC";
const emailKey = "USER_EMAIL";
const userData = "USER_DATA";
const adData = "AD_DATA";
const learnUponUserId = "LEARN_UPON_USER_ID";

const storeField = async (key, value) => {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (error) {
    console.log("Error storing the Field", error);
  }
};

const removeField = async (key) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (error) {
    console.log("Error removing the Field", error);
  }
};

export const storeToken = async (token) => {
  try {
    localStorage.setItem("accessToken", token);
  } catch (error) {
    console.log("Error storing the token", error);
  }
};

export const getToken = async () => {
  try {
    const val = localStorage.getItem("accessToken");
    return val ? val : null;
  } catch (error) {
    console.log("Error getting the token", error);
  }
};

const storeUserData = async (data) => {
  try {
    await AsyncStorage.setItem(userData, JSON.stringify(data));
  } catch (error) {
    console.log("Error storing the token", error);
  }
};

const getUserData = async () => {
  try {
    const val = await AsyncStorage.getItem(userData);
    return val ? val : null;
  } catch (error) {
    console.log("Error storing the token", error);
  }
};

const getField = async (key) => {
  const val = await AsyncStorage.getItem(key);
  return val ? val : null;
};

const removeId = async () => {
  try {
    await AsyncStorage.removeItem(tokenkey);
    await AsyncStorage.removeItem(userData);
    await AsyncStorage.removeItem(adData);
  } catch (error) {
    console.log("Error at delete customerid", error);
  }
};

export default {
  storeField,
  getField,
  removeId,
  removeField,
  storeUserData,
  getUserData,
};
