import React, { useReducer, createContext, useContext, useState } from "react";
import AuthContext from "./context";

// Initial state

// AuthProvider component
const authReducer = (state, action) => {
  switch (action.type) {
    case "USER_DATA":
      return { ...state, user: action.payload };
    case "VERIFY_USER":
      return { ...state, isPasswordVerify: action.payload };
    case "LOGS":
      return { ...state, message: action.payload };
    case "EMAIL":
      return { ...state, email: action.payload };
    case "SET_COURSE":
      return { ...state, inviteCourse: action.payload };
    case "CHECK_LMS":
      return { ...state, isMHA: action.payload };
    case "USER_TYPE":
      return { ...state, userType: action.payload };
    case "LOGOUT":
      return {
        user: null,
        isPasswordVerify: false,
        message: [],
        email: "",
        inviteCourse: {},
        isMHA: false,
        userType: "",
      };
    default:
      return state;
  }
};
export const AuthProvider = ({ children }) => {
  const [userState, dispatch] = useReducer(authReducer, {
    user: null,
    isPasswordVerify: false,
    message: [],
    email: "",
    inviteCourse: {},
    isMHA: false,
    userType: "",
  });
  const [tags, setTags] = useState("");
  return (
    <AuthContext.Provider value={{ setTags, tags, userState, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export default AuthContext;
