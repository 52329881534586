import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useMutation } from '@apollo/client'
import FormControlLabel from '@mui/material/FormControlLabel'
import { usStatesList } from '../../utils/common'
import { toast } from 'react-toastify'
import TextField from '../../components/TextField/TextField'
import Button from '../../components/Button/Button'
import Spinner from '../../components/Spinner/Spinner'
import { CREATE_SUPPORT } from '../../api/graphql'
import ReactSelectField from '../../components/ReactSelectField/ReactSelectField'

const FormSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().required('Last Name is required'),
  location: Yup.string().trim().required('Location is required'),
  // FIXME: email check using regex
  newEmail: Yup.string()
    .trim()
    .nullable()
    .email()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      'Invalid email format'
    ),
})
const Support = ({ email }) => {
  const [ADD_SUPPORT, { loading: supportLoading }] = useMutation(CREATE_SUPPORT)

  const [submitted, setSubmitted] = useState(false)

  const handleSupportForm = async (values, { resetForm }) => {
    try {
      const { data } = await ADD_SUPPORT({
        variables: {
          firstName: values?.firstName,
          lastName: values?.lastName,
          state: values?.location,
          email: email,
          newEmail: values?.newEmail,
        },
      })
      // console.log('data---', data)
      // toast.success('Form saved successfuly')
      setSubmitted(true)
      resetForm()
    } catch (error) {
      toast.error('error occoured')
    }
  }
  return (
    <div className="row d-flex justify-content-center">
      <div className="col-12">
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: '',
            lastName: '',
            location: '',
            newEmail: '',
          }}
          validationSchema={FormSchema}
          onSubmit={(values, { resetForm }) => {
            handleSupportForm(values, { resetForm })
          }}
        >
          {({
            errors,
            values,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => {
            return (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="row p-3">
                  <div className="">
                    <div className="row">
                      <div className="col">
                        <h6 style={{ color: '#345E88', fontWeight: 'bold' }}>
                          Option 1
                        </h6>
                        <h4 style={{ color: '#ea4b22' }}>
                          Verify prerequisites using a different email address
                        </h4>
                        <p>
                          If you took an eligible First Aider course, but
                          possibly used an email address other than the one
                          provided on the previous screen, please provide us
                          with the following information. We’ll gladly recheck
                          our records using this information.
                        </p>
                        <p style={{ fontStyle: 'italic' }}>
                          Please note that it may take up to 72 hours to verify
                          your information.
                        </p>
                      </div>
                    </div>
                    {submitted ? (
                      <section className="row">
                        <p
                          style={{
                            color: '#ea4b22',
                            fontSize: '22px',
                            fontWeight: 'bold',
                          }}
                        >
                          Thank you! A representative will reach out to you
                          shortly.
                        </p>
                        <div className="col-8 col-lg-6">
                          <Button
                            onClick={() => {
                              setSubmitted(false)
                            }}
                          >
                            Verify Another Email
                          </Button>
                        </div>
                      </section>
                    ) : (
                      <section>
                        <div className="row mt-2">
                          <div className="col d-flex flex-column ">
                            <TextField
                              id="firstName"
                              name="firstName"
                              placeholder="First Name"
                              value={values.firstName}
                              label="First Name *"
                              error={errors.firstName}
                              touched={touched.firstName}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col d-flex flex-column ">
                            <TextField
                              id="lastName"
                              name="lastName"
                              placeholder="Last Name"
                              value={values.lastName}
                              label="Last Name *"
                              error={errors.lastName}
                              touched={touched.lastName}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col d-flex flex-column ">
                            <ReactSelectField
                              label="State *"
                              placeholder="State *"
                              inputId="location"
                              mandatory={1}
                              search={true}
                              error={errors.location}
                              touched={touched.location}
                              value={{
                                label: values.location,
                                value: values.location,
                              }}
                              options={usStatesList}
                              onChange={(text) => {
                                setFieldValue('location', text.label)
                              }}
                              clear={false}
                            />
                          </div>
                          <div className="col d-flex flex-column ">
                            <TextField
                              id="newEmail"
                              name="newEmail"
                              placeholder="Previous Email"
                              value={values.newEmail}
                              label="Previous Email"
                              error={errors.newEmail}
                              touched={touched.newEmail}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row mt-3 flex-column d-flex">
                          <div className="col-6 col-lg-3">
                            <Button type="submit">
                              {supportLoading ? (
                                <Spinner
                                  size="20px"
                                  sx={{
                                    '.MuiCircularProgress-root': {
                                      color: 'white',
                                    },
                                  }}
                                  thickness={5}
                                />
                              ) : (
                                'Submit'
                              )}
                            </Button>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default Support
