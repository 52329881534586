import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './InstructorRegistrationModule.css'

import {
  Create_Registration,
  GET_USER_BY_ID,
  INTEGTRATE_SHOPIFY,
} from '../../api/graphql'
import storage, { storeToken } from '../../auth/storage'

import {
  USER_ACTIVITY_STATUS,
  extractShopifyErrorResponse,
} from '../../utils/common'
import { toast } from 'react-toastify'
import Spinner from '../../components/Spinner/Spinner'
import { useMutation, useQuery } from '@apollo/client'
import { useUser } from '../../auth/AuthState'
import UserRegistrationForm from '../../components/Form/UserRegistrationForm'
import Layout from '../../layouts/Layout'
import { useUpdateUserActivity } from '../../hooks/useUpdateUserActivity'

const InstructorRegistration = () => {
  const {
    tags,
    userState: { user },
  } = useUser()
  const navigate = useNavigate()
  const [userInformation, setUserInformation] = useState()
  const [loading, setLoading] = useState(false)
  const [ADD_REGISTRATION] = useMutation(Create_Registration)
  const [INT_SHOPIFY] = useMutation(INTEGTRATE_SHOPIFY)
  const [getProfileLoading, setGetProfileLoading] = useState(true)
  const { updateUserActivity } = useUpdateUserActivity()

  const location = useLocation()

  const isInvited = useMemo(
    () => localStorage.getItem('isInvited'),
    [localStorage.getItem('isInvited')]
  )

  const { data: UserDataReftch, refetch: UserRefetch } = useQuery(
    GET_USER_BY_ID,
    {
      variables: {
        userId: user?.id,
      },
    }
  )

  const lmsCourseId = useMemo(
    () => localStorage.getItem('lmsCourseId'),
    [localStorage.getItem('lmsCourseId')]
  )

  const handleShopifyError = (
    shopifyResponse,
    integrationProcessState,
    integrationMessages
  ) => {
    const error = JSON.parse(shopifyResponse?.error)
    const errValue =
      typeof error === 'string' ? error : extractShopifyErrorResponse(error)

    integrationProcessState.isError = true
    integrationMessages.push({
      isError: true,
      message: errValue,
    })
  }

  const handleRegistrationError = async (data, integrationProcessState) => {
    await updateUserActivity(
      location?.state?.courseId,
      user?.id,
      USER_ACTIVITY_STATUS.ERROR // update user activity status
    )
    integrationProcessState.isError = true
    integrationProcessState.messages = [...data?.userRegistration.message]
  }

  const handlePrivateUserStatusAndMessageUpdate = async (
    data,
    integrationProcessState
  ) => {
    await updateUserActivity(
      location?.state?.courseId,
      user?.id,
      USER_ACTIVITY_STATUS?.ENROLLED // update user activity status
    )

    // console.log('HANDLING PRIVATE USER', data, integrationProcessState)

    // const integrationMessages = [...data.userRegistration.message]
    // integrationProcessState.messages = integrationMessages
  }

  const handleIntegrationProcess = async (variables, user, tags) => {
    const { data } = await ADD_REGISTRATION({
      variables: variables,
    })

    const integrationProcessState = {
      isShowPassword: false,
      values: variables,
      isError: false,
      messages: [],
    }

    if (data?.userRegistration?.status === 200) {
      await updateUserActivity(
        location?.state?.courseId,
        user?.id,
        USER_ACTIVITY_STATUS.PROFILE // update user activity status
      )
      const userData = JSON.parse(await storage.getField('userData'))
      await storage.storeUserData([data.userRegistration.data])
      await storeToken(data.userRegistration.token)

      const shopData = await INT_SHOPIFY({
        variables: {
          email: user?.email,
        },
      })
      const shopifyResponse = shopData?.data.IntegrateShopify || {}

      const shopifyMessages = shopifyResponse?.message || []

      const integrationMessages = [
        ...data.userRegistration.message,
        ...shopifyMessages,
      ]

      console.log('integerationMessages', integrationMessages)
      const status =
        shopifyResponse?.status !== 200
          ? USER_ACTIVITY_STATUS.ERROR
          : USER_ACTIVITY_STATUS.STORE

      if (shopifyResponse?.status !== 200)
        handleShopifyError(
          // handle error if shopidy
          shopifyResponse,
          integrationProcessState,
          integrationMessages
        )
      integrationProcessState.messages = integrationMessages
      await updateUserActivity(
        location?.state?.courseId,
        user?.id,
        status // update user activity status
      )

      if (isInvited === 'true') {
        await handlePrivateUserStatusAndMessageUpdate(
          data,
          integrationProcessState
        )
      }
    } else {
      await handleRegistrationError(data, integrationProcessState)
    }
    console.log('integrationProcessState', integrationProcessState)
    navigate('/integration-process', { state: integrationProcessState })
  }

  const handleRegistration = async (values) => {
    const variables = {
      email: values.email?.trim()?.toLowerCase(),
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      groupId: values.groupId,
      mhfaConnect: values.mhfaConnect.value,
      addressline1: values.addressline1,
      addressline2: values.addressline2,
      state: values.state.label,
      city: values.city,
      zipcode: values.zipcode,
      describeDietaryRestrictions: values.describeDietaryRestrictions,
      dietaryRestrictions: values.dietaryRestrictions.value,
      publicProfile: values.publicProfile,
      getNewsletter: values.getNewsletter,
      jobTitle: values.jobTitle,
      organization: values.organization,
      isPartOfOrganization: values.isPartOfOrganization,
      mfaOptions: values.mfaOptions.value,
      tag: localStorage.getItem('courseName') || 'youth',
      lmsCourseId: lmsCourseId ? +lmsCourseId : null,
      password: localStorage.getItem('password'),
    }
    if (!userInformation?.isMHA) {
      navigate('/integration-process', {
        state: {
          isShowPassword: true,
          values: variables,
          isError: false,
          courseId: location?.state?.courseId,
        },
      })
    } else {
      setLoading(true)
      try {
        await handleIntegrationProcess(variables, user, tags)
      } catch (error) {
        toast.error(error.message)
      }
      setLoading(false)
    }
  }

  const getInfo = async () => {
    const userData = JSON.parse(await storage.getField('userData'))
    if (userData) {
      const GetUserByUserIdRes = await UserRefetch({
        userId: user?.id,
      })
      if (GetUserByUserIdRes?.data?.GetUserDetailByUserId.data) {
        setUserInformation(GetUserByUserIdRes?.data?.GetUserDetailByUserId.data)
      }
    }
  }
  useEffect(() => {
    getInfo()
    setGetProfileLoading(false)
  }, [])
  return (
    <>
      <Layout title={'INSTRUCTOR APPLICATION'}>
        {!getProfileLoading ? (
          <>
            {' '}
            <p className="there-are-many">
              Please complete this profile before you schedule your Instructor
              training course.
            </p>
            &nbsp;
            <div className="row no-gutters ">
              <div className="col-lg-12 regBox p-3">
                <div className="regHead"> Enter Information</div>

                <UserRegistrationForm
                  email={user?.email}
                  handleRegistration={handleRegistration}
                  loading={loading}
                  userInformation={userInformation}
                />
              </div>
              <div className="col-lg-2"></div>
            </div>
          </>
        ) : (
          <Spinner sx={{}} />
        )}
      </Layout>
    </>
  )
}

export default InstructorRegistration
