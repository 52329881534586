import React , {memo} from "react";
import ButtonStyles from "./Button.module.css";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import load from "../../assets/svg/load.gif";
const Button = ({
  text,
  textColor,
  bgColor,
  width,
  disabled,
  img,
  loading,
  children,
  type,
  ...defaultProps
}) => {
  const StyledButton = styled.button`
    background-color: ${disabled ? "#eaeaea" : bgColor};
    border-radius: 5px;
    border: none;
    padding: 10px 18px;
    color: ${disabled ? "#c0c0c0" : "white"};
    box-shadow: ${disabled
      ? ""
      : "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 6.96169376373291px 17.40423583984375px 0px rgba(224, 89, 42, 0.30)"};
    width: ${width ? width : "100%"};
  `;

  return (
    <StyledButton
      type={type}
      sx={{
        "&.Mui-disabled": {
          background: "#eaeaea!important",
          color: "#c0c0c0!important",
        },
      }}
      disabled={disabled}
      className={`${ButtonStyles.ButtonBgColor} ${
        disabled ? "" : ButtonStyles.ButtonBoxShadow
      }`}
      {...defaultProps}
    >
      {text || children} 
    </StyledButton>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
};
export default memo(Button);
