import React from "react";
import "./InstructorRegistrationModule.css";

import Button from "../../components/Button/Button";

import MessageCard from "../../components/MessageCard/MessageCard";
import { useNavigate } from "react-router-dom";
import Layout from "../../layouts/Layout";

const InstructorRegistration = () => {
  const navigate = useNavigate();

  return (
    <>
      <Layout title={"INSTRUCTOR APPLICATION"}>
        <p className="there-are-many">
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything embarrassing hidden in the middle of text. All
          the Lorem Ipsum generators on the Internet tend to repeat predefined
          chunks as necessary,
        </p>
        &nbsp;
        <div className=" d-flex justify-content-center">
          <MessageCard
            title="Congratulations"
            showLine={true}
            messageData='Lorem Ipsum is simply text of the printing and typesetting industry. Lorem Ipsum has been the industry"'
          >
            <Button
              text="REGISTER FOR INSTRUCTOR TRAINING COURSE"
              style={{ marginTop: "2rem", marginBottom: "1rem" }}
              onClick={() => navigate("/enrollment")}
            />
          </MessageCard>
        </div>
      </Layout>
    </>
  );
};

export default InstructorRegistration;
