import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import styles from "./DropDownMenu.module.css";
const DropDownMenu = ({ anchorEl, open, handleClose, items }) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      {items.map(({ icon, text, onClick }, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            handleClose();
            onClick();
          }}
          className={`d-flex flex-row align-items-center gap-2 m-auto justify-content-center  ${
            styles.DropDownItem
          } ${items.length - 1 === index ? "" : styles.DropDownItemBorder}`}
        >
          <img src={icon} alt="" className={`${styles.IconStyle}`} />{" "}
          <p style={{ margin: 0 }} className={`${styles.DropDownItemText}`}>
            {text}
          </p>
        </MenuItem>
      ))}
    </Menu>
  );
};

DropDownMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default DropDownMenu;
