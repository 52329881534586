import React, { useEffect, useState } from 'react'
import { Chip } from '../../components/Chip'

import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'

import Header from '../../components/Header/Header'
import './AdminRequestModule.css'

import { GET_REQUEST } from '../../api/graphql'

import { useQuery } from '@apollo/client'

import Table from '../../components/Table/Table'
import NavBar from '../../components/Navbar/Navbar'
import ReactSelectField from '../../components/ReactSelectField/ReactSelectField'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import moment from 'moment'
import Spinner from '../../components/Spinner/Spinner'
import { capitalizeFirst } from '../../utils/StringUtils'
import TextField from '../../components/TextField/TextField'
import Fuse from 'fuse.js'

const AdminRequest = () => {
  const navigate = useNavigate()
  const { loading: requestLoading, refetch } = useQuery(GET_REQUEST)

  const [requestList, setRequestList] = useState([])

  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const sampleColumns = [
    {
      path: 'orgName',
      header: 'Organization Name',
      icon: <ImportExportIcon fontSize="12" />,
      width: '20%',
    },
    {
      path: 'trainingType',
      header: 'Training Type',
      icon: <ImportExportIcon fontSize="12" />,
      width: '10%',
    },
    {
      path: 'subDate',
      header: 'Created Date',
      icon: <ImportExportIcon fontSize="12" />,
      width: '15%',
    },
    {
      path: 'trainings',
      header: '# Trainings',
      icon: <ImportExportIcon fontSize="12" />,
      width: '10%',
      content: (item) => (
        <span>
          {item.current_trainings} / {item.max_trainings}
        </span>
      ),
    },
    {
      path: 'name',
      header: 'Name',
      icon: <ImportExportIcon fontSize="12" />,
      width: '20%',
    },

    {
      path: 'status',
      header: 'Status',
      width: '15%',
      icon: <ImportExportIcon fontSize="12" />,
      content: (item) => (
        <Chip
          className="chip-instance"
          divClassName="chip-2"
          status={item.status?.toLowerCase()}
          text={item.status}
        />
      ),
    },
    {
      path: 'link',
      header: 'Details',
      icon: <ImportExportIcon fontSize="12" />,
      width: '15%',
    },
  ]

  const getList = async () => {
    const { data } = await refetch()
    const sampleData = []
    const sortedArr = data.GetInstructorTrainingRequest?.data
      ?.slice()
      ?.sort((a, b) => b.created_at - a.created_at)
    console.log('sortedArr---', sortedArr)
    sortedArr?.map((item, index) => {
      const date = moment(item?.submission_date)
      const formattedDate = date.format('YYYY-MM-DD')
      const intrested_training = item?.intrested_training?.map((str) =>
        capitalizeFirst(str)
      )

      console.log('intrested_training--', intrested_training)
      sampleData.push({
        orgName: item.organization ?? '-',
        id: index + 1,
        subDate: formattedDate !== 'Invalid date' ? formattedDate : '-',
        name: item.first_name + ' ' + item.last_name,
        status: item.status,
        trainingType: intrested_training?.toString(),
        trainings: item?.instructorTrainingCount ?? 0,
        current_trainings: item?.instructorTrainingCount ?? 0,
        max_trainings: item?.max_training ?? 0,
        link: (
          <a
            className="request-detail-link"
            href="javascript:void(0)"
            onClick={() =>
              navigate(`/admin/request/view-request`, {
                state: { id: item.id },
              })
            }
          >
            Details
          </a>
        ),
      })
    })
    setRequestList(sampleData)
    setSearchResults(sampleData)
  }

  function performSearch() {
    if (searchTerm === '') {
      setSearchResults(requestList)
    } else {
      // console.log(`Searching for ${searchTerm}`)
      // FIXME: Can contain multiple options
      const options = {
        keys: ['name', 'orgName', 'status', 'trainingType'],
      }
      const fuse = new Fuse(requestList, options)

      const result = fuse.search(searchTerm)
      console.log('results found:', result)
      setSearchResults(result?.map((r) => r.item))
    }
  }

  useEffect(() => {
    getList()
  }, [])
  return (
    <>
      <div className="row p-0 nav-area no-gutters">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0 no-gutters">
            <div className="col-2">
              <NavBar user={'admin'} />
            </div>
            <div className="col-10 row admin-request">
              <div className="col-lg-4">
                <div className="instructor-title">Contracts</div>

                {/* <p className="there-are-many">
                  Study your time tasks and track them at one place
                </p> */}
              </div>
              <div className="col-lg-4 mt-2">
                <TextField
                  placeholder="Search for training contracts"
                  mandatory={true}
                  value={searchTerm}
                  onChange={(i) => {
                    setSearchTerm(i.target.value)
                  }}
                  style={{
                    width: '100%',
                  }}
                />
              </div>
              <div className="col-lg-2 pe-2 mt-2">
                <Button
                  // className="button-base-instance-grey"
                  icon="false"
                  text={'Search'}
                  onClick={performSearch}
                />
              </div>
              <div className="col-lg-2 mt-2">
                <Button
                  onClick={(e) => navigate('/admin/request/new-request')}
                  icon="false"
                  text={'Add'}
                />
              </div>
              {requestLoading ? (
                <Spinner sx={{ marginTop: 4 }} />
              ) : (
                <div className="row no-gutters table-responsive mt-3">
                  <Table
                    data={searchResults}
                    columns={sampleColumns}
                    className="insCertTable"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AdminRequest
