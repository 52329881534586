import React from "react";
import Image1 from "../../assets/png/image-6-1.png";
import Image2 from "../../assets/png/image-7-2.png";
import Image3 from "../../assets/png/image-9.png";
import Intersect from "../../assets/png/intersect-2.png";
const ImageOverlapUpper = () => {
  return (
    <div className="group-2">
      <div className="overlap-group-wrapper">
        <div className="overlap-group-2">
          <img className="img" alt="" src={Image1} />
          <img className="image-2" alt="" src={Image2} />
          <img className="intersect" alt="Intersect" src={Intersect} />
        </div>
      </div>
      <img className="image-3" alt="" src={Image3} />
    </div>
  );
};

export default ImageOverlapUpper;
