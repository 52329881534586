import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import { Chip } from '../../components/Chip'

import Header from '../../components/Header/Header'
import './AdminEditRequestFormModule.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { Update_Request, GET_COURSE } from '../../api/graphql'

import Button from '../../components/Button/Button'
import TextField from '../../components/TextField/TextField'

import * as Yup from 'yup'
import { Field, FieldArray, Formik } from 'formik'
import {
  digitRegExp,
  phoneRegExp,
  usStatesList,
  requestStatusList,
  trainingTypeList,
} from '../../utils/common'

import RadioButton from '../../components/RadioButton/RadioButton'
import Spinner from '../../components/Spinner/Spinner'
import { useMutation, useQuery } from '@apollo/client'
import NavBar from '../../components/Navbar/Navbar'
import ReactSelectField from '../../components/ReactSelectField/ReactSelectField'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { GET_REQUEST_BY_ID } from '../../api/graphql'
import { toast } from 'react-toastify'
import { FormControlLabel, FormGroup } from '@mui/material'

const AdminRequestForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [EDIT_REQUEST, { loading }] = useMutation(Update_Request)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const formikRef = useRef()
  const [intrestedTraining, setIntrestedTraining] = useState([])
  const { refetch } = useQuery(GET_COURSE)
  const [LMSCourseList, setLMSCourseList] = useState([])
  const [load, setLoad] = useState(false)
  const userType = localStorage.getItem('userType')
  const { refetch: refetchRequest } = useQuery(GET_REQUEST_BY_ID, {
    variables: {
      requestId: location.state.id,
    },
  })

  const FormSchema = Yup.object().shape({
    // course: Yup.object().required("Course is required"),
    maxTraining: Yup.number()
      .max(99, 'Max 99 allowed')
      .required('Number of trainings requested is required'),
    firstName: Yup.string().trim().required('First Name is required'),
    lastName: Yup.string().trim().required('Last Name is required'),
    email: Yup.string().email().trim().required('Email is required'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .matches(digitRegExp, 'Phone number must be exactly 10 digits'),
    jobTitle: Yup.string().required('Job title is required'),
    organization: Yup.string().required('Organization is required').trim(),
    addressline1: Yup.string().required('Address is required').trim(),
  })

  const setFormFieldValues = (values) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('status', {
        label: values.status,
        value: values.status,
      })
      formikRef.current.setFieldValue('course', {
        label: values.course_name,
        value: values.course_id,
      })
      formikRef.current.setFieldValue('maxTraining', values.max_training)
      formikRef.current.setFieldValue('firstName', values.first_name)
      formikRef.current.setFieldValue('lastName', values.last_name)
      formikRef.current.setFieldValue('email', values.email)
      formikRef.current.setFieldValue('phoneNumber', values.phone_number)
      formikRef.current.setFieldValue('jobTitle', values.job_title)
      formikRef.current.setFieldValue('organization', values.organization)
      formikRef.current.setFieldValue('addressline1', values.addressline1)
      formikRef.current.setFieldValue('state', {
        label: values.state,
        value: values.state,
      })
      formikRef.current.setFieldValue('city', values.city)
      formikRef.current.setFieldValue('zipcode', values.zipcode)
      formikRef.current.setFieldValue(
        'isNationalCouncilMember',
        values.isNationalCouncilMember
      )
      formikRef.current.setFieldValue('haveFunding', values.have_funding)
      formikRef.current.setFieldValue('max_training', values.max_training)

      formikRef.current.setFieldValue(
        'intrestedTraining',
        values.intrested_training
      )

      formikRef.current.setFieldValue(
        'canView',
        values.permissions
          ? values.permissions.length > 0
            ? true
            : false
          : false
      )
    }
  }

  const handleRequestForm = async (values) => {
    try {
      const { data } = await EDIT_REQUEST({
        variables: {
          email: values.email?.trim()?.toLowerCase(),
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          state: values.state.label,
          city: values.city,
          zipcode: values.zipcode.toString(),
          addressline1: values.addressline1,
          organization: values.organization,
          jobTitle: values.jobTitle,
          isNationalCouncilMember: values.isNationalCouncilMember,
          status: values.status.value,
          courseName: values.course.label,
          courseId: parseInt(values.course.value),
          intrestedTraining: values?.intrestedTraining,
          requestId: parseInt(location.state.id),
          maxTraining: values.maxTraining,
          haveFunding: values.haveFunding,
          permissions: values.canView ? ['training.view'] : [],
        },
      })
      if (!data?.updateRequest?.success) {
        toast.error(data?.updateRequest?.error)
        return
      }
      navigate(
        userType === 'admin'
          ? `/admin/request/view-request`
          : '/request/view-request',
        {
          state: { id: location.state.id },
        }
      )
      toast.success('Request updated')
    } catch (error) {
      console.log('catch------', error)
    }
  }

  const getList = async () => {
    if (!location.state || !location.state.id) {
      navigate(
        userType === 'admin' ? '/admin/request' : '/request/request-list'
      )
      return
    }
    try {
      setLoad(true)
      let { data } = await refetchRequest({
        requestId: location.state.id,
      })
      setFormFieldValues(data.GetInstructorTrainingRequestBYId.data)

      const data1 = await refetch()
      let courseArr = []
      for (let i = 0; i < data1.data.GetCourse?.data?.length; i++) {
        courseArr.push({
          label: data1.data.GetCourse[i].name,
          value: data1.data.GetCourse[i].id,
        })
      }
      setLMSCourseList(courseArr)
      setLoad(false)
    } catch (error) {
      setLoad(false)
      console.log('catch----', error)
    }
  }

  useEffect(() => {
    getList()
  }, [])
  return (
    <>
      <div className="row p-0 nav-area no-gutters">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0  no-gutters">
            <div className="col-2">
              <NavBar user={userType} />
            </div>
            <div className="col-10 row admin-request">
              <div className="mb-2 p-0">
                <a
                  href="javascript:void(0)"
                  onClick={(e) =>
                    navigate(
                      userType === 'admin'
                        ? `/admin/request/view-request`
                        : '/request/view-request',
                      {
                        state: { id: location.state.id },
                      }
                    )
                  }
                  className="back-link"
                >
                  <ChevronLeftIcon />
                  Back
                </a>
              </div>
              <div className="col-lg-6 regBox p-3">
                <Formik
                  innerRef={formikRef}
                  enableReinitialize={true}
                  initialValues={{
                    status: requestStatusList[0],
                    trainingType: '',
                    course: '',
                    maxTraining: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    jobTitle: '',
                    organization: '',
                    state: '',
                    city: '',
                    addressline1: '',
                    zipcode: '',
                    isPartOfOrganization: false,
                    isAdult: false,
                    isYouth: false,
                    intrestedTraining: [],
                    isNationalCouncilMember: '',
                    haveFunding: '',
                    canView: false,
                  }}
                  validationSchema={FormSchema}
                  onSubmit={handleRequestForm}
                >
                  {({
                    errors,
                    values,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row p-3">
                          <div className="requestTitle">Contract Details</div>
                          <div className="pt-3 row">
                            <div className="col-md-2">Status</div>
                            <div className="col-md-4">
                              {userType === 'admin' ? (
                                <>
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) =>
                                      setIsDropdownOpen(!isDropdownOpen)
                                    }
                                  >
                                    <Chip
                                      className="chip-instance"
                                      divClassName="chip-2"
                                      status={values.status?.value?.toLowerCase()}
                                      text={values.status.label}
                                    />{' '}
                                    <KeyboardArrowDownIcon />
                                  </span>
                                  {isDropdownOpen && (
                                    <div className="">
                                      <ul
                                        style={{
                                          listStyle: 'none',
                                          padding: '5px',
                                          border: '1px solid #ccc',
                                          backgroundColor: '#fff',
                                          position: 'absolute',
                                          zIndex: 200,
                                        }}
                                      >
                                        {requestStatusList.map(
                                          (item, index) => (
                                            <li
                                              className="status-style"
                                              onClick={(e) => {
                                                setFieldValue('status', item)
                                                setIsDropdownOpen(false)
                                              }}
                                            >
                                              {item.label}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <Chip
                                  className="chip-instance"
                                  divClassName="chip-2"
                                  status={values.status?.value?.toLowerCase()}
                                  text={values.status.label}
                                />
                              )}
                            </div>
                          </div>
                          <div className="">
                            {/* <ReactSelectField
                              label="What MHFA training program(s) are you interested in? *"
                              placeholder="Course"
                              inputId="course"
                              mandatory={1}
                              search={false}
                              error={errors.course}
                              touched={touched.course}
                              value={values.course}
                              options={LMSCourseList}
                              onChange={(text) => {
                                setFieldValue("course", text);
                              }}
                            /> */}
                            <div className="row align-items-center">
                              <div className="col-md-12 d-flex flex-column ">
                                <b className="inputLabel">
                                  What MHFA training program(s) are you
                                  interested in ?
                                </b>

                                <FieldArray name="intrestedTraining">
                                  {({ push, remove }) => (
                                    <Field name="intrestedTraining">
                                      {({ field }) => (
                                        <FormGroup>
                                          {trainingTypeList.map((option) => (
                                            <div key={option.value}>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    {...field}
                                                    name="intrestedTraining"
                                                    value={option.value}
                                                    checked={values.intrestedTraining.includes(
                                                      option.value
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        push(option.value)
                                                      } else {
                                                        remove(
                                                          values.intrestedTraining.indexOf(
                                                            option.value
                                                          )
                                                        )
                                                      }
                                                    }}
                                                  />
                                                }
                                                label={option.label}
                                              />
                                            </div>
                                          ))}
                                        </FormGroup>
                                      )}
                                    </Field>
                                  )}
                                </FieldArray>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-12 mb-3 flex-column d-flex ">
                                <TextField
                                  id="maxTraining"
                                  type="number"
                                  name="maxTraining"
                                  placeholder="How many MHFA Instructor trainings do you plan to host each year? *"
                                  value={values.maxTraining}
                                  label="How many MHFA Instructor trainings do you plan to host each year? *"
                                  error={errors.maxTraining}
                                  touched={touched.maxTraining}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                  maxlength={2}
                                />
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-6 mb-3 flex-column d-flex ">
                                <TextField
                                  id="firstName"
                                  name="firstName"
                                  placeholder="First Name"
                                  value={values.firstName}
                                  label="First Name *"
                                  error={errors.firstName}
                                  touched={touched.firstName}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-6 mb-3 flex-column d-flex">
                                <TextField
                                  id="lastName"
                                  name="lastName"
                                  placeholder="Last Name"
                                  value={values.lastName}
                                  label="Last Name *"
                                  error={errors.lastName}
                                  touched={touched.lastName}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-3 flex-column d-flex ">
                                <TextField
                                  disabled
                                  name="email"
                                  placeholder="Email"
                                  value={values.email?.trim()?.toLowerCase()}
                                  label="Email address *"
                                  error={errors.email}
                                  touched={touched.email}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-3 flex-column d-flex ">
                                <TextField
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  placeholder="Phone Number"
                                  value={values.phoneNumber}
                                  label="Phone Number *"
                                  error={errors.phoneNumber}
                                  touched={touched.phoneNumber}
                                  handleBlur={handleBlur}
                                  maxlength={10}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mb-3 flex-column d-flex ">
                                <TextField
                                  name="jobTitle"
                                  placeholder="Job Title *"
                                  label="Job Title *"
                                  value={values.jobTitle}
                                  error={errors.jobTitle}
                                  touched={touched.jobTitle}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-6 mb-3 flex-column d-flex ">
                                <TextField
                                  name="organization"
                                  placeholder="Organization"
                                  label="Organization *"
                                  value={values.organization}
                                  error={errors.organization}
                                  touched={touched.organization}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="mb-3 flex-column d-flex">
                                <TextField
                                  name="addressline1"
                                  placeholder="Address *"
                                  label="Address *"
                                  value={values.addressline1}
                                  error={errors.addressline1}
                                  touched={touched.addressline1}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mb-3 flex-column d-flex ">
                                <TextField
                                  name="city"
                                  placeholder="City"
                                  label="City"
                                  value={values.city}
                                  error={errors.city}
                                  touched={touched.city}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-6 mb-3 flex-column d-flex ">
                                <ReactSelectField
                                  label="State"
                                  placeholder="State"
                                  inputId="state"
                                  mandatory={1}
                                  search={false}
                                  error={errors.state}
                                  touched={touched.state}
                                  value={values.state}
                                  options={usStatesList}
                                  onChange={(text) => {
                                    setFieldValue('state', text)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-3 flex-column d-flex ">
                                <TextField
                                  name="zipcode"
                                  placeholder="Zip Code"
                                  label="Zip Code"
                                  value={values.zipcode}
                                  error={errors.zipcode}
                                  touched={touched.zipcode}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            {/* <FieldArray name="intrestedTraining">
                                  {({ push, remove }) => (
                                    <Field name="intrestedTraining">
                                      {({ field }) => (
                                        <FormGroup>
                                          {trainingTypeList.map((option) => (
                                            <div key={option.value}>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    {...field}
                                                    name="intrestedTraining"
                                                    value={option.value}
                                                    checked={values.intrestedTraining?.includes(
                                                      option.value
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        push(option.value);
                                                      } else {
                                                        remove(
                                                          values.intrestedTraining?.indexOf(
                                                            option.value
                                                          )
                                                        );
                                                      }
                                                    }}
                                                  />
                                                }
                                                label={option.label}
                                              />
                                            </div>
                                          ))}
                                        </FormGroup>
                                      )}
                                    </Field>
                                  )}
                                </FieldArray> */}
                            <div className="row align-items-center">
                              <div className="col-md-6 mb-3  d-flex flex-column ">
                                <b className="inputLabel">
                                  Are you a National Council member?
                                </b>
                                <div className="d-flex  ">
                                  <RadioButton
                                    id="yes"
                                    name="isNationalCouncilMember"
                                    value={values.isNationalCouncilMember}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="Yes"
                                    val="Yes"
                                  />
                                  <RadioButton
                                    name="isNationalCouncilMember"
                                    id="no"
                                    value={values.isNationalCouncilMember}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="No"
                                    val="No"
                                  />
                                  <RadioButton
                                    name="isNationalCouncilMember"
                                    id="notSure"
                                    value={values.isNationalCouncilMember}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="Not Sure"
                                    val="Not Sure"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3  d-flex flex-column ">
                                <b className="inputLabel">
                                  Do you currently have any training funding
                                  available? *
                                </b>
                                <div className="d-flex  ">
                                  <RadioButton
                                    id="fundingYes"
                                    name="haveFunding"
                                    value={values.haveFunding}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="Yes"
                                    val="Yes"
                                  />
                                  <RadioButton
                                    name="haveFunding"
                                    id="fundingNo"
                                    value={values.haveFunding}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="No"
                                    val="No"
                                  />
                                  <RadioButton
                                    name="haveFunding"
                                    id="fundingNotSure"
                                    value={values.haveFunding}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="Not Sure"
                                    val="Not Sure"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <p>Allow view Trainings?</p>
                              <div className="d-flex">
                                <RadioButton
                                  name="canView"
                                  id="canViewYes"
                                  value={values.canView}
                                  setFieldValue={setFieldValue}
                                  checkBoxLabel={'Yes'}
                                  val={true}
                                />
                                <RadioButton
                                  name="canView"
                                  id="canViewNo"
                                  value={values.canView}
                                  setFieldValue={setFieldValue}
                                  checkBoxLabel={'No'}
                                  val={false}
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6"></div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-6">
                                    <Button
                                      className="button-base-instance-grey"
                                      type="button"
                                      onClick={(e) =>
                                        navigate(
                                          userType === 'admin'
                                            ? `/admin/request/view-request`
                                            : '/request/view-request',
                                          {
                                            state: { id: location.state.id },
                                          }
                                        )
                                      }
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                  <div className="col-md-6">
                                    <Button type="submit">
                                      {loading ? (
                                        <Spinner
                                          size="20px"
                                          sx={{
                                            '.MuiCircularProgress-root': {
                                              color: 'white',
                                            },
                                          }}
                                          thickness={5}
                                        />
                                      ) : (
                                        'Save'
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminRequestForm
