import React, { useEffect, useState } from "react";
import "./InstructorProfileModule.css";

import { Create_Registration, GET_USER_BY_ID } from "../../api/graphql";
import storage from "../../auth/storage";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../auth/AuthState";
import UserRegistrationForm from "../../components/Form/UserRegistrationForm";
import Layout from "../../layouts/Layout";

const InstructorProfile = () => {
  const {
    userState: { user },
  } = useUser();
  const navigate = useNavigate();
  const [userInformation, setUserInformation] = useState();

  const { data: UserDataReftch, refetch: UserRefetch } = useQuery(
    GET_USER_BY_ID,
    {
      variables: {
        userId: user?.id,
      },
    }
  );

  const [ADD_REGISTRATION, { loading }] = useMutation(Create_Registration);

  const handleRegistration = async (values) => {
    console.log("%cInstructorProfile.jsx line:33 ", "color: #007acc;");
    try {
      const { data } = await ADD_REGISTRATION({
        variables: {
          email: values.email?.trim()?.toLowerCase(),
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          groupId: values.groupId,
          mhfaConnect: values.mhfaConnect.value,
          addressline1: values.addressline1,
          addressline2: values.addressline2,
          state: values.state.label,
          city: values.city,
          zipcode: values.zipcode,
          describeDietaryRestrictions: values.describeDietaryRestrictions,
          dietaryRestrictions: values.dietaryRestrictions.value,
          publicProfile: values.publicProfile,
          getNewsletter: values.getNewsletter,
          jobTitle: values.jobTitle,
          organization: values.organization,
          isPartOfOrganization: values.isPartOfOrganization,
          mfaOptions: values.mfaOptions.value,
        },
      });

      if (data?.userRegistration?.status === 200) {
        const userData = JSON.parse(await storage.getField("userData"));
        toast.success("User profile updated successfully");
        await storage.storeUserData([data?.userRegistration?.data]);
        navigate("/instructor-application");
        return;
      } else {
        toast.error("something went wrong! try again");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getInfo = async () => {
    const learnUponUserId = await storage.getField("learnUponUserId");
    if (!learnUponUserId || learnUponUserId === "") {
      navigate("/");
      return;
    }
    const userData = JSON.parse(await storage.getField("userData"));

    if (userData) {
      const GetUserByUserIdRes = await UserRefetch({
        userId: user?.id,
      });
      if (GetUserByUserIdRes?.data?.GetUserDetailByUserId.data) {
        setUserInformation(
          GetUserByUserIdRes?.data?.GetUserDetailByUserId.data
        );
      }
    }
  };

  useEffect(() => {
    console.log("%cInstructorRegistration.jsx line:155 ", "color: #007acc;");

    getInfo();
  }, []);
  return (
    <>
      <Layout title={"Profile"}>
        <div className="row no-gutters ">
          <div className="col-lg-12 regBox p-3">
            <UserRegistrationForm
              email={user?.email}
              handleRegistration={handleRegistration}
              loading={loading}
              userInformation={userInformation}
            />
          </div>
          <div className="col-lg-2"></div>
        </div>
      </Layout>
    </>
  );
};

export default InstructorProfile;
