import React, { useCallback, useMemo, useState } from 'react'
import MessageCard from '../../components/MessageCard/MessageCard'
import Button from '../../components/Button/Button'
import { USER_ACTIVITY_STATUS } from '../../utils/common'
import { useUser } from '../../auth/AuthState'
import { useUpdateUserActivity } from '../../hooks/useUpdateUserActivity'
import { useLocation, useNavigate } from 'react-router-dom'
import line192svg from '../../assets/svg/line-19-2.svg'

import Layout from '../../layouts/Layout'

const ScorePage = () => {
  const {
    userState: { user },
  } = useUser()
  const location = useLocation()
  const navigate = useNavigate()
  const { updateUserActivity } = useUpdateUserActivity()

  const handleNext = useCallback(async () => {
    navigate(`/instructor-registration`, {
      state: {
        courseId: location?.state.course_id,
      },
    })

    await updateUserActivity(
      location?.state.course_id,
      user?.id,
      USER_ACTIVITY_STATUS.SCORE
    )
  }, [navigate])

  return (
    <>
      <Layout title={'INSTRUCTOR APPLICATION'}>
        <p className="ptext mt-2">
          Please take 20-30 minutes to complete the following application. Once
          you complete the application you will receive instructions for moving
          forward with earning your MHFA Instructor certification.
        </p>

        <div>
          <img className="line" alt="Line" src={line192svg} />
        </div>
        <div
          className="row d-flex justify-content-center"
          style={{ marginTop: '20px' }}
        >
          {location?.state?.isFailerPage ? (
            <>
              <MessageCard
                title="Thank You for your submission"
                showLine={true}
                messageData={`Thank you for your interest in becoming a Mental Health First Aid Instructor. Unfortunately, we are unable to advance your application at this time. You may reapply in 90 days. In the meantime, we encourage you to consider taking another First Aider course, attending a Becoming a MHFA Instructor webinar   and reviewing our website to learn more about the <a href="">role of a MHFA Instructor</a>. Try after 90 days. <br />If you have any questions please do not hesitate to reach out to us at <a href="mailto:mhfaapplications@thenationalcouncil.org">mhfaapplications@thenationalcouncil.org</a>.`}
                className=" d-flex flex-column align-items-center justify-content-center cardDiv  p-4"
              >
                <Button
                  text="Back to Home"
                  onClick={() => navigate('/instructor-application')}
                  style={{
                    marginTop: '2rem',
                    marginBottom: '1rem',
                    width: '336px',
                  }}
                />
              </MessageCard>
            </>
          ) : (
            <>
              <MessageCard
                title="Thank You"
                showLine={true}
                messageData={
                  'Thank you for your interest in becoming a MHFA Instructor. Please click next to continue your journey to becoming an MHFA Instructor.'
                }
              >
                <Button
                  text="Next"
                  style={{ marginTop: '2rem', marginBottom: '1rem' }}
                  onClick={handleNext}
                />
              </MessageCard>
            </>
          )}
        </div>
      </Layout>
    </>
  )
}

export default ScorePage
