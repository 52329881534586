import React from "react";
import _ from "lodash";

function TableBody({ data, columns }) {
  const renderCell = (item, column) => {
    if (column.content) return column.content(item);

    return _.get(item, column.path) || "-";
  };

  return (
    <tbody>
        {data?.length > 0 ? (
          data.map((item) => (
            <tr key={`row-${item.id}`}>
              {columns.map((column) => (
                <td
                  key={`col-${column.path}`}
                  width={column.width}
                  className={column.align == 'center' ? 'px-2 text-center' : 'px-2'}
                >
                  {renderCell(item, column)}
                </td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td
              colSpan="10"
              style={{
                textAlign: "center",
                padding: "45px",
                fontSize: "18px",
              }}
            >
              No Records Found
            </td>
          </tr>
        )}
    </tbody>
  );
}

export default TableBody;
