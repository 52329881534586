import React from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Box, Modal } from '@mui/material'
import Button from '../../components/Button/Button'
import Spinner from '../../components/Spinner/Spinner'
const UploadCSV = ({
  handleCloseCSV,
  openUploadCSV,
  handleFileUpload,
  csvUploadLoading,
  handleCSVSubmit,
  csvData,
  canCSVSubmit,
  csvErrors,
}) => {
  return (
    <Modal
      open={openUploadCSV}
      onClose={handleCloseCSV}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableBackdropClick={true}
    >
      <Box
        sx={{
          position: 'absolute',
          maxwidth: 550,
          bgcolor: 'background.paper',
          border: '0.5px solid #000',
          borderRadius: '10px',
          p: 4,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="row d-flex align-items-center">
          <div>
            <input type="file" accept=".csv" onChange={handleFileUpload} />
          </div>
        </div>
        {csvErrors && csvErrors.length > 0 && (
          <ul className="row mt-4">
            {csvErrors.map((error, index) => (
              <li key={index} className="text-danger">
                {error}
              </li>
            ))}
          </ul>
        )}
        <div className="row mt-4">
          <div className="col-md-6 mb-3 flex-column d-flex">
            <Button
              className="button-base-instance-grey"
              variant="contained"
              onClick={handleCloseCSV}
            >
              Cancel
            </Button>
          </div>
          <div className="col-md-6 mb-3 flex-column d-flex">
            <Button
              // className="button-base-instance-grey"
              variant="contained"
              disabled={csvData?.length > 0 ? false : true}
              onClick={handleCSVSubmit}
            >
              {csvUploadLoading ? (
                <Spinner
                  size="20px"
                  sx={{
                    '.MuiCircularProgress-root': {
                      color: 'white',
                    },
                  }}
                  thickness={5}
                />
              ) : (
                'Submit'
              )}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default UploadCSV
