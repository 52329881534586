import React, { memo } from "react";
import ErrorMessage from "../Common/ErrorMessage";
import Button from "../Button/Button";
import Spinner from "../Spinner/Spinner";
import PropTypes from "prop-types";

const EmailVerify = ({
  email,
  emailError,
  emailLoading,
  setEmail,
  setEmailError,
  focusInputError,
  isEmail,
  sendEmail,
}) => {
  return (
    <>
      <div className="mt-5 row no-gutters emailHead">
        Enter email for verification
      </div>
      <div className="col-lg-12 subheadtext">
        Enter your email address. If you already have a MHFA Connect account,
        use the same email address as you do for your account.
      </div>
      <div className="row no-gutters mt-5  formWrapper ">
        <b
          className="inputLabel"
          style={{
            color: "#53605F",
            fontSize: "16px",
            fontFamily: "Open Sans",
            fontWeight: "500",
            opacity: "0.8",
          }}
        >
          Email Address
        </b>
        <input
          id="emailId"
          className={`inputWrapper`}
          placeholder="Email address"
          onChange={(e) => {
            setEmailError(false);
            setEmail(e.target.value?.trim()?.toLowerCase());
            focusInputError("emailId", false);
          }}
          onBlur={() => {
            if (email == "" || !isEmail(email)) {
              focusInputError("emailId", true);
              setEmailError(true);
              return;
            }
          }}
          value={email}
          onKeyUp={(e) => e.keyCode === 13 && sendEmail()}
        />
        <ErrorMessage visible={emailError} error="Please enter valid Email" />
        <Button
          onClick={sendEmail}
          text={
            emailLoading ? (
              <Spinner
                size="20px"
                sx={{
                  ".MuiCircularProgress-root": {
                    color: "white",
                  },
                }}
                thickness={5}
              />
            ) : (
              "Next"
            )
          }
          style={{ marginTop: "50px" }}
        />
      </div>
    </>
  );
};

EmailVerify.propTypes = {
  email: PropTypes.string,
  emailError: PropTypes.bool,
  emailLoading: PropTypes.bool,
  setEmail: PropTypes.func,
  setEmailError: PropTypes.func,
  focusInputError: PropTypes.func,
  isEmail: PropTypes.func,
  sendEmail: PropTypes.func,
};

export default memo(EmailVerify);
