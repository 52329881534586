import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: 
    process.env.REACT_APP_APOLLO_URL, // Replace with your GraphQL API endpoint
    cache: new InMemoryCache(),
});
    // console.log("apolo config REACT_APP_APOLLO_URL", process.env.REACT_APP_APOLLO_URL);
    // console.log("REACT_APP_EMAIL_API_URL", process.env.REACT_APP_EMAIL_API_URL);
    // console.log("REACT_APP_LEARNUPON", process.env.REACT_APP_LEARNUPON);
    // console.log("REACT_APP_SHOPIFY", process.env.REACT_APP_SHOPIFY);

export default client;
