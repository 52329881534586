const focusInputError = (inputField, focus) => {
  //Here if focus is false then remove focus class otherwise add focus class
  if (focus) {
    // document.getElementById(inputField).focus();
    document.getElementById(inputField).classList.add("focusInputError");
  } else {
    document.getElementById(inputField).classList.remove("focusInputError");
  }
};

export { focusInputError };
