import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { Chip } from '../../components/Chip'
import Table from '../../components/Table/Table'

import Header from '../../components/Header/Header'
import './AdminCandidateViewModule.css'

import {
  GET_CANDIDATE_DETAIL,
  GET_TRAINING_BY_REQUEST,
  GET_USER_LOGS,
} from '../../api/graphql'

import Button from '../../components/Button/Button'

import { trainingTypeList } from '../../utils/common'
import { useQuery } from '@apollo/client'
import NavBar from '../../components/Navbar/Navbar'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import moment from 'moment'
import CustomActivityIndicator from '../../components/Common/CustomActivityIndicator'
import Spinner from '../../components/Spinner/Spinner'
import { useUser } from '../../auth/AuthState'

const AdminRequestForm = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [load, setLoad] = useState(false)

  const [data, setData] = useState()
  const [assessmentData, setAssessmentData] = useState([])
  const [userLogs, setUserLogs] = useState([])
  const [trainingData, setTrainingData] = useState()
  const [interestedArr, setInterestedArr] = useState([])
  const { dispatch, userState } = useUser()

  const { loading: requestLoading, refetch } = useQuery(GET_CANDIDATE_DETAIL, {
    variables: {
      getCandidateDetailsId: location?.state?.id,
    },
  })
  const { loading: userLogLoading, refetch: refetchUserLogs } = useQuery(
    GET_USER_LOGS,
    {
      variables: {
        userId: userState?.user?.id,
        startDate: null,
        endDate: null,
        limit: 100,
      },
    }
  )

  const asssessmentColumns = [
    { path: 'id', header: '#', width: '25%' },
    {
      path: 'courseName',
      header: 'Certification Name',
      width: '25%',
      icon: <ImportExportIcon fontSize="12" />,
    },
    {
      path: 'start_time',
      header: 'Start Date',
      width: '25%',
      icon: <ImportExportIcon fontSize="12" />,
    },
    {
      path: 'end_time',
      header: 'End Date',
      width: '25%',
      icon: <ImportExportIcon fontSize="12" />,
    },
    {
      path: 'status',
      header: 'Status',
      width: '10%',
      icon: <ImportExportIcon fontSize="12" />,
      content: (item) => (
        <Chip
          className="chip-instance"
          divClassName="chip-2"
          status={item?.status?.toLowerCase()}
          text={item.status}
        />
      ),
    },
  ]
  const trianingColumns = [
    { path: 'id', header: '#', width: '25%' },
    {
      path: 'certificationName',
      header: 'Certification Name',
      width: '25%',
      icon: <ImportExportIcon fontSize="12" />,
    },
    {
      path: 'certificationDate',
      header: 'Certification Date',
      width: '25%',
      icon: <ImportExportIcon fontSize="12" />,
    },
    {
      path: 'expiryDate',
      header: 'Expiry Date',
      width: '25%',
      icon: <ImportExportIcon fontSize="12" />,
    },
    {
      path: 'status',
      header: 'Status',
      width: '10%',
      icon: <ImportExportIcon fontSize="12" />,
      content: (item) => (
        <Chip
          className="chip-instance"
          divClassName="chip-2"
          status={item?.status?.toLowerCase()}
          text={item.status}
        />
      ),
    },
  ]

  const userLogsColumns = [
    { path: 'id', header: '#', width: '5%' },
    {
      path: 'log_module',
      header: 'Log Module',
      width: '25%',
      icon: <ImportExportIcon fontSize="12" />,
    },
    {
      path: 'log_description',
      header: 'Log Description',
      width: '45%',
      icon: <ImportExportIcon fontSize="12" />,
      // content: (item) => (
      //   <>
      //     {item?.log_description === 'Prerequisite Failed'
      //       ? 'No Certifications Found'
      //       : item?.log_description}
      //   </>
      // ),
    },
    {
      path: 'log_date',
      header: 'Log Date',
      width: '25%',
      icon: <ImportExportIcon fontSize="12" />,
    },
  ]

  const getList = async () => {
    if (!location.state || !location.state.id) {
      navigate('/admin/candidate-list')
      return
    }

    console.log('location.state.id---', location.state.id)
    let { data } = await refetch({
      getCandidateDetailsId: location.state.id,
    })

    const { data: userLogData } = await refetchUserLogs({
      userId: data.GetCandidateDetails?.candidateData?.id,
      startDate: null,
      endDate: null,
      limit: 100,
    })

    const updatedUserLog = userLogData?.GetUserLogs?.data?.map(
      (_log, index) => {
        const date = new Date(parseInt(_log?.timestamp))
        return {
          id: index + 1,
          user_id: _log?.user_id,
          log_type: _log?.log_type,
          log_module: _log?.log_module,
          log_description: _log?.log_description,
          log_date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
        }
      }
    )

    setUserLogs(updatedUserLog)

    setData(data.GetCandidateDetails?.candidateData || [])
    let assessmentArr = []
    for (let i = 0; i < data.GetCandidateDetails?.AssessmentData?.length; i++) {
      assessmentArr.push({
        id: data.GetCandidateDetails?.AssessmentData[i].id,
        courseName: data.GetCandidateDetails?.AssessmentData[i]?.course?.name,
        start_time: moment(
          data.GetCandidateDetails?.AssessmentData[i]?.start_time
        ).format('yyyy-MM-DD'),
        end_time: moment(
          data.GetCandidateDetails?.AssessmentData[i]?.end_time
        ).format('yyyy-MM-DD'),
        status: data.GetCandidateDetails?.AssessmentData[i].status,
      })
    }
    console.log('assessmentArr------', assessmentArr)
    setAssessmentData(assessmentArr)
    // let interestedArr = [];
    // if (
    //   data.GetInstructorTrainingRequestBYId.data.intrested_training &&
    //   data.GetInstructorTrainingRequestBYId.data.intrested_training.length > 0
    // ) {
    //   data.GetInstructorTrainingRequestBYId.data.intrested_training.map(
    //     (item) => {
    //       let arr = trainingTypeList.filter((m) => m.value !== item);
    //       interestedArr.push(arr[0].label);
    //     }
    //   );
    // }
    // setInterestedArr(interestedArr);
  }

  useEffect(() => {
    getList()
  }, [])
  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: 'center' }}
        visible={load}
      />
      <div className="row p-0  no-gutters nav-area">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0  no-gutters">
            <div className="col-2">
              <NavBar user={'admin'} />
            </div>
            <div className="col-10 row admin-request">
              <div className="mb-2 p-0">
                <a
                  href="javascript:void(0)"
                  onClick={(e) => window.history.back()}
                  className="back-link"
                >
                  <ChevronLeftIcon />
                  Back
                </a>
              </div>
              {requestLoading || !data ? (
                <Spinner sx={{ marginTop: 4 }} />
              ) : (
                <>
                  <div className="col-lg-9 regBox p-5">
                    <div className="row">
                      <div className="col-lg-9 requestTitle mt-2">
                        User Detail
                      </div>
                    </div>
                    <div className="row mt-2 p-2">
                      <hr />
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label className="view-label">User Name</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">
                        {(data?.first_name ?? '-') +
                          ' ' +
                          (data?.last_name ?? '-')}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">Email</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">{data?.email}</div>
                    </div>
                    <div className="row mt-2 p-2">
                      <hr />
                    </div>
                    <div className="text-wrapper-3 mt-4 mb-3">
                      List of Training Types
                    </div>
                    <div className="row no-gutters table-responsive">
                      <Table
                        data={[]}
                        columns={trianingColumns}
                        className="insCertTable"
                      />
                    </div>
                    <div className="text-wrapper-3 mt-4 mb-3">
                      List of Assessment History
                    </div>
                    <div
                      className="row no-gutters table-responsive"
                      style={{ maxHeight: '350px' }}
                    >
                      <Table
                        data={assessmentData}
                        columns={asssessmentColumns}
                        className="insCertTable"
                      />
                    </div>
                    <div className="text-wrapper-3 mt-4 mb-3">User Logs</div>
                    <div
                      className="row no-gutters table-responsive"
                      style={{ maxHeight: '30rem' }}
                    >
                      <Table
                        data={userLogs}
                        columns={userLogsColumns}
                        className="insCertTable"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminRequestForm
