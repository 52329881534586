import React, { memo, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Header.css'

import PersonSVG from '../../assets/svg/Person.svg'
import LogoutSVG from '../../assets/svg/Logout.svg'
import UserBadge from '../UserBadge/UserBadge'
import DropDownMenu from '../DropDownMenu/DropDownMenu'
import useAuth from '../../auth/useAuth'
import { useUser } from '../../auth/AuthState'
import { CHECK_LMS_USER } from '../../api/graphql'
import { useQuery } from '@apollo/client'

const Header = ({ className, disableLogoClick, shouldReload }) => {
  const { logOut } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const [item, setItem] = useState([])
  const open = Boolean(anchorEl)
  const {
    userState: { user, isMHA, userType, email },
    dispatch,
  } = useUser()

  const { refetch } = useQuery(CHECK_LMS_USER, {
    variables: {
      email: email,
    },
  })
  const isStarted = useMemo(
    () => localStorage.getItem('isExamStarted'),
    [localStorage.getItem('isExamStarted'), window.location.href]
  )
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const navigate = useNavigate()

  const checkUser = async () => {
    if (userType !== 'admin') {
      const userLMS = await refetch({
        email: user?.email,
      })
      dispatch({
        type: 'CHECK_LMS',
        payload: userLMS?.data?.CheckLMSUser?.success,
      })
    }
    let item = []
    if (isMHA && !isStarted) {
      item = [
        {
          icon: PersonSVG,
          text: 'My Profile',
          onClick: function () {
            navigate('/instructor-profile')
          },
        },

        {
          icon: LogoutSVG,
          text: 'Logout',
          onClick: function () {
            logOut()
          },
        },
      ]
    } else {
      item = [
        {
          icon: LogoutSVG,
          text: 'Logout',
          onClick: function () {
            logOut()
          },
        },
      ]
    }
    setItem(item)
  }

  const onLogoClick = () => {
    if (shouldReload) {
      window.location.reload()
    } else if (userType === 'admin') {
      navigate('/admin/request')
    } else if (userType === 'request') {
      navigate('/request/request-list')
    } else {
      localStorage.removeItem('isExamStarted')
      // navigate(-1);
      navigate('/instructor-application')
    }
  }
  useEffect(() => {
    checkUser()
  }, [isStarted, isMHA, user?.email, userType])
  return (
    <div className="row no-gutters pt-3 mb-4">
      <div className="col-8">
        <div className="row no-gutters">
          <div
            className="text-3"
            style={{ cursor: 'pointer' }}
            onClick={disableLogoClick ? () => {} : onLogoClick}
          >
            <img
              // className="compImage"
              alt="main-side"
              src={require('../../assets/png/header-logo.png')}
              height={'50px'}
            />
            &nbsp; MHFA
          </div>
          {/* <a
            href="https://www.mentalhealthfirstaid.org/become-an-instructor/"
            target="_blank"
          >
            <img
              alt="main-side"
              src={require("../../assets/png/header-logo.png")}
              height={"50px"}
            />
          </a> */}
        </div>
      </div>

      <div className="col-4">
        <div className="d-flex relative justify-content-end ">
          <div className="d-flex flex-column ">
            <UserBadge handleOpen={handleClick} />
            <DropDownMenu
              open={open}
              handleClose={handleClose}
              anchorEl={anchorEl}
              items={item}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Header)
