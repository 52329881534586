import React, { useEffect, useState } from "react";
import "./InstructorRegistrationModule.css";

import storage from "../../auth/storage";

import MessageCard from "../../components/MessageCard/MessageCard";
import Spinner from "../../components/Spinner/Spinner";
import Layout from "../../layouts/Layout";

const InstructorRegistration = () => {
  const [email, setEmail] = useState("");
  const [shopifyLoading, setShopifyLoading] = useState(false);

  const getInfo = async () => {
    const email = await storage.getField("emailKey");
    setEmail(email);
  };

  useEffect(() => {
    getInfo();
  }, []);
  return (
    <>
      <Layout title={""}>
        <div
          className="d-flex flex-column align-items-center justify-content-center "
          style={{ minHeight: "700px" }}
        >
          <MessageCard
            title="Enrolled in Private Training"
            messageCardStyle={{
              cursor: "pointer",
            }}
            titleStyle={{
              fontSize: "30px",
            }}
          ></MessageCard>
          &nbsp;
          <p style={{ color: "#53605F", fontWeight: "500" }}>or</p>
          <MessageCard
            title={shopifyLoading ? <Spinner /> : "Shopify Page"}
            messageCardStyle={{
              cursor: "pointer",
            }}
            titleStyle={{
              fontSize: "30px",
            }}
            // onClick={() => integrateShopify()}
          ></MessageCard>
        </div>
      </Layout>
    </>
  );
};

export default InstructorRegistration;
