import React, { forwardRef } from 'react'
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const digitRegExp = /^\d{10}$/

export const passWordRegExp =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
export const mfaConnect = [
  {
    value: 'I am an MHFA Coordinator',
    label: 'I am an MHFA Coordinator',
  },
  {
    value: 'I am a certified MHFA Instructor',
    label: 'I am a certified MHFA Instructor',
  },
  {
    value: 'I am applying to become an Adult MHFA Instructor',
    label: 'I am applying to become an Adult MHFA Instructor',
  },
  {
    value: 'I am applying to become a Youth MHFA Instructor',
    label: 'I am applying to become a Youth MHFA Instructor',
  },
  {
    value: 'I am applying to become a teen MHFA Instructor',
    label: 'I am applying to become a teen MHFA Instructor',
  },
  {
    value: 'I am certified or earning my certification in teen MHFA',
    label: 'I am certified or earning my certification in teen MHFA',
  },
  {
    value: 'I am certified or earning my certification in Adult or Youth MHFA',
    label: 'I am certified or earning my certification in Adult or Youth MHFA',
  },
]

export const trainingTypeList = [
  {
    label: 'Mental Health First Aid for Adults ( MHFA for Adult )',
    value: 'adult',
    adult: 'Mental Health First Aid for Adults ( MHFA for Adult )',
  },
  {
    label: 'Mental Health First Aid for Youth ( MHFA for Youth )',
    value: 'youth',
    youth: 'Mental Health First Aid for Youth ( MHFA for Youth )',
  },
]

export const courseList = [
  {
    value: 1,
    label: 'Adult Instructor Training',
  },
  {
    value: 2,
    label: 'Youth Instructor Training',
  },
  {
    value: 3,
    label: 'Teen Instructor Training',
  },
  {
    value: 4,
    label: 'NT Training',
  },
]
export const dietaryRestriction = [
  {
    value: 'Vegan',
    label: 'Vegan',
  },
  {
    value: 'Vegetarian',
    label: 'Vegetarian',
  },
  {
    value: 'Pescatarian',
    label: 'Pescatarian',
  },
  {
    value: 'Gluten-free',
    label: 'Gluten-free',
  },
  {
    value: 'Allergies or Other Preferences',
    label: 'Allergies or Other Preferences',
  },
]
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '4px',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    border:
      state.isFocused || state.isSelected
        ? '1px solid  black !important'
        : '1px solid  #ebebe !important',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ea4b22' : '#ffffff',
  }),
}

export const trainingStatusList = [
  { label: 'Scheduling', value: 'scheduling' },
  { label: 'Enrolling', value: 'enrolling' },
  { label: 'Completed', value: 'completed' },
  { label: 'Cancelled', value: 'cancelled' },
]
export const requestStatusList = [
  { label: 'Pending', value: 'pending' },
  { label: 'Contracted', value: 'contracted' },
  { label: 'Not Contracted', value: 'notContracted' },
  { label: 'Rejected', value: 'rejected' },
]

export const MHFAGroupsList = [
  {
    value: 'N/A',
    label: 'N/A',
  },
  {
    value: 'Florida Department of Education (FL DOE)',
    label: 'Florida Department of Education (FL DOE)',
  },
]

export const usStatesList = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Federated States Micronesia', value: 'FSM' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Guam', value: 'GU' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Marshall Islands', value: 'MI' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Norther Mariana Islands', value: 'NMI' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'US Virgin Islands', value: 'UVI' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
  { label: 'District of Columbia', value: 'DC' },
]

const adultEnrollmentCheck = [
  'Blended Adult MHFA',
  'In-Person Adult MHFA',
  'Blended Adult Mental Health First Aid',
  'In-Person Adult Mental Health First Aid',
  'Higher Ed - In-Person Mental Health First Aid',
  'Fire/EMS - In-Person Mental Health First Aid',
  'Military, Veterans, and Their Families - In-Person Mental Health First Aid',
  'PASM para Comunidades de Habla-Hispana Presencial',
  'PASM para Comunidades de Habla-Hispana Semipresencial',
]

const youthEnrollmentCheck = [
  'Blended Youth MHFA Version',
  'In-Person Youth MHFA Version',
  'Blended Youth Mental Health First Aid',
  'In-Person Youth Mental Health First Aid',
  'PASMJ para Comunidades de Habla-Hispana Presencial',
  'PASMJ para Comunidades de Habla-Hispana Semipresencial',
  'In-Person Youth MHFA for Tribal Communities and Indigenous Peoples',
  'In-Person Youth MHFA for Korean-speaking Communities',
  'In-Person Youth MHFA for Chinese-speaking Communities',
  'Blended Youth MHFA for Korean-speaking Communities',
  'Blended Youth MHFA for Chinese-speaking Communities',
]

export const checkEnrollment = (courseName, type, status) => {
  if (type?.includes('Adult')) {
    // mapping or checking with LMS adult enrollment
    return adultEnrollmentCheck.some(
      (course) =>
        courseName?.toLowerCase()?.includes(course.toLowerCase()) &&
        (status === 'passed' || status === 'completed')
    )
  }

  // mapping or checking with LMS youth enrollment
  return youthEnrollmentCheck.some(
    (course) =>
      courseName?.toLowerCase()?.includes(course.toLowerCase()) &&
      (status === 'passed' || status === 'completed')
  )
}

export const DateCustomInput = forwardRef(
  (
    {
      id = '',
      value,
      onClick,
      mandatory = 0,
      setFieldTouched = '',
      touchId = '',
    },
    ref
  ) => (
    <button
      type="button"
      id={id}
      className={`datepicker date-picker`}
      onClick={onClick}
      onBlur={() => (setFieldTouched ? setFieldTouched(touchId) : null)}
      ref={ref}
    >
      {value ? value : 'MM-DD-YYYY'}
    </button>
  )
)

export const trainingCourse = [
  {
    label: 'Adult',
    value: 1,
  },
  {
    label: 'Youth',
    value: 2,
  },
]

// check if the user have crm course or not

export const checkCRMCourse = () => {}

export const extractShopifyErrorResponse = (error) => {
  const key = Object.keys(error)[0]
  let value = 'something went wrong'
  let resvalue = Object.values(error)[0]
  if (Array.isArray(resvalue)) {
    value = resvalue[0]
  } else {
    value = resvalue
  }
  return value
}

export const USER_ACTIVITY_STATUS = {
  STARTED: "Started",
  RESUMED: "Resumed",
  SCORE: "Score",
  PROFILE: "Profile",
  ENROLLED: "Enrolled",
  STORE: "Store",
  ERROR: "Error",
};

export const USER_ROLE = {
  ADMIN: 3,
  CANDIDATE: 1,
  REQUEST: 2,
}
export const TIME_ZONE = [
  { value: 'AST - US/Puerto_Rico', label: 'AST - US/Puerto_Rico' },
  { value: 'EST - US/Eastern', label: 'EST - US/Eastern' },
  { value: 'CST - US/Central', label: 'CST - US/Central' },
  { value: 'MST - US/Mountain', label: 'MST - US/Mountain' },
  { value: 'PST - US/Pacific', label: 'PST - US/Pacific' },
  { value: 'AKST - US/Alaska', label: 'AKST - US/Alaska' },
  { value: 'HST - US/Aleutian', label: 'HST - US/Aleutian' },
  { value: 'HST - US/Hawaii', label: 'HST - US/Hawaii' },
  { value: 'SST - US/Samoa', label: 'SST - US/Samoa' },
]
