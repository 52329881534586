import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../Button/Button'
import { Create_Registration, INTEGTRATE_SHOPIFY } from '../../api/graphql'
import AuthContext from '../../auth/context'
import { useMutation } from '@apollo/client'
import { useUser } from '../../auth/AuthState'
import TextField from '../TextField/TextField'
import { Formik } from 'formik'
import {
  USER_ACTIVITY_STATUS,
  extractShopifyErrorResponse,
} from '../../utils/common'
import storage, { storeToken } from '../../auth/storage'
import CheckSVG from '../../assets/svg/check.svg'
import Cross from '../../assets/png/cross.png'
import ProgressIcon from '../../assets/png/progress-icon.jpg'
import RightMarkIcon from '../../assets/png/right-mark.png'
import CrossMarkIcon from '../../assets/png/cross-icon.png'
import { passwordFormSchema } from '../../utils/ValidationUtil'
import Layout from '../../layouts/Layout'
import { useUpdateUserActivity } from '../../hooks/useUpdateUserActivity'

const ShopiftyIntegration = () => {
  const location = useLocation()
  const [messages, setMessages] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [shopifyLoading, setShopifyLoading] = useState(false)
  const [ADD_REGISTRATION, { loading }] = useMutation(Create_Registration)
  const [INT_SHOPIFY] = useMutation(INTEGTRATE_SHOPIFY)
  const [startProcess, setStartProcess] = useState(false)
  const [visible, setVisible] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLmsError, setIsLmsError] = useState(false)
  const { updateUserActivity } = useUpdateUserActivity()

  const isInvited = useMemo(
    () => localStorage.getItem('isInvited'),
    [localStorage.getItem('isInvited')]
  )

  const {
    userState: { user, message, email },
  } = useUser()

  const { tags } = useContext(AuthContext)

  const navigate = useNavigate()

  useEffect(() => {
    let timer
    if (currentStep == messages?.length - 1) {
      clearInterval(timer)
      if (currentStep !== 0) setVisible(true)
    } else {
      timer = setInterval(() => {
        setCurrentStep((prevStep) => prevStep + 1)
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [currentStep, messages])

  const shopifyUrl = useMemo(
    () =>
      process.env.REACT_APP_SHOPIFY ||
      'https://mhfadevelopment.myshopify.com/account/login',
    [process.env.REACT_APP_SHOPIFY]
  )
  const lmsUrl = useMemo(
    () => process.env.REACT_APP_LEARNUPON || 'https://devmhfa.learnupon.com',
    [process.env.REACT_APP_LEARNUPON]
  )

  const headerText = useMemo(
    () =>
      isInvited === 'true'
        ? 'Congratulations! You’re one step closer to becoming a MHFA Instructor and bringing MHFA to your community! You’ve been enrolled in an upcoming training. Visit MHFA Connect to access your course and begin the pre-work.'
        : 'Congratulations! You’re one step closer to becoming a MHFA Instructor and bringing MHFA to your community! Visit the MHFA Storefront to purchase your training and schedule the course.',
    [isInvited]
  )

  const footerText = useMemo(
    () =>
      isInvited === 'true'
        ? 'Getting started on your MHFA Instructor training course is easy. Log in to MHFA Connect and access your course through your Enrolled Courses tab. Then complete your pre-work.'
        : 'Click the link below to visit our MHFA Storefront. Once you’ve logged in, select “Adult MHFA Instructor Trainings & Materials” or “Youth MHFA Instructor Trainings & Materials” from the “Instructor Candidate Trainings & Materials” dropdown menu. You can then select the course date you wish to attend and complete your purchase.',

    [isInvited]
  )

  useEffect(() => {
    setCurrentStep(0)
    console.log('MESSAGES UPDATED', messages)
  }, [messages])

  const handleShopifyIntegration = async (user, tags, userData) => {
    const shopData = await INT_SHOPIFY({
      variables: {
        email: user?.email,
      },
    })
    const shopifyResponse = shopData?.data.IntegrateShopify || {}
    console.log('SHOPIFY', shopifyResponse)
    const status =
      shopifyResponse?.status !== 200
        ? USER_ACTIVITY_STATUS.ERROR
        : USER_ACTIVITY_STATUS.STORE

    if (shopifyResponse.status !== 200) {
      const error = JSON.parse(shopifyResponse?.error)
      const shopifyError =
        typeof error === 'string' ? error : extractShopifyErrorResponse(error)
      setMessages([
        ...userData?.userRegistration.message,
        ...shopifyResponse?.message,
        {
          message: shopifyError,
          isError: true,
        },
      ])
      setIsError(true)
    } else {
      const shopifyMessages = shopifyResponse?.message || []
      // console.log('SETTING SHOPIFY MESSAGES', shopifyMessages)
      setMessages([...userData?.userRegistration.message, ...shopifyMessages])
    }
    await updateUserActivity(
      location?.state?.courseId,
      user?.id,
      status // update user activity status
    )
    setShopifyLoading(false)
    setStartProcess(true)
  }

  const handleRegistration = async (values) => {
    setShopifyLoading(true)
    try {
      const { data } = await ADD_REGISTRATION({
        variables: {
          ...location?.state?.values,
          password: values.password,
          confirmPassword: values.confirmPassword,
        },
      })
      if (data?.userRegistration?.status === 200) {
        await updateUserActivity(
          location?.state?.courseId,
          user?.id,
          USER_ACTIVITY_STATUS.PROFILE // update user activity status
        )
        await storage.storeUserData([data?.userRegistration?.data])
        await storeToken(data?.userRegistration?.token)

        await handleShopifyIntegration(user, tags, data)

        await updateUserActivity(
          location?.state?.courseId,
          user?.id,
          USER_ACTIVITY_STATUS.ENROLLED // update user activity status
        )
      } else {
        setIsError(true)
        setIsLmsError(true)
        await updateUserActivity(
          location?.state?.courseId,
          user?.id,
          USER_ACTIVITY_STATUS.ERROR // update user activity status
        )
      }
      // setMessages([...data?.userRegistration.message])
      setShopifyLoading(false)
      setStartProcess(true)
    } catch (error) {
      console.log(
        '%cShopiftyIntegration.js line:118 error',
        'color: #007acc;',
        error
      )
    }
  }

  useEffect(() => {
    if (
      location?.state?.isShowPassword === false &&
      location?.state?.isError === true
    ) {
      setStartProcess(true)
      setMessages(location?.state?.messages)
      setIsError(true)
    }
    if (location?.state?.isShowPassword === false) {
      setStartProcess(true)
      setMessages(location?.state?.messages)
    }
  }, [location?.state?.isShowPassword])

  return (
    <>
      <Layout title={'INSTRUCTOR APPLICATION'}>
        {startProcess && <p className="text-wrapper-3">{headerText}</p>}

        <div className="row no-gutters    p-3  ">
          <div className="container d-flex justify-content-center">
            {location?.state?.isShowPassword && !startProcess ? (
              <>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                  }}
                  validationSchema={passwordFormSchema}
                  onSubmit={(values) => {
                    handleRegistration(values)
                  }}
                >
                  {({
                    errors,
                    values,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }) => {
                    return (
                      <div
                        className="cardDiv p-4 mt-5"
                        style={{ width: '50%' }}
                      >
                        <form onSubmit={handleSubmit} autoComplete="off">
                          <div className="container p-3 mt-5">
                            <div className="row">
                              <div className=" mb-3 flex-column d-flex">
                                <TextField
                                  type="password"
                                  name="password"
                                  placeholder="Password"
                                  label="Password *"
                                  value={values.password}
                                  error={errors.password}
                                  touched={touched.password}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className=" mb-3 flex-column d-flex">
                                <TextField
                                  type="password"
                                  name="confirmPassword"
                                  placeholder="Confirm Password"
                                  label="Confirm Password *"
                                  value={values.confirmPassword}
                                  error={errors.confirmPassword}
                                  touched={touched.confirmPassword}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <Button type="submit">
                            {shopifyLoading
                              ? 'Please wait while we are Processing...'
                              : 'Submit'}
                          </Button>
                        </form>
                      </div>
                    )
                  }}
                </Formik>
              </>
            ) : (
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <div className="shopify-process-card cardDiv">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {visible ? (
                        <div className="d-flex justify-content-center mt-3">
                          {isError ? (
                            <>
                              <div className="d-flex justify-content-center flex-column align-items-center">
                                {isLmsError ? (
                                  <>
                                    <img
                                      src={CrossMarkIcon}
                                      alt="CrossMarkIcon"
                                      style={{
                                        width: '150px',
                                        height: '150px',
                                      }}
                                      className="mt-2"
                                    />
                                    <p className="text-wrapper-3 mt-3">
                                      Click on below button to contact the admin
                                      Support
                                    </p>
                                    <div className="mt-3">
                                      <Button width={50}>
                                        <a
                                          style={{
                                            textDecoration: 'none',
                                            color: 'white',
                                          }}
                                          href={`mailto:${
                                            process.env
                                              .REACT_APP_LMS_SUPPORT_MAIL ||
                                            'mhfaapplications@thenationalcouncil.org'
                                          }`}
                                          target="_blank"
                                        >
                                          Contact Administration for Support
                                        </a>
                                      </Button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={CrossMarkIcon}
                                      alt="CrossMarkIcon"
                                      style={{
                                        width: '150px',
                                        height: '150px',
                                      }}
                                      className="mt-2"
                                    />
                                    <p className="text-wrapper-3 mt-3">
                                      Click on below button to resolve the Go to
                                      User Registration
                                    </p>
                                    <div className="mt-3">
                                      <Button
                                        width={50}
                                        onClick={() => {
                                          navigate('/instructor-registration')
                                        }}
                                      >
                                        User Registration
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center flex-column align-items-center">
                                <img
                                  src={RightMarkIcon}
                                  alt="RIghtcheckMark"
                                  style={{
                                    width: '150px',
                                    height: '150px',
                                  }}
                                  className="mt-2"
                                />
                                <p className="text-wrapper-3 mt-3">
                                  {footerText}
                                </p>
                                <div className="mt-3">
                                  <Button width={50}>
                                    <a
                                      style={{
                                        textDecoration: 'none',
                                        color: 'white',
                                      }}
                                      href={
                                        isInvited !== 'true'
                                          ? shopifyUrl
                                          : lmsUrl
                                      }
                                      target="_blank"
                                    >
                                      {isInvited !== 'true'
                                        ? 'Purchase the Course'
                                        : 'Access the Course'}
                                    </a>
                                  </Button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <>
                          <h4 style={{ fontWeight: '600' }} className=" mt-3">
                            Processing Please wait...
                          </h4>
                          <img
                            src={ProgressIcon}
                            alt="progress"
                            style={{ width: '150px', height: '150px' }}
                            className=" mt-3"
                          />
                          <ul className="mt-3">
                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  messages[currentStep]?.isError
                                    ? Cross
                                    : CheckSVG
                                }
                                alt="check"
                                style={{ width: '20px', height: '20px' }}
                                className="me-2"
                              />
                              <li
                                style={{
                                  color: `${
                                    messages[currentStep]?.isError ? 'red' : ''
                                  }`,
                                  textDecoration: `${
                                    messages[currentStep]?.isError
                                      ? 'underline'
                                      : ''
                                  }`,
                                }}
                              >
                                <p className="m-0 h5">
                                  {messages[currentStep]?.text}
                                </p>
                              </li>
                            </div>
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ShopiftyIntegration
