import React from "react";

import ImageOverlapUpper from "./ImageOverlapUpper";
import ImageOverlapLower from "./ImageOverlapLower";
const ImageOverlapSection = () => {
  return (
    <>
      <ImageOverlapUpper />
      <ImageOverlapLower />
    </>
  );
};

export default ImageOverlapSection;
