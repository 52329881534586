import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import storage, { getToken } from "../auth/storage";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = async () => {
    try {
      const userToken = await getToken();
      const isOtpVerified = await storage.getField("otpVerified");
      const isPasswordVerify = await storage.getField("isPasswordVerify");
      if (userToken || isOtpVerified || isPasswordVerify) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        return navigate("/");
      }
    } catch (error) {
      console.error("Error while checking user token:", error);
      setIsLoggedIn(false);
      navigate("/");
    }
  };

  useEffect(() => {
    checkUserToken();
  }, []);

  return isLoggedIn ? <>{children}</> : null;
};

export default ProtectedRoute;
