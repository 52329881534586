import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import style from './Assessment.module.css'
import { Stepper, Step } from 'react-form-stepper'
import './stepper.css'
import Button from '../Button/Button'
import RadioButton from '../RadioButton/RadioButton'
import { Formik, Field } from 'formik'
import {
  CREATE_ASSESSMENT_PROGRESS,
  UPDATE_SELF_WAIVE_ASSESSMENT,
} from '../../api/graphql'
import moment from 'moment'
import { toast } from 'react-toastify'
import Spinner from '../Spinner/Spinner'
import { useMutation } from '@apollo/client'
import { CREATE_ASSESSMENT_LOG } from '../../api/graphql'
import { useUser } from '../../auth/AuthState'
import { capitalizeFirst } from '../../utils/StringUtils'
import { useNavigate } from 'react-router-dom'
import { checkEmptyArray } from '../../utils/ArrayUtils'

const Assessment = ({
  title,
  questions,
  setIsAssessmentComplete,
  setIsAssessmentSuccess,
  course_id,
  questionLoading,
  progressData,
}) => {
  const [questionNumber, setQuestionNumber] = useState(0)
  const [questionToShow, setQuestionToShow] = useState(0)
  const [shouldResume, setShouldResume] = useState(false)
  const [hasSetResume, setHasSetResume] = useState(false)
  const [hasUpdatedWaiveStatus, setHasUpdatedWaiveStatus] = useState(false)
  const totalQuestions = questions?.length
  const [currentQuestion, setCurrentQuestion] = useState({})
  const [formValues, setFormValues] = useState(null)
  const navigate = useNavigate()
  const startTime = localStorage.getItem('startTime') || null
  const {
    userState: { user },
  } = useUser()
  const [COMPLETE_ASSESSMENT, { loading: assessmentLogLoading }] = useMutation(
    CREATE_ASSESSMENT_LOG
  )

  const [PROGRESS_ASSESSMENT, { loading }] = useMutation(
    CREATE_ASSESSMENT_PROGRESS
  )

  const [UPDATE_SELF_WAIVE, { loading: self_waive_loading }] = useMutation(
    UPDATE_SELF_WAIVE_ASSESSMENT
  )

  const initialValues = useMemo(() => {
    return formValues
      ? formValues?.answers?.map((_q, index) => {
          const answer = progressData?.answers?.find(
            (_item) => _item.question_id === _q.question_id
          )
          return {
            questionNumber: _q.questionNumber,
            question_id: _q.question_id,
            answer: answer?.answer[0] || _q.answer,
            start_time: startTime,
            end_time: _q.end_time,
            question_points: _q.question_points,
          }
        })
      : questions?.map((_q, index) => {
          // console.log('QUESTION', _q, 'Progress', progressData)
          const answer = progressData?.answers?.find(
            (_item) => _item.question_id === _q.question.id
          )
          // console.log(`ANSWER FOUND FOR Q${_q.question.id}`, answer)
          return {
            questionNumber: index + 1,
            question_id: _q.question.id,
            answer: answer?.answer, // || (_q.type === 'single' ? '' : []),
            start_time: startTime,
            end_time: new Date(),
            question_points: _q.question_points,
          }
        })
  }, [formValues, questions, startTime])

  // a function that takes an array and randomizes it based on another array that looks like [0, 1, 2, 4, 3]
  function randomizeArray(array, randomOrder) {
    return randomOrder.map((index) => array[index])
  }

  // a function that reverses the randomizeArray function
  // randomOrder is the same array that was used to randomize the array
  function unRandomizeArray(randomizedArray, randomOrder) {
    const unrandomizedArray = []
    for (let i = 0; i < randomOrder.length; i++) {
      const index = randomOrder.indexOf(i)
      unrandomizedArray.push(randomizedArray[index])
    }
    return unrandomizedArray
  }

  // randomOrder inludes index in a random order for questions
  // so if there are 3 questions, the randomOrder could be [2, 0, 1]
  const randomOrder = useMemo(() => {
    // console.log('Progress Data', progressData?.question_order)
    if (progressData?.question_order) {
      return progressData?.question_order
    }
    // if (questions && questions.length > 0 && progressData.) {

    // }
    return questions &&
      questions.length >= 0 &&
      questions[0]?.course?.question_randomize
      ? questions.map((_, index) => index).sort(() => Math.random() - 0.5)
      : questions.map((_, index) => index)
  }, [questions])

  const questionNumberFromProgress = useMemo(() => {
    return progressData?.answers?.findIndex((_item) => {
      if (_item?.answer?.length > 0 && _item?.answer[0] !== null) {
        return false
      } else {
        return true
      }
    })
  }, [progressData?.answers])

  useEffect(() => {
    // THIS NEEDS WORK
    console.log('RANDOM ORDER', randomOrder)
    setCurrentQuestion(questions[randomOrder[questionNumberFromProgress ?? 0]])
    console.log(questionNumber)

    if (!hasSetResume) {
      setShouldResume(randomOrder[questionNumberFromProgress] !== -1)
      setHasSetResume(true)
    }

    // look into answers and find the first one with answer as null and set that as questionNumber
    // also make sure if we're checking null that the answer only has one element
    const firstUnansweredQuestion = randomOrder
      ?.map((_i) => progressData?.answers[_i])
      ?.findIndex((_item) => {
        const answersWithoutNulls = _item?.answer?.filter(
          (_ans) => _ans !== null
        )
        return answersWithoutNulls?.length === 0
      })

    setQuestionNumber(
      firstUnansweredQuestion !== -1 ? firstUnansweredQuestion : 0
    )
    setCurrentQuestion(
      questions[
        randomOrder[
          firstUnansweredQuestion !== -1 ? firstUnansweredQuestion : 0
        ]
      ]
    )
  }, [questions, progressData?.questionNumber, setQuestionNumber, randomOrder])

  const handleNextQn = async (values) => {
    setFormValues((_val, index) => {
      return {
        ..._val,
        ...values,
      }
    })
    if (!hasUpdatedWaiveStatus) {
      const isAdminWaived = localStorage.getItem('isAdminWaived')

      if (
        isAdminWaived &&
        parseInt(localStorage.getItem('adminWaived')) === parseInt(course_id)
      ) {
        await UPDATE_SELF_WAIVE({
          variables: {
            userId: user?.id,
            courseId: +course_id,
            status: 'Waive Admin',
          },
        })
      } else if (
        localStorage.getItem('selfWaived') &&
        parseInt(localStorage.getItem('selfWaived')) === parseInt(course_id)
      ) {
        await UPDATE_SELF_WAIVE({
          variables: {
            userId: user?.id,
            courseId: +course_id,
            status: 'Waive User',
          },
        })
      } // else if for Prerequisites Met
      else if (
        localStorage.getItem('pre_req_met') &&
        parseInt(localStorage.getItem('pre_req_met')) === parseInt(course_id)
      ) {
        await UPDATE_SELF_WAIVE({
          variables: {
            userId: user?.id,
            courseId: +course_id,
            status: 'Prerequisites Met',
          },
        })
      } else {
        await UPDATE_SELF_WAIVE({
          variables: {
            userId: user?.id,
            courseId: +course_id,
            status: 'No Prerequisite',
          },
        })
      }
      setHasUpdatedWaiveStatus(true)
    }
    const progressQuestion = values?.answers.map((_val, index) => {
      return {
        question_id: _val?.question_id,
        answer: Array.isArray(_val.answer) ? _val.answer : [_val.answer],
      }
    })

    if (questionNumber + 1 < totalQuestions) {
      await PROGRESS_ASSESSMENT({
        variables: {
          questionId: values?.answers[values?.answers?.length - 1]?.question_id,
          courseId: +course_id,
          userId: user?.id,
          isCompleted: false,
          isResumeAssessment: shouldResume,
          answer: progressQuestion,
          questionNumber: questionNumber + 1,
          start_time: startTime,
          isLastQuestion: totalQuestions - 1 === questionNumber,
          questionOrder: randomOrder || null,
        },
      })
      setShouldResume(false)
      setQuestionNumber(questionNumber + 1)
      setCurrentQuestion(questions[randomOrder[questionNumber + 1]])
    } else {
      localStorage.removeItem('startTime')
      localStorage.removeItem('isExamStarted')
      const currentDate = moment()
      const formattedDate = currentDate.format('YYYY-MM-DD HH:mm:ss')
      const reassessment_date = moment(formattedDate).add(90, 'days')
      const data = values?.answers?.map((_val, index) => {
        return {
          ..._val,
          answer: Array.isArray(_val.answer) ? _val.answer : [_val.answer],
          end_time: formattedDate,
        }
      })
      try {
        const { data: progressData } = await PROGRESS_ASSESSMENT({
          variables: {
            questionId:
              values?.answers[values?.answers?.length - 1]?.question_id,
            courseId: +course_id,
            userId: user?.id,
            isCompleted: false,
            answer: progressQuestion,
            questionNumber: questionNumber + 1,
            start_time: startTime,
            isLastQuestion: totalQuestions - 1 === questionNumber,
            questionOrder: randomOrder || null,
          },
        })
        if (progressData?.createAssessment_progress.success) {
          navigate('/score', {
            state: {
              course_id: +course_id,
              isFailerPage:
                progressData?.createAssessment_progress.data?.status ===
                'Failed',
            },
          })
          // toast.success(message)
        }
      } catch (error) {
        // toast.success('Something went wrong!')
      }
    }
  }

  return (
    <>
      <div
        className={`col-xl-12  col-md-12 col-sm-12  row d-flex align-items-center justify-content-center ${style.Card}`}
      >
        <div className=" d-flex align-items-center justify-content-center w-100 p-3">
          <p className={`m-auto ${style.HeaderTitle}`}>{title}</p>
        </div>
        <div className={`w-100 ${style.HeaderLine}`}></div>

        <div className="p-4">
          {/* <Stepper
            connectorStateColors
            size={1}
            circleFontSize="2px"
            className="custom-stepper"
            connectorStyleConfig={{
              disabledColor: '#ECECEC',
              completedColor: '#EA4B22',
              activeColor: '#EA4B22',
              size: 4,
            }}
            styleConfig={{
              activeBgColor: '#EA4B22',
              inactiveBgColor: '#D5D5D5',
              size: 10,
            }}
            style={{
              padding: '0px',
            }}
          >
            {Array.from({ length: totalQuestions }, (_, index) => {
              return (
                <Step
                  active={index <= questionNumber ? true : false}
                  className={
                    index === questionNumber ? 'shadowOutlineCurrentQn' : ''
                  }
                ></Step>
              )
            })}
          </Stepper> */}

          <div style={{ padding: '30px 25px 80px 25px' }}>
            <div className="d-flex justify-content-between">
              <p className={`${style.QuestionFontStyle}`}>
                Question {questionNumber + 1}:
              </p>
              <p className={`${style.QuestionFontStyle}`}>
                <span style={{ color: '#EA4B22' }}>{questionNumber + 1}</span>/
                {totalQuestions}
              </p>
            </div>
            {questionLoading || !questions.length ? (
              <Spinner sx={{ marginTop: 4 }} />
            ) : (
              <div>
                <p
                  className={`${style.QuestionFontStyle}`}
                  dangerouslySetInnerHTML={{
                    __html: currentQuestion?.question?.question,
                  }}
                ></p>
                <div style={{ position: 'relative' }}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      answers: initialValues,
                    }}
                    onSubmit={(values) => {
                      handleNextQn(values)
                    }}
                  >
                    {({
                      errors,
                      values,
                      touched,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                          {currentQuestion?.question.type === 'radio' &&
                            currentQuestion?.question.options?.map(
                              (option, index) => {
                                return (
                                  <div className="d-flex gap-2 align-items-center mb-3 p-2">
                                    <label>
                                      <RadioButton
                                        name={`answers[${randomOrder[questionNumber]}].answer`}
                                        id={`answers[${randomOrder[questionNumber]}].questionNumber_Option${index}`}
                                        value={
                                          values.answers[
                                            randomOrder[questionNumber]
                                          ]?.answer
                                        }
                                        setFieldValue={setFieldValue}
                                        checkBoxLabel={capitalizeFirst(
                                          option.choice
                                        )}
                                        val={option.value}
                                      />
                                    </label>
                                  </div>
                                )
                              }
                            )}
                          {currentQuestion?.question.type === 'checkbox' &&
                            currentQuestion?.question?.options?.map(
                              (option, index) => (
                                <div
                                  key={`question-${randomOrder[questionNumber]}-option-${index}`}
                                  className="d-flex gap-2 align-items-center mb-3 p-2"
                                >
                                  <input
                                    type="checkbox"
                                    name={`answers[${randomOrder[questionNumber]}].answer`}
                                    checked={values.answers[
                                      randomOrder[questionNumber]
                                    ]?.answer?.includes(option.value)}
                                    onChange={handleChange}
                                    value={option.value}
                                    id={`answers[${randomOrder[questionNumber]}].questionNumber_Option${index}`}
                                  />
                                  <label>{option.choice}</label>
                                  {/* <Field
                                    type="checkbox"
                                    name={`answers[${questionNumber}].answer`}
                                    value={option.value}
                                    id={`answers[${questionNumber}].questionNumber_Option${index}`}
                                  /> */}
                                  {/* <span className="mb-1">{option.choice}</span> */}
                                </div>
                              )
                            )}

                          {currentQuestion?.question.type === 'likert' && (
                            <div style={{ display: 'flex', gap: '1.25rem' }}>
                              {currentQuestion.question.options?.map(
                                (option, index) => {
                                  return (
                                    <div
                                      key={`question-${randomOrder[questionNumber]}-option-${index}`}
                                    >
                                      <RadioButton
                                        name={`answers[${randomOrder[questionNumber]}].answer`}
                                        id={`answers[${randomOrder[questionNumber]}].questionNumber_Option${index}`}
                                        value={
                                          values.answers[
                                            randomOrder[questionNumber]
                                          ]?.answer
                                        }
                                        setFieldValue={setFieldValue}
                                        val={option.value}
                                        className="mx-auto"
                                        checkBoxLabel={capitalizeFirst(
                                          option.choice
                                        )}
                                        vertical={true}
                                      />
                                    </div>
                                  )
                                }
                              )}
                            </div>
                          )}
                          <Button
                            color="secondary"
                            type="Submit"
                            variant="outlined"
                            disabled={
                              checkEmptyArray(
                                values?.answers[randomOrder[questionNumber]]
                                  ?.answer
                              )
                                ? true
                                : false
                            }
                            style={{
                              marginTop: '30px',
                              position: 'absolute',
                            }}
                          >
                            <span>
                              {loading ||
                              self_waive_loading ||
                              assessmentLogLoading ? (
                                <Spinner
                                  size="20px"
                                  sx={{
                                    '.MuiCircularProgress-root': {
                                      color: 'white',
                                    },
                                  }}
                                  thickness={5}
                                />
                              ) : totalQuestions - 1 >
                                randomOrder[questionNumber] ? (
                                'Next'
                              ) : (
                                'Submit'
                              )}
                            </span>
                          </Button>
                        </form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(Assessment)
