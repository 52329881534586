import React from "react";

const style = {
  color: "#53605F",
  textAlign: "center",
  fontFamily: "Open Sans",
  fontSize: "20px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "34px" /* 170% */,
};

const Message = ({ data }) => {
  return (
    <div
      style={style}
      className="text-center"
      dangerouslySetInnerHTML={{
        __html: data,
      }}
    ></div>

    // <p className="text-center" style={style}>
    //   {data}
    // </p>
  );
};

export default Message;
