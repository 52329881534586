import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Chip } from '../../components/Chip'
import Table from '../../components/Table/Table'

import Header from '../../components/Header/Header'
import './AdminRequestFormViewModule.css'

import { GET_REQUEST_BY_ID, GET_TRAINING_BY_REQUEST } from '../../api/graphql'

import Button from '../../components/Button/Button'

import { trainingTypeList } from '../../utils/common'
import { useQuery } from '@apollo/client'
import NavBar from '../../components/Navbar/Navbar'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import moment from 'moment'
import CustomActivityIndicator from './../../components/Common/CustomActivityIndicator'
import Spinner from '../../components/Spinner/Spinner'
import { checkUserPermissions } from '../../utils/UserUtils'

const AdminRequestForm = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [load, setLoad] = useState(false)

  const [data, setData] = useState()
  const [trainingData, setTrainingData] = useState()
  const [interestedArr, setInterestedArr] = useState([])
  const userType = localStorage.getItem('userType')
  console.log('userType---', userType)
  const { loading: requestLoading, refetch } = useQuery(GET_REQUEST_BY_ID, {
    variables: {
      requestId: location.state.id,
    },
  })
  const { loading: trainingLoading, refetch: refetchTraining } = useQuery(
    GET_TRAINING_BY_REQUEST,
    {
      variables: {
        requestId: location.state.id,
      },
    }
  )

  const sampleColumns = [
    { path: 'date', header: 'Date', width: '25%' },
    { path: 'trainingType', header: 'Type', width: '25%' },

    {
      path: 'status',
      header: 'Status',
      width: '10%',
      content: (item) => (
        <Chip
          className="chip-instance"
          divClassName="chip-2"
          status={item?.status?.toLowerCase()}
          text={item.status}
        />
      ),
    },
    { path: 'link', header: '', width: '15%' },
  ]

  const getList = async () => {
    if (!location.state || !location.state.id) {
      navigate(userType == 'admin' ? '/admin/request' : '/request/request-list')
      return
    }

    const id = location.state.id
    let { data } = await refetch({
      requestId: location.state.id,
    })
    setData(data.GetInstructorTrainingRequestBYId.data)

    let interestedArr = []
    if (
      data.GetInstructorTrainingRequestBYId.data.intrested_training &&
      data.GetInstructorTrainingRequestBYId.data.intrested_training.length > 0
    ) {
      data.GetInstructorTrainingRequestBYId.data.intrested_training.map(
        (item) => {
          let arr = trainingTypeList.filter((m) => m.value === item)
          interestedArr.push(arr[0].label)
        }
      )
    }
    setInterestedArr(interestedArr)

    let { data: data1 } = await refetchTraining({
      requestId: location.state.id,
    })
    const sampleData = []

    if (data1.GetTrainingList.data.length > 0) {
      data1.GetTrainingList.data.map((item, index) => {
        sampleData.push({
          date: item.start_date
            ? moment(item.start_date).format('DD MMM yyyy')
            : '-',
          trainingType: item.training_type,
          status: item.status,
          link: (
            <a
              className="chip invited"
              href="javascript:void(0)"
              onClick={() =>
                navigate(
                  userType == 'admin'
                    ? `/admin/request/view-training`
                    : '/request/view-training',
                  {
                    state: {
                      id: item.id,
                      requestCourseId: item?.course_id,
                      requestId: location.state.id,
                    },
                  }
                )
              }
            >
              View
            </a>
          ),
        })
      })
    }
    const newTrainingData = sampleData
      ?.sort((a, b) => {
        // Sort by Start Date
        if (moment(b.date).isBefore(a.date)) {
          return -1
        } else {
          return 1
        }
      })
      .reverse()
    setTrainingData(newTrainingData)
  }

  useEffect(() => {
    getList()
  }, [])
  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: 'center' }}
        visible={load}
      />
      <div className="row p-0  no-gutters nav-area">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0  no-gutters">
            <div className="col-2">
              <NavBar user={userType} />
            </div>
            <div className="col-10 row admin-request">
              <div className="mb-2 p-0">
                <a
                  href="javascript:void(0)"
                  onClick={(e) =>
                    navigate(
                      userType == 'admin'
                        ? '/admin/request'
                        : '/request/request-list'
                    )
                  }
                  className="back-link"
                >
                  <ChevronLeftIcon />
                  Back
                </a>
              </div>
              {requestLoading ? (
                <Spinner sx={{ marginTop: 4 }} />
              ) : (
                <>
                  <div className="col-xl-6 regBox p-5">
                    <div className="row">
                      <div className="col-lg-9 requestTitle mt-2">
                        Contract Details
                      </div>

                      <div className="col-lg-3 mt-2">
                        {checkUserPermissions('request.edit') && (
                          <Button
                            type="button"
                            onClick={() =>
                              navigate(
                                userType == 'admin'
                                  ? `/admin/request/edit-request`
                                  : `/request/edit-request`,
                                {
                                  state: { id: data.id },
                                }
                              )
                            }
                            className="button-base-instance-grey"
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-2 mt-2">
                      <Chip
                        className="chip-instance"
                        divClassName="chip-2"
                        status={data?.status?.toLowerCase()}
                        text={data?.status}
                      />
                    </div>
                    <div className="row mt-2 p-2">
                      <hr />
                    </div>
                    <div className="row mt-2 ms-1 view-label">
                      What MHFA training program(s) are you interested in ?
                    </div>
                    <div className="row mt-2 ms-1">
                      {interestedArr.map((item) => (
                        <div>{item}</div>
                      ))}
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">
                          # trainings requested
                        </label>
                      </div>
                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">
                        {data?.max_training}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">Date</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">
                        {data?.submission_date
                          ? moment(data?.submission_date).format('DD MMM yyyy')
                          : '-'}
                      </div>
                    </div>

                    <div className="row mt-2 p-2">
                      <hr />
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="view-label">First Name</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">
                        {data?.first_name ?? '-'}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">Last Name</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">
                        {data?.last_name ?? '-'}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">Email Address</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">
                        {data?.email ?? '-'}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">Phone Number</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">
                        {data?.phone_number ?? '-'}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">Job Title</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">
                        {data?.job_title ?? '-'}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">Organization Name</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">
                        {data?.organization ?? '-'}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">Address</label>
                      </div>
                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">
                        {data?.addressline1 ?? '-'}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">City</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>
                      <div className="col-md-7 view-content">
                        {data?.city ?? '-'}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">State</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>

                      <div className="col-md-7 view-content">
                        {data?.state ?? '-'}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label className="view-label">Zip code</label>
                      </div>

                      <div className="col-md-1 d-none d-sm-inline">:</div>

                      <div className="col-md-7 view-content">
                        {data?.zipcode ?? '-'}
                      </div>
                    </div>
                    <div className="row mt-2 p-2">
                      <hr />
                    </div>

                    <div className="row mt-1">
                      <label className="view-label">
                        Are you national council member?
                      </label>
                    </div>
                    <div className="row view-content mt-2 ms-1">
                      <div>{data?.isNationalCouncilMember ?? '-'}</div>
                    </div>

                    <div className="row mt-1">
                      <label className="view-label">
                        Do you currently have any training funding available?
                      </label>
                    </div>
                    <div className="row view-content mt-2 ms-1">
                      <div>{data?.have_funding ?? '-'}</div>
                    </div>

                    {checkUserPermissions('request.edit') && (
                      <div className="row mt-2">
                        <label className="view-label mb-2">
                          Allow view Trainings?
                        </label>
                        <div className="view-content">
                          {data?.permissions
                            ? data?.permissions.length > 0
                              ? 'Yes'
                              : 'No'
                            : 'No'}
                        </div>
                      </div>
                    )}
                  </div>
                  {data?.status?.toLowerCase() == 'contracted' &&
                    checkUserPermissions(
                      'training.view',
                      data?.permissions || null
                    ) && (
                      <div className="col-xl-6 mt-sm-2">
                        <div className="row regBox mx-xl-3">
                          <div className="container px-5 pt-4">
                            <div className="row pt-3">
                              <div className="col-md-9 requestTitle">
                                Trainings
                              </div>
                              {data?.max_training > trainingData?.length && (
                                <div className="col-lg-3 text-right mt-sm-2">
                                  {checkUserPermissions('training.add') && (
                                    <Button
                                      type="button"
                                      onClick={(e) =>
                                        navigate(
                                          userType == 'admin'
                                            ? '/admin/request/training-form'
                                            : '/request/training-form',
                                          {
                                            state: {
                                              id: data.id,
                                              courseId: data.course_id,
                                            },
                                          }
                                        )
                                      }
                                    >
                                      Add
                                    </Button>
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="row p-3">
                              <hr />
                            </div>
                            {trainingLoading || !trainingData ? (
                              <Spinner sx={{ marginTop: 4 }} />
                            ) : (
                              <div className="row no-gutters table-responsive">
                                <Table
                                  data={trainingData}
                                  columns={sampleColumns}
                                  className="insCertTable"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminRequestForm
