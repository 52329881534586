import React, { memo } from "react";
import styles from "./MessagCard.module.css";
import MessageCardLine from "./MessageCardLine";
import Message from "./Message";
import Line from "../Line/Line";
const MessageCard = ({
  title,
  children,
  messageCardStyle,
  titleStyle,
  showLine = false,
  messageData,
  normalTitle,
  subTitle,
  className,
  onClick
}) => {
  return (
    <div
      className={`${
        className
          ? className
          : "col-lg-5 col-md-7 col-sm-10    d-flex flex-column align-items-center justify-content-center cardDiv  p-4"
      } `}
      style={messageCardStyle}
      onClick={onClick}
    >
      {title && (
        <p className={`${styles.title} mt-4 text-center`} style={titleStyle}>
          {title}
        </p>
      )}

      {subTitle && <p className={`${styles.subtitle}`}>{subTitle}</p>}
      {normalTitle && <p className={`${styles.normalTitle}`}>{normalTitle}</p>}
      {showLine && <Line lineWidth="230px" />}

      {messageData && <Message data={messageData} />}
      {children}
    </div>
  );
};

export default memo(MessageCard);
