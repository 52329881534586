import React from 'react'
import TableBody from '../TableBody/TableBody'
import TableHeader from '../TableHeader/TableHeader'

function Table({ data, columns, className, header = true, ...otherProps }) {
  return (
    <table className={className} {...otherProps}>
      {header && <TableHeader columns={columns} />}
      <TableBody columns={columns} data={data} />
    </table>
  )
}

export default Table
