import React from 'react'
import ImageOverlapSection from '../components/ImageOverlapSection/ImageOverlapSection'
import Header from '../components/Header/Header'

const Layout = ({ title, children, disableLogoClick, shouldReload }) => {
  return (
    <>
      <div className="container-fluid" style={{ minHeight: '100vh' }}>
        <div className="become-an-instructor">
          <ImageOverlapSection />
          <div
            className="row no-gutters pt-2 pb-2"
            style={{ minHeight: '100vh' }}
          >
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <Header
                className={''}
                disableLogoClick={disableLogoClick}
                shouldReload={shouldReload}
              />
              {title && <div className="instructorTitle">{title}</div>}

              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
