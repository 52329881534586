import React from "react";

function TableHeader({ columns }) {
    return <thead>
        <tr>
            {columns.map((column, index) => (
                <th key={`${column.path}=${index}`}
                    className={column.align == 'center' ? 'clickable p-2 text-center' : 'clickable p-2'}>
                    {column.header}{" "}
                    {column.icon && (
                        <span style={{cursor: "pointer"}}>{column.icon}</span>
                    )}
                </th>
            ))}
        </tr>
    </thead>;
}

export default TableHeader;
