import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'

import { Create_Request, GET_COURSE } from '../../api/graphql'

import Header from '../../components/Header/Header'
import Button from '../../components/Button/Button'
import TextField from '../../components/TextField/TextField'
import RadioButton from '../../components/RadioButton/RadioButton'
import Spinner from '../../components/Spinner/Spinner'
import NavBar from '../../components/Navbar/Navbar'
import ReactSelectField from '../../components/ReactSelectField/ReactSelectField'

import {
  digitRegExp,
  phoneRegExp,
  usStatesList,
  trainingTypeList,
} from '../../utils/common'

import './AdminRequestFormModule.css'
import NewRequest from '../../components/NewRequest/NewRequest'

const AdminRequestForm = () => {
  const navigate = useNavigate()
  const [CREATE_REQUEST, { loading }] = useMutation(Create_Request)
  const [LMSCourseList, setLMSCourseList] = useState([])
  const [intrestedTraining, setIntrestedTraining] = useState([])
  const { refetch } = useQuery(GET_COURSE)

  const FormSchema = Yup.object().shape({
    // course: Yup.object().required("Course is required"),
    maxTraining: Yup.number()
      .max(99, 'Max 99 allowed')
      .required('Number of trainings requested is required'),
    firstName: Yup.string().trim().required('First Name is required'),
    lastName: Yup.string().trim().required('Last Name is required'),
    email: Yup.string().email().trim().required('Email is required'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .matches(digitRegExp, 'Phone number must be exactly 10 digits'),
    jobTitle: Yup.string().required('Job title is required'),
    organization: Yup.string().required('Organization is required').trim(),
  })
  const handleClose = () => {
    navigate('/admin/request')
    return
  }

  const handleRequestForm = async (values) => {
    try {
      const { data } = await CREATE_REQUEST({
        variables: {
          email: values.email?.trim()?.toLowerCase(),
          firstName: values.firstName,
          lastName: values.lastName,
          status: 'pending',
          phoneNumber: values.phoneNumber,
          jobTitle: values.jobTitle,
          organization: values.organization,
          city: values.city,
          state: values.state.label,
          zipcode: values.zipcode,
          addressline1: values.addressline1,
          submissionDate: new Date(),
          intrestedTraining: values.intrestedTraining,
          courseId: values.course.value,
          courseName: values.course.label,
          isNationalCouncilMember: values.isNationalCouncilMember,
          maxTraining: values.maxTraining,
          haveFunding: values.haveFunding,
          permissions: values.canView ? ['training.view'] : [],
        },
      })
      if (data?.createRequest?.success) {
        // toast.success('Request added')
        navigate('/admin/request')
      } else {
        console.error(data?.createRequest?.message)
        // toast.error(data?.createRequest?.message)
      }
    } catch (error) {
      console.log('catch------', error)
    }
  }

  const getList = async () => {
    try {
      const data1 = await refetch()
      let courseArr = []
      for (let i = 0; i < data1.data.GetCourse?.data?.length; i++) {
        courseArr.push({
          label: data1.data.GetCourse[i].name,
          value: data1.data.GetCourse[i].id,
        })
      }
      setLMSCourseList(courseArr)
    } catch (error) {
      console.log('catch----', error)
    }
  }

  useEffect(() => {
    getList()
  }, [])
  return (
    <>
      <div className="row p-0 nav-area no-gutters">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0  no-gutters">
            <div className="col-2">
              <NavBar user={'admin'} />
            </div>
            <div className="col-10 row admin-request">
              <div className="mb-2 p-0">
                <a
                  href="javascript:void(0)"
                  onClick={(e) => navigate(`/admin/request`)}
                  className="back-link"
                >
                  <ChevronLeftIcon />
                  Back
                </a>
              </div>
              <div className="col-lg-6 regBox p-3">
                <NewRequest
                  handleRequestForm={handleRequestForm}
                  handleClose={handleClose}
                  loggedEmail={''}
                />
                {/* <Formik
                  enableReinitialize={true}
                  initialValues={{
                    // status: requestStatusList[0],
                    trainingType: "",
                    course: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    jobTitle: "",
                    organization: "",
                    state: "",
                    city: "",
                    zipcode: "",
                    isNationalCouncilMember: "Not Sure",
                    isAdult: false,
                    isYouth: false,
                    intrestedTraining: [],
                    maxTraining: "",
                    haveFunding: "Not Sure",
                  }}
                  validationSchema={FormSchema}
                  onSubmit={handleRequestForm}
                >
                  {({
                    errors,
                    values,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row p-3">
                          <div className="requestTitle">Request Details</div>

                          <div className="">
                            <div className="row align-items-center">
                              <div className="col-md-12 mt-2 d-flex flex-column ">
                                <b className="inputLabel">
                                  What MHFA training program(s) are you
                                  interested in ?
                                </b>
                                {trainingTypeList.map((item, index) => (
                                  <div className="d-flex ">
                                    <label>
                                      <Checkbox
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        name="intrestedTraining"
                                        id="intrestedTraining"
                                        onClick={(e) => {
                                          let arr = intrestedTraining;
                                          if (e.target.checked) {
                                            arr.push(item.value);
                                          } else {
                                            arr = intrestedTraining.filter(
                                              (m) => m !== item.value
                                            );
                                          }
                                          setIntrestedTraining(arr);
                                        }}
                                      />
                                      {item.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                           

                            <div className="row mt-2">
                              <div className="col-md-12 mb-3 flex-column d-flex ">
                                <TextField
                                  id="maxTraining"
                                  type="number"
                                  name="maxTraining"
                                  placeholder="How many MHFA Instructor trainings do you plan to host each year? *"
                                  value={values.maxTraining}
                                  label="How many MHFA Instructor trainings do you plan to host each year? *"
                                  error={errors.maxTraining}
                                  touched={touched.maxTraining}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                  maxlength={2}
                                />
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-6 mb-3 flex-column d-flex ">
                                <TextField
                                  id="firstName"
                                  name="firstName"
                                  placeholder="First Name"
                                  value={values.firstName}
                                  label="First Name *"
                                  error={errors.firstName}
                                  touched={touched.firstName}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-6 mb-3 flex-column d-flex">
                                <TextField
                                  id="lastName"
                                  name="lastName"
                                  placeholder="Last Name"
                                  value={values.lastName}
                                  label="Last Name *"
                                  error={errors.lastName}
                                  touched={touched.lastName}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-3 flex-column d-flex ">
                                <TextField
                                  name="email"
                                  placeholder="Email"
                                  value={values.email?.trim()?.toLowerCase()}
                                  label="Email address *"
                                  error={errors.email}
                                  touched={touched.email}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-3 flex-column d-flex ">
                                <TextField
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  placeholder="Phone Number"
                                  value={values.phoneNumber}
                                  label="Phone Number *"
                                  error={errors.phoneNumber}
                                  touched={touched.phoneNumber}
                                  handleBlur={handleBlur}
                                  maxlength={10}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mb-3 flex-column d-flex ">
                                <TextField
                                  name="jobTitle"
                                  placeholder="Job Title *"
                                  label="Job Title *"
                                  value={values.jobTitle}
                                  error={errors.jobTitle}
                                  touched={touched.jobTitle}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-6 mb-3 flex-column d-flex ">
                                <TextField
                                  name="organization"
                                  placeholder="Organization"
                                  label="Organization *"
                                  value={values.organization}
                                  error={errors.organization}
                                  touched={touched.organization}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mb-3 flex-column d-flex ">
                                <TextField
                                  name="city"
                                  placeholder="City"
                                  label="City"
                                  value={values.city}
                                  error={errors.city}
                                  touched={touched.city}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-6 mb-3 flex-column d-flex ">
                                <ReactSelectField
                                  label="State"
                                  placeholder="State"
                                  inputId="state"
                                  mandatory={1}
                                  search={false}
                                  error={errors.state}
                                  touched={touched.state}
                                  value={values.state}
                                  options={usStatesList}
                                  onChange={(text) => {
                                    setFieldValue("state", text);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-3 flex-column d-flex ">
                                <TextField
                                  name="zipcode"
                                  placeholder="Zip Code"
                                  label="Zip Code"
                                  value={values.zipcode}
                                  error={errors.zipcode}
                                  touched={touched.zipcode}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div className="row align-items-center">
                              <div className="col-md-6 mb-3  d-flex flex-column ">
                                <b className="inputLabel">
                                  Are you a National Council member?
                                </b>
                                <div className="d-flex  ">
                                  <RadioButton
                                    id="yes"
                                    name="isNationalCouncilMember"
                                    value={values.isNationalCouncilMember}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="Yes"
                                    val="Yes"
                                  />
                                  <RadioButton
                                    name="isNationalCouncilMember"
                                    id="no"
                                    value={values.isNationalCouncilMember}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="No"
                                    val="No"
                                  />
                                  <RadioButton
                                    name="isNationalCouncilMember"
                                    id="notSure"
                                    value={values.isNationalCouncilMember}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="Not Sure"
                                    val="Not Sure"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3  d-flex flex-column ">
                                <b className="inputLabel">
                                  Do you currently have any training funding
                                  available? *
                                </b>
                                <div className="d-flex  ">
                                  <RadioButton
                                    id="fundingYes"
                                    name="haveFunding"
                                    value={values.haveFunding}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="Yes"
                                    val="Yes"
                                  />
                                  <RadioButton
                                    name="haveFunding"
                                    id="fundingNo"
                                    value={values.haveFunding}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="No"
                                    val="No"
                                  />
                                  <RadioButton
                                    name="haveFunding"
                                    id="fundingNotSure"
                                    value={values.haveFunding}
                                    setFieldValue={setFieldValue}
                                    checkBoxLabel="Not Sure"
                                    val="Not Sure"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6"></div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-6 mt-2">
                                    <Button
                                      className="button-base-instance-grey"
                                      type="button"
                                      onClick={(e) => window.history.back()}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                  <div className="col-md-6 mt-2">
                                    <Button type="submit">
                                      {loading ? (
                                        <Spinner
                                          size="20px"
                                          sx={{
                                            ".MuiCircularProgress-root": {
                                              color: "white",
                                            },
                                          }}
                                          thickness={5}
                                        />
                                      ) : (
                                        "Save"
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminRequestForm
