import React, { memo } from "react";
import ErrorMessage from "../Common/ErrorMessage";
import Spinner from "../Spinner/Spinner";
import Button from "../Button/Button";

import PropTypes from "prop-types";

const PasswordVerify = ({
  password,
  passError,
  passwordLoading,
  setPassError,
  setPassword,
  checkPassword,
}) => {
  return (
    <>
      <div className="mt-5 row no-gutters emailHead">
        Enter Password for Verification
      </div>
      <div className="col-lg-6 ptext">
        Welcome back! Please enter your Password.
      </div>
      <div className="row no-gutters mt-5  formWrapper">
        <b className="inputLabel">Password</b>
        <input
          type="password"
          id="passId"
          className="inputWrapper"
          onChange={(e) => {
            setPassError(false);
            setPassword(e.target.value?.trim());
          }}
          placeholder="Password"
          value={password}
          onKeyUp={(e) => e.keyCode === 13 && checkPassword()}
        />
        <ErrorMessage
          visible={passError}
          error="Password doesnt match. Please check again"
        />

        <Button
          onClick={checkPassword}
          text={
            passwordLoading ? (
              <Spinner
                size="20px"
                sx={{
                  ".MuiCircularProgress-root": {
                    color: "white",
                  },
                }}
                thickness={5}
              />
            ) : (
              "Next"
            )
          }
          style={{ marginTop: "59px" }}
        />
      </div>
    </>
  );
};


PasswordVerify.propTypes = {
  password: PropTypes.string,
  passError: PropTypes.bool,
  passwordLoading: PropTypes.bool,
  setPassError: PropTypes.func,
  setPassword: PropTypes.func,
  checkPassword: PropTypes.func,
};

export default memo(PasswordVerify);
