import React from 'react'
import styles from './RadioButton.module.css'

const RadioButton = ({
  error,
  setFieldValue,
  label,
  name,
  value,
  checkBoxLabel,
  val,
  id,
  vertical = false,
}) => {
  return (
    <>
      {label && <b className={styles.inputLabel}>{label}</b>}
      <div>
        <input
          type="radio"
          id={id}
          name={name}
          value={val}
          onChange={() => setFieldValue(name, val)}
          checked={val === value}
        />
        <label htmlFor={id}>{checkBoxLabel}</label>

        {!vertical && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
      </div>
    </>
  )
}

export default RadioButton
