import { useQuery } from '@apollo/client'
import Header from '../../components/Header/Header'
import NavBar from '../../components/Navbar/Navbar'
import './AdminSupportModule.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { GET_SUPPORT } from '../../api/graphql'
import { useEffect, useRef, useState } from 'react'
import Spinner from '../../components/Spinner/Spinner'
import Table from '../../components/Table/Table'
import { Link } from 'react-router-dom'
import TextField from '../../components/TextField/TextField'
import ReactSelectField from '../../components/ReactSelectField/ReactSelectField'
import { DateCustomInput } from '../../utils/common'
import DateField from '../../components/DatePickerField/DatePickerField'
import Button from '../../components/Button/Button'
import { SearchOutlined } from '@mui/icons-material'
import { Formik } from 'formik'

export default function AdminSupport() {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [status, setStatus] = useState('')
  const [start_date, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  ) // 30 days ago by default
  const [end_date, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate()))
  )

  const formRef = useRef()

  const { loading: supportLoading, refetch } = useQuery(
    GET_SUPPORT,
    {
      variables: {
        searchTerm: '',
        startDate: '',
        endDate: '',
      },
    },
    {
      // disable it here
    }
  )
  const location = useLocation()
  const navigate = useNavigate()
  const userType = localStorage.getItem('userType')
  const [supportList, setSupportList] = useState([])
  const [isSupportLoading, setIsSupportLoading] = useState(false)

  async function getSupport(values) {
    // console.log('GETTING SUPPORT USING GET SUPPORT')
    if (
      values.search ||
      values.status ||
      values.start_date ||
      values.end_date
    ) {
      setIsSupportLoading(true)
      const { data } = await refetch({
        searchTerm: values?.search,
        startDate: values?.start_date,
        endDate: values?.end_date,
        //   status: status.value,
      })
      setStatus(values?.status)
      console.log('GOT THE DATA', data)
      setSupportList(data?.GetSupport.data)
      setIsSupportLoading(false)
    }
  }

  async function filterStatus(e) {
    setStatus(e)
    // getSupport()
  }

  async function filterStartDate(e) {
    setStartDate(e)
    // getSupport()
  }

  async function filterEndDate(e) {
    setEndDate(e)
    // getSupport()
  }

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDebouncedSearch(search)
  //   }, 500) // 1000ms = 1 second

  //   return () => {
  //     clearTimeout(handler)
  //   }
  // }, [search])

  // useEffect(() => {
  //   getSupport()
  // }, [debouncedSearch])

  useEffect(() => {
    if (!location.state || !location.state.id) {
      navigate(userType === 'admin' ? '/admin/support' : '/')
      return
    }
  }, [])

  useEffect(() => {
    getSupport({
      search: '',
      status: '',
      start_date: new Date(new Date().setDate(new Date().getDate() - 30)),
      end_date: new Date(new Date().setDate(new Date().getDate())),
    })
  }, [])

  const tableData = [
    {
      path: 'id',
      header: '#',
      width: '5%',
    },
    {
      path: 'first_name',
      header: 'First Name',
      width: '15%',
    },
    {
      path: 'last_name',
      header: 'Last Name',
      width: '25%',
    },
    {
      path: 'prevEmail',
      header: 'User ID',
      width: '25%',
    },
    {
      path: 'status',
      header: 'Status',
      width: '20%',
      content: (item) => (
        <span
          style={{
            borderRadius: '5px',
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '2px',
            paddingBottom: '2px',
            backgroundColor:
              item.status === 'Open'
                ? '#FFD700'
                : item.status === 'Closed'
                  ? '#32CD32'
                  : '#FF4500',
            color: '#fff',
          }}
        >
          {item.status}
        </span>
      ),
    },
    {
      path: 'created_at',
      header: 'Created At',
      width: '15%',
      content: (item) => (
        <span>
          {new Date(parseInt(item.created_at)).toLocaleDateString('en-US')}
        </span>
      ),
    },
    {
      path: 'id',
      header: 'Actions',
      width: '15%',
      content: (item) => (
        <>
          <Link
            to={`/admin/support/${item.id}`}
            className="btn btn-link btn-sm"
          >
            View
          </Link>
        </>
      ),
    },
  ]
  return (
    <>
      <div className="row p-0 nav-area no-gutters">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0 no-gutters">
            <div className="col-2">
              <NavBar user={'admin'} />
            </div>
            <div className="col-10 row admin-request">
              <div className="row">
                <div className="col-lg-4">
                  <h2 className="instructor-title">Support Tickets</h2>
                  {/* <p className="there-are-many">
                    View and manage support tickets from learners.
                  </p> */}
                </div>
                <div className="col-lg-8 row">
                  <Formik
                    // enableReinitialize={true}
                    initialValues={{
                      search: '',
                      status: '',
                      start_date: new Date(
                        new Date().setDate(new Date().getDate() - 30)
                      ),
                      end_date: new Date(
                        new Date().setDate(new Date().getDate())
                      ),
                    }}
                    innerRef={formRef}
                    onSubmit={getSupport}
                  >
                    {({ handleSubmit, values, setFieldValue, dirty }) => (
                      <>
                        <form
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end',
                            gap: '0.25rem',
                          }}
                          onSubmit={handleSubmit}
                        >
                          <div>
                            <label
                              htmlFor=""
                              style={{ marginBottom: '0.75rem' }}
                            >
                              Search Term
                            </label>
                            <div>
                              <TextField
                                placeholder={'Search Support Ticket...'}
                                value={values.search}
                                onChange={(e) =>
                                  setFieldValue('search', e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor=""
                              style={{ marginBottom: '0.75rem' }}
                            >
                              Status
                            </label>
                            <div>
                              <ReactSelectField
                                placeholder={'Status'}
                                options={[
                                  { label: 'New', value: 'New' },
                                  { label: 'Open', value: 'Open' },
                                  { label: 'Closed', value: 'Closed' },
                                ]}
                                onChange={(e) => {
                                  setFieldValue('status', e)
                                }}
                                value={values.status}
                              />
                            </div>
                          </div>
                          <div>
                            <label htmlFor="">Start Date</label>
                            <DateField
                              placeholder="Start Date"
                              selected={values.start_date}
                              onChange={(date) => {
                                setFieldValue('start_date', date)
                              }}
                              customInput={<DateCustomInput id="start_date" />}
                            />
                          </div>
                          <div>
                            <label htmlFor="">End Date</label>
                            <DateField
                              placeholder="End Date"
                              selected={values.end_date}
                              onChange={(e) => {
                                setFieldValue('end_date', e)
                              }}
                              minDate={values.start_date}
                              customInput={<DateCustomInput id="end_date" />}
                            />
                          </div>
                          <div>
                            <label htmlFor="">&nbsp;</label>
                            <Button
                              style={{
                                height: '100%',
                              }}
                              // disabled={!dirty}
                              onClick={getSupport}
                              type={'submit'}
                            >
                              Search
                            </Button>
                          </div>
                        </form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="row">
                {supportLoading || isSupportLoading ? (
                  <Spinner sx={{ marginTop: 4 }} />
                ) : (
                  <div className="row no-gutters table-responsive mt-3">
                    <Table
                      data={
                        status
                          ? supportList.filter(
                              (item) => item.status === status.value
                            )
                          : supportList
                      }
                      columns={tableData}
                      className="insCertTable"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
