import { useMutation } from "@apollo/client";
import { CREATE_USER_LOG, UPDATE_CANDIDATE_STATUS } from "../api/graphql";

export const useUpdateUserActivity = () => {
  const [UPDATE_STATUS] = useMutation(UPDATE_CANDIDATE_STATUS);
  const [CREATE_LOG] = useMutation(CREATE_USER_LOG);

  const updateUserActivity = async (courseId, userId, status) => {
    await UPDATE_STATUS({
      variables: {
        courseId,
        userId,
        status,
      },
    });
  };

  const createUserLog = async (logModule, logDescription, userId) => {
    await CREATE_LOG({
      variables: {
        logModule,
        logDescription,
        userId,
      },
    });
  };

  return { updateUserActivity, createUserLog };
};
