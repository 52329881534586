import React, { memo, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import * as Yup from 'yup'
import { Formik } from 'formik'

import Button from '../../components/Button/Button'
import TextField from '../../components/TextField/TextField'
import RadioButton from '../../components/RadioButton/RadioButton'
import Spinner from '../../components/Spinner/Spinner'
import ReactSelectField from '../../components/ReactSelectField/ReactSelectField'

import {
  digitRegExp,
  phoneRegExp,
  usStatesList,
  trainingTypeList,
} from '../../utils/common'

import './NewRequestForm.css'

const NewRequestForm = ({
  loggedEmail,
  handleRequestForm,
  loading,
  handleClose,
}) => {
  console.log('loggedEmail---', loggedEmail)
  const [intrestedTraining, setIntrestedTraining] = useState([])

  const FormSchema = Yup.object().shape({
    maxTraining: Yup.number()
      .max(99, 'Max 99 allowed')
      .required('Number of trainings requested is required'),
    firstName: Yup.string().trim().required('First Name is required'),
    lastName: Yup.string().trim().required('Last Name is required'),
    email: Yup.string().email().trim().required('Email is required'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .matches(digitRegExp, 'Phone number must be exactly 10 digits'),
    jobTitle: Yup.string().required('Job title is required'),
    organization: Yup.string().required('Organization is required').trim(),
    addressline1: Yup.string().required('Address is required').trim(),
  })

  return (
    <>
      <div className="row">
        <Formik
          enableReinitialize={true}
          initialValues={{
            trainingType: '',
            course: '',
            firstName: '',
            lastName: '',
            email: loggedEmail,
            phoneNumber: '',
            jobTitle: '',
            organization: '',
            addressline1: '',
            state: '',
            city: '',
            zipcode: '',
            isNationalCouncilMember: 'Not Sure',
            isAdult: false,
            isYouth: false,
            intrestedTraining: [],
            maxTraining: '',
            haveFunding: 'Not Sure',
            canView: false,
          }}
          validationSchema={FormSchema}
          onSubmit={handleRequestForm}
        >
          {({
            errors,
            values,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row p-3">
                  <div className="requestTitle">Contract Details</div>

                  <div className="">
                    <div className="row align-items-center">
                      <div className="col-md-12 mt-2 d-flex flex-column ">
                        <b className="inputLabel">
                          What MHFA training program(s) are you interested in ?
                        </b>
                        {trainingTypeList.map((item, index) => (
                          <div className="d-flex ">
                            <label>
                              <Checkbox
                                inputProps={{
                                  'aria-label': 'controlled',
                                }}
                                name="intrestedTraining"
                                id="intrestedTraining"
                                onClick={(e) => {
                                  let arr = intrestedTraining
                                  if (e.target.checked) {
                                    arr.push(item.value)
                                  } else {
                                    arr = intrestedTraining.filter(
                                      (m) => m !== item.value
                                    )
                                  }
                                  setIntrestedTraining(arr)
                                  setFieldValue('intrestedTraining', arr)
                                }}
                              />
                              {item.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12 mb-3 flex-column d-flex ">
                        <TextField
                          id="maxTraining"
                          type="number"
                          name="maxTraining"
                          placeholder="How many MHFA Instructor trainings do you plan to host each year? *"
                          value={values.maxTraining}
                          label="How many MHFA Instructor trainings do you plan to host each year? *"
                          error={errors.maxTraining}
                          touched={touched.maxTraining}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          maxlength={2}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6 mb-3 flex-column d-flex ">
                        <TextField
                          id="firstName"
                          name="firstName"
                          placeholder="First Name"
                          value={values.firstName}
                          label="First Name *"
                          error={errors.firstName}
                          touched={touched.firstName}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6 mb-3 flex-column d-flex">
                        <TextField
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          value={values.lastName}
                          label="Last Name *"
                          error={errors.lastName}
                          touched={touched.lastName}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 mb-3 flex-column d-flex ">
                        <TextField
                          name="email"
                          placeholder="Email"
                          value={values.email?.trim()?.toLowerCase()}
                          label="Email address *"
                          error={errors.email}
                          touched={touched.email}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          disabled={loggedEmail ? true : false}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 mb-3 flex-column d-flex ">
                        <TextField
                          id="phoneNumber"
                          name="phoneNumber"
                          placeholder="Phone Number"
                          value={values.phoneNumber}
                          label="Phone Number *"
                          error={errors.phoneNumber}
                          touched={touched.phoneNumber}
                          handleBlur={handleBlur}
                          maxlength={10}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3 flex-column d-flex ">
                        <TextField
                          name="jobTitle"
                          placeholder="Job Title *"
                          label="Job Title *"
                          value={values.jobTitle}
                          error={errors.jobTitle}
                          touched={touched.jobTitle}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6 mb-3 flex-column d-flex ">
                        <TextField
                          name="organization"
                          placeholder="Organization"
                          label="Organization *"
                          value={values.organization}
                          error={errors.organization}
                          touched={touched.organization}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 flex-column d-flex">
                        <TextField
                          name="addressline1"
                          placeholder="Address *"
                          label="Address *"
                          value={values.addressline1}
                          error={errors.addressline1}
                          touched={touched.addressline1}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3 flex-column d-flex ">
                        <TextField
                          name="city"
                          placeholder="City"
                          label="City"
                          value={values.city}
                          error={errors.city}
                          touched={touched.city}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6 mb-3 flex-column d-flex ">
                        <ReactSelectField
                          label="State"
                          placeholder="State"
                          inputId="state"
                          mandatory={1}
                          search={false}
                          error={errors.state}
                          touched={touched.state}
                          value={values.state}
                          options={usStatesList}
                          onChange={(text) => {
                            setFieldValue('state', text)
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-3 flex-column d-flex ">
                        <TextField
                          name="zipcode"
                          placeholder="Zip Code"
                          label="Zip Code"
                          value={values.zipcode}
                          error={errors.zipcode}
                          touched={touched.zipcode}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-md-6 mb-3  d-flex flex-column ">
                        <b className="inputLabel">
                          Are you a National Council member?
                        </b>
                        <div className="d-flex  ">
                          <RadioButton
                            id="yes"
                            name="isNationalCouncilMember"
                            value={values.isNationalCouncilMember}
                            setFieldValue={setFieldValue}
                            checkBoxLabel="Yes"
                            val="Yes"
                          />
                          <RadioButton
                            name="isNationalCouncilMember"
                            id="no"
                            value={values.isNationalCouncilMember}
                            setFieldValue={setFieldValue}
                            checkBoxLabel="No"
                            val="No"
                          />
                          <RadioButton
                            name="isNationalCouncilMember"
                            id="notSure"
                            value={values.isNationalCouncilMember}
                            setFieldValue={setFieldValue}
                            checkBoxLabel="Not Sure"
                            val="Not Sure"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3  d-flex flex-column ">
                        <b className="inputLabel">
                          Do you currently have any training funding available?
                          *
                        </b>
                        <div className="d-flex  ">
                          <RadioButton
                            id="fundingYes"
                            name="haveFunding"
                            value={values.haveFunding}
                            setFieldValue={setFieldValue}
                            checkBoxLabel="Yes"
                            val="Yes"
                          />
                          <RadioButton
                            name="haveFunding"
                            id="fundingNo"
                            value={values.haveFunding}
                            setFieldValue={setFieldValue}
                            checkBoxLabel="No"
                            val="No"
                          />
                          <RadioButton
                            name="haveFunding"
                            id="fundingNotSure"
                            value={values.haveFunding}
                            setFieldValue={setFieldValue}
                            checkBoxLabel="Not Sure"
                            val="Not Sure"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <p>Allow view Trainings?</p>
                      <div className="d-flex">
                        <RadioButton
                          name="canView"
                          id="canViewYes"
                          value={values.canView}
                          setFieldValue={setFieldValue}
                          checkBoxLabel={'Yes'}
                          val={true}
                        />
                        <RadioButton
                          name="canView"
                          id="canViewNo"
                          value={values.canView}
                          setFieldValue={setFieldValue}
                          checkBoxLabel={'No'}
                          val={false}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 mt-2">
                            <Button
                              className="button-base-instance-grey"
                              type="button"
                              onClick={(e) => handleClose()}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="col-md-6 mt-2">
                            <Button type="submit">
                              {loading ? (
                                <Spinner
                                  size="20px"
                                  sx={{
                                    '.MuiCircularProgress-root': {
                                      color: 'white',
                                    },
                                  }}
                                  thickness={5}
                                />
                              ) : (
                                'Save'
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default memo(NewRequestForm)
