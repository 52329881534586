import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Chip } from '../../components/Chip'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { TIME_ZONE, usStatesList } from '../../utils/common'
import Header from '../../components/Header/Header'
import './AdminEditTrainingFormModule.css'

import {
  Update_Training,
  GET_COURSE,
  GET_TRAINING_BY_ID,
} from '../../api/graphql'

import Button from '../../components/Button/Button'
import TextField from '../../components/TextField/TextField'

import * as Yup from 'yup'
import { Formik } from 'formik'
import { DateCustomInput, trainingStatusList } from '../../utils/common'

import Spinner from '../../components/Spinner/Spinner'
import { useMutation, useQuery } from '@apollo/client'
import NavBar from '../../components/Navbar/Navbar'
import ReactSelectField from '../../components/ReactSelectField/ReactSelectField'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import DateField from '../../components/DatePickerField/DatePickerField'
import { toast } from 'react-toastify'
import { TimePicker } from '@mui/lab'
import TimeField from '../../components/TimeField/TimeField'
import RadioButton from '../../components/RadioButton/RadioButton'

const AdminTrainingForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const formikRef = useRef()
  const userType = localStorage.getItem('userType')
  console.log('userType---', userType)
  const currentDate = new Date()
  const [trainingDetails, setTrainingDetails] = useState(null)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { refetch } = useQuery(GET_COURSE)
  const { loading: loadingTraining, refetch: refetchTraining } = useQuery(
    GET_TRAINING_BY_ID,
    {
      variables: {
        trainingId: location.state.id,
      },
    }
  )
  const [UPDATE_TRAINING, { loading }] = useMutation(Update_Training)

  const [courseList, setCourseList] = useState([])

  const FormSchema = Yup.object().shape({
    trainingType: Yup.object(),
    start_date: Yup.date().required('Start Date is required'),
    end_date: Yup.date()
      .required('End Date is required')
      .when('start_date', (start_date, schema) => {
        return schema.min(start_date, 'End Date must be after Start Date')
      }),
    maxLearner: Yup.number().required('Max # Learner is required'),
    courseId: Yup.number().required('Course Id is required'),
    // timezone: Yup.string(),
    // address: Yup.string(),
    // state: Yup.string().notRequired(),
    // city: Yup.string(),
    // zipcode: Yup.number(),
    // location: Yup.string(),
    // notes: Yup.string(),
  })

  const handleRequestForm = async (values) => {
    try {
      const { data } = await UPDATE_TRAINING({
        variables: {
          status: values.status.value,
          courseId: parseInt(values.courseId),
          trainingId: location.state.id,
          maxLearner: parseInt(values.maxLearner),
          startDate: values.start_date,
          endDate: values.end_date,
          startTime: values.start_time,
          timeZone: values.timezone.value,
          endTime: values.end_time,
          address: values.address,
          state: values.state.label,
          city: values.city,
          zipcode: values.zipcode,
          location: values.location,
          notes: values.notes,
          permissions: values.canView
            ? [
                'training.view_learners',
                'training.invite',
                'training.copy_invite',
                'training.resend_invite',
              ]
            : [],
        },
      })
      if (!data.updateTraining.success) {
        toast.error(data.updateTraining.error)
        return
      }
      navigate(
        userType === 'admin'
          ? `/admin/request/view-training`
          : '/request/view-training',
        {
          state: { id: location.state.id, requestId: location.state.requestId },
        }
      )
    } catch (error) {}
  }

  function setStatusField(status) {
    if (formikRef.current) {
      formikRef.current.setFieldValue('status', status)
    }
  }

  const setFormFieldValues = (values) => {
    console.log('SETTING FORM FIELD VALUES', values)
    if (formikRef.current) {
      formikRef.current.setFieldValue('trainingType', {
        label: values?.training_type,
        value: values?.training_type,
      })
      formikRef.current.setFieldValue('status', {
        label: values?.status,
        value: values?.status?.toLowerCase(),
      })
      // formikRef.current.setFieldValue("date", new Date(values?.training_date));
      formikRef.current.setFieldValue(
        'start_date',
        new Date(values?.start_date)
      )

      formikRef.current.setFieldValue('timezone', {
        label: values?.time_zone,
        value: values?.time_zone,
      })

      formikRef.current.setFieldValue('state', {
        label: values?.state,
        value: values?.state,
      })

      formikRef.current.setFieldValue('end_date', new Date(values?.end_date))
      formikRef.current.setFieldValue('start_time', values?.start_time)
      formikRef.current.setFieldValue('end_time', values?.end_time)
      formikRef.current.setFieldValue('maxLearner', values?.max_learner)
      formikRef.current.setFieldValue('courseId', values?.lms_course_id)
      formikRef.current.setFieldValue('address', values?.address)
      formikRef.current.setFieldValue('city', values?.city)
      formikRef.current.setFieldValue('zipcode', values?.zipcode)
      formikRef.current.setFieldValue('location', values?.location)
      formikRef.current.setFieldValue('notes', values?.notes)
      console.log('FORMIK REF', formikRef.current)
    }
  }

  const getList = async () => {
    if (!location.state || !location.state.id) {
      navigate(
        userType === 'admin' ? '/admin/request' : '/request/request-list'
      )
      return
    }
    // console.log("------------", location.state);
    let { data: data1 } = await refetchTraining({
      trainingId: location.state.id,
    })
    setTrainingDetails(data1.GetTrainingDetails.data)
    console.log('TRAINING DETAILS SET TO', data1.GetTrainingDetails.data)
    // setFormFieldValues(data1.GetTrainingDetails.data)
    let courseArr = [
      {
        label: 'Adult',
        value: 'Adult',
      },
      {
        label: 'Youth',
        value: 'Youth',
      },
    ]
    setCourseList(courseArr)
  }

  useEffect(() => {
    getList()
  }, [])
  return (
    <>
      <div className="row p-0 no-gutters nav-area">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0  no-gutters">
            <div className="col-2">
              <NavBar user={userType} />
            </div>
            <div className="col-10 row admin-request">
              <div className="mb-2 p-0">
                <a
                  href="javascript:void(0)"
                  onClick={(e) =>
                    navigate(
                      userType === 'admin'
                        ? `/admin/request/view-training`
                        : '/request/view-training',
                      {
                        state: {
                          id: location.state.id,
                          requestId: location.state.requestId,
                        },
                      }
                    )
                  }
                  className="back-link"
                >
                  <ChevronLeftIcon />
                  Back
                </a>
              </div>
              <div className="col-lg-6 regBox p-3">
                {loadingTraining ? (
                  <>
                    <Spinner />
                  </>
                ) : (
                  trainingDetails && (
                    <Formik
                      innerRef={formikRef}
                      enableReinitialize={true}
                      initialValues={{
                        status: {
                          label:
                            trainingDetails?.status.charAt(0).toUpperCase() +
                            trainingDetails?.status.slice(1),
                          value: trainingDetails?.status?.toLowerCase(),
                        },
                        trainingType: {
                          label: trainingDetails.training_type,
                          value: trainingDetails.training_type,
                        },
                        start_date: trainingDetails.start_date
                          ? new Date(trainingDetails.start_date)
                          : '',
                        end_date: trainingDetails.end_date
                          ? new Date(trainingDetails.end_date)
                          : '',
                        start_time: trainingDetails.start_time,
                        end_time: trainingDetails.end_time,
                        timezone: {
                          label: trainingDetails.time_zone,
                          value: trainingDetails.time_zone,
                        },
                        maxLearner: trainingDetails.max_learner,
                        courseId: trainingDetails.lms_course_id,
                        address: trainingDetails.address,
                        city: trainingDetails.city,
                        state: {
                          label: trainingDetails.state,
                          value: trainingDetails.state,
                        },
                        zipcode: trainingDetails.zipcode,
                        location: trainingDetails.location,
                        notes: trainingDetails.notes,
                        canView:
                          trainingDetails.permissions &&
                          trainingDetails.permissions.length > 0
                            ? true
                            : false,
                      }}
                      validationSchema={FormSchema}
                      onSubmit={handleRequestForm}
                    >
                      {({
                        errors,
                        values,
                        touched,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                      }) => {
                        return (
                          <form onSubmit={handleSubmit} autoComplete="off">
                            <div className="row p-5">
                              <div className="requestTitle">
                                Training Details
                              </div>
                              <div className="pt-3 row">
                                <div className="col-md-2">Status</div>
                                <div className="col-md-12">
                                  <ReactSelectField
                                    inputId="status"
                                    mandatory={1}
                                    clear={false}
                                    search={false}
                                    error={errors.status}
                                    touched={touched.status}
                                    options={trainingStatusList}
                                    value={values.status}
                                    onChange={(text) => {
                                      setFieldValue('status', text)
                                    }}
                                  />
                                  {/* <span
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  userType === 'admin' &&
                                    setIsDropdownOpen(!isDropdownOpen)
                                }}
                              >
                                <Chip
                                  className="chip-instance"
                                  divClassName="chip-2"
                                  status={values.status.value.toLowerCase()}
                                  text={values.status.label}
                                />{' '}
                                {userType === 'admin' && (
                                  <KeyboardArrowDownIcon />
                                )}
                              </span>
                              {isDropdownOpen && (
                                <div className="">
                                  <ul
                                    style={{
                                      listStyle: 'none',
                                      padding: '5px',
                                      border: '1px solid #ccc',
                                      backgroundColor: '#fff',
                                      position: 'absolute',
                                      zIndex: 200,
                                    }}
                                  >
                                    {trainingStatusList.map((item, index) => (
                                      <li
                                        key={`status-points-key-${index}`}
                                        className="status-style"
                                        onClick={(e) => {
                                          console.log(
                                            'CURRENT STATUS',
                                            values.status
                                          )
                                          console.log(
                                            'SETTING FIELD VALUE',
                                            item
                                          )

                                          setStatusField(item)
                                          values.status = item
                                          console.log(
                                            'NEW STATUS',
                                            values.status
                                          )

                                          setIsDropdownOpen(false)
                                        }}
                                      >
                                        {item.label}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )} */}
                                </div>
                              </div>
                              <div className="pt-3">
                                <div className="row">
                                  <ReactSelectField
                                    label="Training Type *"
                                    placeholder="Training Type"
                                    disabled
                                    inputId="trainingType"
                                    mandatory={1}
                                    search={false}
                                    error={errors.trainingType}
                                    touched={touched.trainingType}
                                    value={values.trainingType}
                                    options={courseList}
                                    onChange={(text) => {
                                      setFieldValue('trainingType', text)
                                    }}
                                  />
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <DateField
                                      label="Start Date *"
                                      inputId="start_date"
                                      error={errors.start_date}
                                      touched={touched.start_date}
                                      // maxDate={new Date()}
                                      // minDate={
                                      //   new Date(moment().subtract(3, "month"))
                                      // }
                                      selected={values.start_date}
                                      customInput={
                                        <DateCustomInput
                                          id="start_date"
                                          touchId="start_date"
                                          setFieldTouched={setFieldTouched}
                                        />
                                      }
                                      onChange={(date) => {
                                        setFieldValue('start_date', date)
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-6">
                                    <DateField
                                      label="End Date *"
                                      inputId="end_date"
                                      error={errors.end_date}
                                      touched={touched.end_date}
                                      // maxDate={new Date()}
                                      // minDate={
                                      //   new Date(moment().subtract(3, "month"))
                                      // }
                                      selected={values.end_date}
                                      customInput={
                                        <DateCustomInput
                                          id="end_date"
                                          touchId="end_date"
                                          setFieldTouched={setFieldTouched}
                                        />
                                      }
                                      onChange={(date) => {
                                        setFieldValue('end_date', date)
                                      }}
                                    />
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <TimeField
                                        type="time"
                                        name="start_time"
                                        label="Start Time"
                                        value={values.start_time}
                                        error={errors.start_time}
                                        touched={touched.start_time}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                    <div className="col-lg-6">
                                      <TimeField
                                        type="time"
                                        name="end_time"
                                        label="End Time"
                                        value={values.end_time}
                                        error={errors.end_time}
                                        touched={touched.end_time}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <ReactSelectField
                                    label="Timezone"
                                    placeholder="Timezone"
                                    inputId="timezone"
                                    mandatory={1}
                                    search={false}
                                    error={errors.timezone}
                                    touched={touched.timezone}
                                    value={values.timezone}
                                    options={TIME_ZONE}
                                    clear={false}
                                    onChange={(text) => {
                                      setFieldValue('timezone', text)
                                    }}
                                  />
                                </div>
                                <div className="row">
                                  <TextField
                                    name="maxLearner"
                                    placeholder="Max"
                                    value={values.maxLearner}
                                    label="Max # of Learners *"
                                    maxlength={2}
                                    error={errors.maxLearner}
                                    touched={touched.maxLearner}
                                    handleBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="row">
                                  <TextField
                                    id="courseId"
                                    name="courseId"
                                    placeholder="Course Id"
                                    value={values.courseId}
                                    label="Course Id *"
                                    error={errors.courseId}
                                    touched={touched.courseId}
                                    handleBlur={handleBlur}
                                    maxlength={10}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="row">
                                  <TextField
                                    id="address"
                                    name="address"
                                    placeholder="Address"
                                    value={values.address}
                                    label="Address"
                                    error={errors.address}
                                    touched={touched.address}
                                    handleBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="row">
                                  <TextField
                                    id="city"
                                    name="city"
                                    placeholder="City"
                                    value={values.city}
                                    label="City"
                                    error={errors.city}
                                    touched={touched.city}
                                    handleBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="row">
                                      <ReactSelectField
                                        inputId="state"
                                        mandatory={1}
                                        search={false}
                                        error={errors.state}
                                        touched={touched.state}
                                        options={usStatesList}
                                        onChange={(text) => {
                                          setFieldValue('state', text)
                                        }}
                                        name="state"
                                        value={values.state}
                                        label="State"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-1"></div>
                                  <div className="col-lg-5">
                                    <div className="row">
                                      <TextField
                                        id="zipcode"
                                        name="zipcode"
                                        placeholder="Zipcode"
                                        value={values.zipcode}
                                        label="Zipcode"
                                        error={errors.zipcode}
                                        touched={touched.zipcode}
                                        handleBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <TextField
                                    id="location"
                                    name="location"
                                    placeholder="Location"
                                    value={values.location}
                                    label="Location"
                                    error={errors.location}
                                    touched={touched.location}
                                    handleBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="row">
                                  <TextField
                                    id="notes"
                                    name="notes"
                                    placeholder="Notes"
                                    value={values.notes}
                                    label="Notes"
                                    error={errors.notes}
                                    touched={touched.notes}
                                    handleBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="row mt-3">
                                  <p>Allow invite Learners?</p>
                                  <div className="d-flex">
                                    <RadioButton
                                      name="canView"
                                      id="canViewYes"
                                      value={values.canView}
                                      setFieldValue={setFieldValue}
                                      checkBoxLabel={'Yes'}
                                      val={true}
                                    />
                                    <RadioButton
                                      name="canView"
                                      id="canViewNo"
                                      value={values.canView}
                                      setFieldValue={setFieldValue}
                                      checkBoxLabel={'No'}
                                      val={false}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-5">
                                  <div className="col-md-6"></div>
                                  <div className="col-md-6">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <Button
                                          className="button-base-instance-grey"
                                          type="button"
                                          onClick={(e) => window.history.back()}
                                        >
                                          Cancel
                                        </Button>
                                      </div>
                                      <div className="col-md-6">
                                        <Button type="submit">
                                          {loading ? (
                                            <Spinner
                                              size="20px"
                                              sx={{
                                                '.MuiCircularProgress-root': {
                                                  color: 'white',
                                                },
                                              }}
                                              thickness={5}
                                            />
                                          ) : (
                                            'Save'
                                          )}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        )
                      }}
                    </Formik>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminTrainingForm
