import React from 'react'
import styles from './TimeField.module.css'

const TimeField = ({
  name,
  type = 'text',
  placeholder,
  value,
  label,
  disabled = false,
  error,
  autoFocus = false,
  onChange,
  handleBlur,
  touched,
  ...otherProps
}) => {
  return (
    <div className={`row no-gutters`}>
      {label && <b className={styles.inputLabel}>{label}</b>}
      <input
        name={name}
        type={type}
        className={`${styles.inputWrapper}  ${
          error && touched && 'focusInputError'
        }`}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        autoFocus={autoFocus}
        onBlur={handleBlur}
        style={{ backgroundColor: disabled && '#E7E0D3' }}
        {...otherProps}
      />
      {error && touched && <p className={styles.errorMessage}>{error}</p>}
    </div>
  )
}

export default TimeField
