import * as React from 'react'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Stack from '@mui/material/Stack'
import { IconButton } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { checkUserPermissions } from '../../utils/UserUtils'

export default function MenuListComposition({
  handleDeleteLearner,
  handleSendAgain,
  handleEditLearner,
  copyInviteLink,
  additionalPermissions = [],
}) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <IconButton
          ref={anchorRef}
          aria-label="more"
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVert />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          style={{ zIndex: 999 }}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {checkUserPermissions(
                      'training.resend_invite',
                      additionalPermissions
                    ) && (
                      <MenuItem
                        onClick={() => {
                          handleSendAgain()
                          setOpen(false)
                        }}
                      >
                        Resend Invite
                      </MenuItem>
                    )}
                    {checkUserPermissions(
                      'training.edit_learner',
                      additionalPermissions
                    ) && (
                      <MenuItem
                        onClick={() => {
                          handleEditLearner()
                          setOpen(false)
                        }}
                      >
                        Edit
                      </MenuItem>
                    )}
                    {checkUserPermissions(
                      'training.delete_learner',
                      additionalPermissions
                    ) && (
                      <MenuItem
                        onClick={() => {
                          handleDeleteLearner()
                          setOpen(false)
                        }}
                      >
                        Remove
                      </MenuItem>
                    )}
                    {checkUserPermissions(
                      'training.copy_invite',
                      additionalPermissions
                    ) && (
                      <MenuItem
                        onClick={() => {
                          copyInviteLink()
                          setOpen(false)
                        }}
                      >
                        Copy Invite Link
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  )
}
