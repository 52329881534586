import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Header from '../../components/Header/Header'
import './AdminTrainingFormModule.css'

import { Create_Training, GET_COURSE } from '../../api/graphql'

import Button from '../../components/Button/Button'
import TextField from '../../components/TextField/TextField'

import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  DateCustomInput,
  trainingCourse,
  usStatesList,
} from '../../utils/common'

import Spinner from '../../components/Spinner/Spinner'
import { useMutation, useQuery } from '@apollo/client'
import NavBar from '../../components/Navbar/Navbar'
import ReactSelectField from '../../components/ReactSelectField/ReactSelectField'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import DateField from '../../components/DatePickerField/DatePickerField'
import { toast } from 'react-toastify'
import { TIME_ZONE } from '../../utils/common'
import TimeField from '../../components/TimeField/TimeField'
import RadioButton from '../../components/RadioButton/RadioButton'

const AdminTrainingForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [CREATE_TRAINING, { loading }] = useMutation(Create_Training)
  const userType = localStorage.getItem('userType')
  console.log('userType---', userType)
  const currentDate = new Date()

  const FormSchema = Yup.object().shape({
    trainingType: Yup.object().required('Training Type is required'),
    start_date: Yup.date().required('Start Date is required'),
    end_date: Yup.date()
      .required('End Date is required')
      .when('start_date', (start_date, schema) => {
        return schema.min(start_date, 'End Date must be after Start Date')
      }),
    maxLearner: Yup.number().required('Max # Learner is required'),
    courseId: Yup.number().required('Course Id is required'),
    // start_time: Yup.string(),
    // end_time: Yup.string(),
    // timezone: Yup.string(),
    // address: Yup.string(),
    // state: Yup.string().notRequired(),
    // city: Yup.string(),
    // zipcode: Yup.number(),
    // location: Yup.string(),
    // notes: Yup.string(),
  })

  const handleRequestForm = async (values) => {
    try {
      const { data } = await CREATE_TRAINING({
        variables: {
          courseId: parseInt(values.courseId),
          // trainingId: location.state.id,
          trainingType: values.trainingType.label,
          trainingCourseId: values.trainingType.value,
          requestId: location.state.id,
          maxLearner: parseInt(values.maxLearner),
          startDate: values.start_date,
          endDate: values.end_date,
          startTime: values.start_time,
          endTime: values.end_time,
          timeZone: values.timezone.value,
          address: values.address,
          state: values.state?.label,
          city: values.city,
          zipcode: values.zipcode,
          location: values.location,
          notes: values.notes,
          permissions: values.canView
            ? [
                'training.view_learners',
                'training.invite',
                'training.copy_invite',
                'training.resend_invite',
              ]
            : [],
        },
      })
      if (!data.createTraining.success) {
        // toast.error(data.createTraining.error)
        return
      }
      navigate(
        userType === 'admin'
          ? `/admin/request/view-request`
          : '/request/view-request',
        {
          state: { id: location.state.id },
        }
      )
    } catch (error) {}
  }

  const getList = async () => {
    if (!location.state || !location.state.id) {
      navigate(
        userType === 'admin' ? '/admin/request' : '/request/request-list'
      )
    }
  }

  useEffect(() => {
    getList()
  }, [])
  return (
    <>
      <div className="row p-0 no-gutters nav-area">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0  no-gutters">
            <div className="col-2">
              <NavBar user={userType} />
            </div>
            <div className="col-10 row admin-request">
              <div className="mb-2 p-0">
                <a
                  href="javascript:void(0)"
                  onClick={(e) =>
                    navigate(
                      userType === 'admin'
                        ? `/admin/request/view-request`
                        : '/request/view-request',
                      {
                        state: { id: location.state.id },
                      }
                    )
                  }
                  className="back-link"
                >
                  <ChevronLeftIcon />
                  Back
                </a>
              </div>
              <div className="col-lg-6 regBox p-3">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    trainingType: '',
                    start_date: currentDate,
                    end_date: currentDate,
                    start_time: '',
                    end_time: '',
                    timezone: '',
                    maxLearner: '',
                    courseId: '',
                    address: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    location: '',
                    notes: '',
                    canView: false,
                  }}
                  validationSchema={FormSchema}
                  onSubmit={handleRequestForm}
                >
                  {({
                    errors,
                    values,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row p-5">
                          <div className="requestTitle">Training Details</div>
                          <div className="pt-3">
                            <div className="row">
                              <ReactSelectField
                                label="Training Type *"
                                placeholder="Training Type"
                                inputId="trainingType"
                                mandatory={1}
                                search={false}
                                error={errors.trainingType}
                                touched={touched.trainingType}
                                value={values.trainingType}
                                options={trainingCourse}
                                onChange={(text) => {
                                  setFieldValue('trainingType', text)
                                }}
                              />
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <DateField
                                  label="Start Date *"
                                  inputId="start_date"
                                  error={errors.start_date}
                                  touched={touched.start_date}
                                  // maxDate={new Date()}
                                  // minDate={
                                  //   new Date(moment().subtract(3, "month"))
                                  // }
                                  selected={values.start_date}
                                  customInput={
                                    <DateCustomInput
                                      id="start_date"
                                      touchId="start_date"
                                      setFieldTouched={setFieldTouched}
                                    />
                                  }
                                  onChange={(date) => {
                                    setFieldValue('start_date', date)
                                  }}
                                />
                              </div>
                              <div className="col-lg-6">
                                <DateField
                                  label="End Date *"
                                  inputId="end_date"
                                  error={errors.end_date}
                                  touched={touched.end_date}
                                  // maxDate={new Date()}
                                  // minDate={
                                  //   new Date(moment().subtract(3, "month"))
                                  // }
                                  selected={values.end_date}
                                  customInput={
                                    <DateCustomInput
                                      id="end_date"
                                      touchId="end_date"
                                      setFieldTouched={setFieldTouched}
                                    />
                                  }
                                  onChange={(date) => {
                                    setFieldValue('end_date', date)
                                  }}
                                />
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <TimeField
                                    type="time"
                                    name="start_time"
                                    label="Start Time"
                                    value={values.start_time}
                                    error={errors.start_time}
                                    touched={touched.start_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <TimeField
                                    type="time"
                                    name="end_time"
                                    label="End Time"
                                    value={values.end_time}
                                    error={errors.end_time}
                                    touched={touched.end_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <ReactSelectField
                                label="Timezone"
                                placeholder="Timezone"
                                inputId="timezone"
                                mandatory={1}
                                search={false}
                                error={errors.timezone}
                                touched={touched.timezone}
                                value={values.timezone}
                                options={TIME_ZONE}
                                onChange={(text) => {
                                  setFieldValue('timezone', text)
                                }}
                              />
                            </div>
                            <div className="row">
                              <TextField
                                name="maxLearner"
                                placeholder="Max"
                                value={values.maxLearner}
                                label="Max # of Learners *"
                                maxlength={2}
                                error={errors.maxLearner}
                                touched={touched.maxLearner}
                                handleBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="row">
                              <TextField
                                id="courseId"
                                name="courseId"
                                placeholder="Course Id"
                                value={values.courseId}
                                label="Course Id *"
                                error={errors.courseId}
                                touched={touched.courseId}
                                handleBlur={handleBlur}
                                maxlength={10}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="row">
                              <TextField
                                id="address"
                                name="address"
                                placeholder="Address"
                                value={values.address}
                                label="Address"
                                error={errors.address}
                                touched={touched.address}
                                handleBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="row">
                              <TextField
                                id="city"
                                name="city"
                                placeholder="City"
                                value={values.city}
                                label="City"
                                error={errors.city}
                                touched={touched.city}
                                handleBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="row">
                                  <ReactSelectField
                                    inputId="state"
                                    mandatory={1}
                                    search={false}
                                    error={errors.state}
                                    touched={touched.state}
                                    options={usStatesList}
                                    onChange={(text) => {
                                      setFieldValue('state', text)
                                    }}
                                    name="state"
                                    value={values.state}
                                    label="State"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-1"></div>
                              <div className="col-lg-5">
                                <div className="row">
                                  <TextField
                                    id="zipcode"
                                    name="zipcode"
                                    placeholder="Zipcode"
                                    value={values.zipcode}
                                    label="Zipcode"
                                    error={errors.zipcode}
                                    touched={touched.zipcode}
                                    handleBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <TextField
                                id="location"
                                name="location"
                                placeholder="Location"
                                value={values.location}
                                label="Location"
                                error={errors.location}
                                touched={touched.location}
                                handleBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="row">
                              <TextField
                                id="notes"
                                name="notes"
                                placeholder="Notes"
                                value={values.notes}
                                label="Notes"
                                error={errors.notes}
                                touched={touched.notes}
                                handleBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="row mt-3">
                              <p>Allow invite Learners?</p>
                              <div className="d-flex">
                                <RadioButton
                                  name="canView"
                                  id="canViewYes"
                                  value={values.canView}
                                  setFieldValue={setFieldValue}
                                  checkBoxLabel={'Yes'}
                                  val={true}
                                />
                                <RadioButton
                                  name="canView"
                                  id="canViewNo"
                                  value={values.canView}
                                  setFieldValue={setFieldValue}
                                  checkBoxLabel={'No'}
                                  val={false}
                                />
                              </div>
                            </div>

                            <div className="row mt-5">
                              <div className="col-md-6"></div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-6 mt-2">
                                    <Button
                                      className="button-base-instance-grey"
                                      type="button"
                                      onClick={(e) => window.history.back()}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                  <div className="col-md-6 mt-2">
                                    <Button type="submit">
                                      {loading ? (
                                        <Spinner
                                          size="20px"
                                          sx={{
                                            '.MuiCircularProgress-root': {
                                              color: 'white',
                                            },
                                          }}
                                          thickness={5}
                                        />
                                      ) : (
                                        'Save'
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminTrainingForm
