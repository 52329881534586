import { useNavigate } from 'react-router'
import { useUser } from './AuthState'

const useAuth = () => {
  const navigate = useNavigate()
  const { dispatch } = useUser()

  const logOut = () => {
    const userType = localStorage.getItem('userType')
    dispatch({ type: 'LOGOUT' })
    localStorage.clear()
    if (userType === 'admin') navigate('/admin')
    else if (userType === 'request') navigate('/request')
    else navigate('/')
  }
  return {
    logOut,
  }
}

export default useAuth
