import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import { INVITED_USER_RESULT } from "../../api/graphql";
import { useQuery } from "@apollo/client";
import MessageCard from "../../components/MessageCard/MessageCard";
import Button from "../../components/Button/Button";
import { useUser } from "../../auth/AuthState";
import Layout from "../../layouts/Layout";

const LearnerAssessment = () => {
  const [application, setApplication] = useState([]);
  const email = localStorage.getItem("emailKey");
  const invitationId = localStorage.getItem("invitationId");
  const [status, setStatus] = useState("");
  const [courseName, setCourseName] = useState("");
  const {
    userState: { user },
  } = useUser();
  const { loading, refetch } = useQuery(INVITED_USER_RESULT, {
    variables: { invitationId: +invitationId, userId: user?.id },
  });

  useEffect(() => {
    getAssestment();
  }, []);

  const getAssestment = async () => {
    const invitationData = await refetch({ invitationId: +invitationId });
    setStatus(invitationData?.data?.GetInvitedUserResult?.data?.status);
  };

  return (
    <Layout title={"CANDIDATE APPLICATION"}>
      <div className="row mt-5">
        <div className="row mt-2 no-gutter d-flex flex-column">
          {loading ? (
            <Spinner sx={{ marginTop: 4 }} />
          ) : (
            <>
              <div className="col mt-5 d-flex justify-content-center flex-column align-items-center">
                <p className="instructorTitle mt-5"></p>

                <>
                  <MessageCard
                    showLine={true}
                    messageData={
                      status?.toLowerCase() !== "failed"
                        ? `You have already passed the ${courseName} Assessment`
                        : `You have failed the ${courseName} Assessment, Try after 90 DAYS`
                    }
                  >
                    <Button
                      text="Thank You"
                      style={{
                        marginTop: "2rem",
                        marginBottom: "1rem",
                      }}
                    />
                  </MessageCard>
                </>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LearnerAssessment;
