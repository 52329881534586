import { useQuery } from '@apollo/client'
import Header from '../../components/Header/Header'
import NavBar from '../../components/Navbar/Navbar'
import Table from '../../components/Table/Table'
import { useLocation, useNavigate } from 'react-router-dom'

import './AdminReports.module.css'
import { GET_REPORT } from '../../api/graphql'
import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import DateField from '../../components/DatePickerField/DatePickerField'
import { DateCustomInput } from '../../utils/common'
import Spinner from '../../components/Spinner/Spinner'
import Button from '../../components/Button/Button'
import { Download } from '@mui/icons-material'
import ReactSelectField from '../../components/ReactSelectField/ReactSelectField'

const tableColumns = [
  {
    header: 'ID',
    path: 'id',
  },
  {
    header: 'Email',
    path: 'user.email',
  },
  {
    header: 'First Name',
    path: 'user.first_name',
  },
  {
    header: 'Last Name',
    path: 'user.last_name',
  },
  {
    header: 'Type',
    path: 'course_id',
    // FIXME: Temporary Fix For Now
    content: (item) => (
      <>{parseInt(item?.course_id) === 1 ? 'Adult' : 'Youth'}</>
    ),
  },
  {
    header: 'Date',
    path: 'start_date',
    content: (item) => (
      <>{new Date(item?.created_at).toLocaleString('en-US')}</>
    ),
  },
  {
    header: 'Score',
    path: 'score',
    content: (item) => (
      <>
        {item?.score && (
          <>{(item?.score?.score?.final_score * 100).toFixed(2)}%</>
        )}
      </>
    ),
  },
  {
    header: 'Pre-Requisite Status',
    path: 'prerequisite_status',
  },
]

export default function AdminReports() {
  const [start_date, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  )
  const [end_date, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate()))
  )
  const [previous_start_date, setPreviousStartDate] = useState(start_date)
  const [previous_end_date, setPreviousEndDate] = useState(end_date)

  const [loading, setLoading] = useState(false)

  const { loading: reportLoading, refetch } = useQuery(GET_REPORT, {
    variables: {
      startDate: start_date,
      endDate: end_date,
      timeOffSet: new Date().getTimezoneOffset() * -1,
    },
  })

  const location = useLocation()
  const navigate = useNavigate()
  const userType = localStorage.getItem('userType')
  const [reports, setReports] = useState([])
  const [courseFilter, setCourseFilter] = useState({ label: 'All', value: '' })

  async function getReport() {
    await setLoading(true)
    setPreviousEndDate(end_date)
    setPreviousStartDate(start_date)
    const { data } = await refetch({
      startDate: start_date,
      endDate: end_date,
      timeOffSet: new Date().getTimezoneOffset() * -1,
      //   status: status.value,
    })
    await setReports(data?.GetAssessmentProgressReport?.data)
    await setLoading(false)
  }

  function downloadReport() {
    // download report as CSV
    // find the max number of questions
    const maxQuestions = Math.max(
      ...reports.map((item) => item?.answers?.length)
    )
    const headers = tableColumns.map((item) => item.header)
    // add each question as Q1, Q2, Q3, ... in the headers
    for (let i = 0; i < maxQuestions; i++) {
      headers.push(`Q${i + 1}`)
    }
    const simplifiedReports = reports.map((item) => {
      // remove all null/undefined values from item.answers
      const answers = item.answers?.filter((el) => el !== null)
      return {
        id: item.id,
        email: item.user?.email,
        first_name: item.user?.first_name,
        last_name: item.user?.last_name,
        course_id: parseInt(item.course_id) === 1 ? 'Adult' : 'Youth',
        start_date: new Date(item?.created_at)
          .toLocaleString('en-US')
          .replace(',', ' '),
        score: item.score?.score?.final_score,
        prerequisite_status: item.prerequisite_status,
        questions: answers
          ?.map((question, index) => `${question.answer}`.replaceAll(',', ' '))
          .join(','),
      }
    })
    const csv = simplifiedReports
      ?.map((item) => {
        return Object.values(item).join(',')
      })
      .join('\n')
    // add headers
    const csvWithHeaders = [headers.join(','), csv].join('\n')
    const blob = new Blob([csvWithHeaders], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('hidden', '')
    a.setAttribute('href', url)
    a.setAttribute('download', 'report.csv')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    // downloaded
  }

  async function filterStartDate(e) {
    setStartDate(e)
    // getReport()
  }

  async function filterEndDate(e) {
    setEndDate(e)
    // getReport()
  }

  useEffect(() => {
    // FIXME: Temporary check for admin
    if (!location.state || !location.state.id) {
      navigate(userType === 'admin' ? '/admin/reports/detailed' : '/')
      // return
    }
    getReport()
  }, [])

  return (
    <>
      <div className="row p-0 nav-area no-gutters">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0 no-gutters">
            <div className="col-2">
              <NavBar user={'admin'} />
            </div>
            <div className="col-10 admin-request">
              <div
                className="row mb-4"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div className="col-xl-12 row">
                  <h2 className="instructor-title">Reports</h2>
                </div>
                <div>
                  {/** REPORTS FILTERS */}
                  <div className="col-xl-12 row">
                    <div className="row">
                      <div className="col-xl-2 row">
                        <label htmlFor="" className="mb-2">
                          Course Type
                        </label>
                        <ReactSelectField
                          placeholder={'Status'}
                          options={[
                            { label: 'All', value: '' },
                            { label: 'Adult', value: 1 },
                            { label: 'Youth', value: 2 },
                          ]}
                          clear={false}
                          onChange={(e) => {
                            setCourseFilter(e)
                            // change course filter here
                          }}
                          value={courseFilter}
                        />
                      </div>
                      <div className="col-xl-7 row">
                        <div className="col-xl-4">
                          <label htmlFor="">Start Date</label>
                          <DateField
                            placeholder="Start Date"
                            selected={start_date}
                            onChange={filterStartDate}
                            customInput={<DateCustomInput id="start_date" />}
                          />
                        </div>
                        <div className="col-xl-4 mt-2 mt-xl-0">
                          <label htmlFor="">End Date</label>
                          <DateField
                            placeholder="End Date"
                            selected={end_date}
                            onChange={filterEndDate}
                            minDate={start_date}
                            customInput={<DateCustomInput id="end_date" />}
                          />
                        </div>
                        <div className="col-xl-4 mt-2 mt-xl-4">
                          <Button onClick={getReport}>Search</Button>
                        </div>
                      </div>
                      <div className="col-md-4 col-xl-3 mt-4 mt-xl-4">
                        <Button onClick={downloadReport}>
                          Download Report
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row bg-white rounded no-gutters table-responsive">
                {loading ? (
                  <Spinner />
                ) : (
                  <Table
                    columns={tableColumns}
                    className={'insCertTable'}
                    data={
                      courseFilter.value === ''
                        ? reports
                        : reports.filter(
                            (item) =>
                              parseInt(item.course_id) ===
                              parseInt(courseFilter.value)
                          )
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
