import React, { memo } from "react";

import ottGroupSvg from "../../assets/svg/ott-group.svg";
import logoPng from "../../assets/png/site-logo.png";
import ErrorMessage from "../Common/ErrorMessage";
import Spinner from "../Spinner/Spinner";

import PropTypes from "prop-types";

const OtpVerify = ({
  email,
  otpError,
  otp,
  handleOtpVerify,
  handleResend,
  handleChangeEmail,
  handleOtpChange,
  otpLoading,
}) => {
  return (
    <>
      <div className="">
        <div className={"row no-gutters  rowReverse heightI"}>
          <div className="col-lg-6">
            <div
              className="row no-gutters p-3 pt-5 maskGroupBg"
              style={{ height: "100%" }}
            >
              <img src={ottGroupSvg} width="80%" className="image-otp" />
            </div>
          </div>
          <div className="col-lg-6 p-3">
            <div className="row no-gutters">
              <div className="ptext" style={{ textAlign: "right" }}>
                Need assistance?{" "}
                <b className="orangeText">Contact us for help</b>
              </div>
              <div className="formRight">
                <div className="mt-5 row no-gutters">
                  <div className="text-center">
                    <img src={logoPng} width={"200"} />
                  </div>
                </div>
                <div className="mt-5 sendText">
                  An application code has been sent to <br />
                  <b className="orangeText">{email}</b>
                </div>
                <div className="sendText mt-3">Kindly check your email</div>
                <div className="head text-center mt-3">
                  Enter Your Application Code
                </div>

                <div className="text-center mt-5 p-2">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      id={`otp${index + 1}`}
                      className="otpWrapper"
                      maxLength={1}
                      value={value}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                    />
                  ))}
                  <ErrorMessage
                    visible={otpError}
                    error="Please enter valid OTP"
                  />
                </div>
                <div className="text-center">
                  <button
                    onClick={handleOtpVerify}
                    className="mt-5 buttonWrapper"
                  >
                    {otpLoading ? (
                      <Spinner
                        sx={{
                          ".MuiCircularProgress-root": {
                            color: "white",
                          },
                        }}
                        size="12px"
                      />
                    ) : (
                      "Verify"
                    )}
                  </button>
                </div>
                <div className="text-center">
                  Didn’t receive code?{" "}
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => handleResend()}
                  >
                    Resend Code
                  </span>
                </div>
                <div className="text-center">
                  <a
                    href="javascript:void(0)"
                    className="blueLink"
                    onClick={(e) => handleChangeEmail()}
                  >
                    Change Email
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

OtpVerify.propTypes = {
  email: PropTypes.string,
  otpError: PropTypes.bool,
  otp: PropTypes.arrayOf(PropTypes.string),
  handleOtpVerify: PropTypes.func,
  handleResend: PropTypes.func,
  handleChangeEmail: PropTypes.func,
  handleOtpChange: PropTypes.func,
  otpLoading: PropTypes.bool,
};

export default memo(OtpVerify);
