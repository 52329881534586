import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { storeToken } from '../../auth/storage'
import api, { CHECK_ADMIN_REQUEST_EMAIL } from '../../api/graphql'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import ellipseSvg from '../../assets/svg/Ellipse.svg'

import './AdminLogin.css'
import React from 'react'
import { useUser } from '../../auth/AuthState'
import OtpVerify from '../../components/OtpVerify/OtpVerify'
import PasswordVerify from '../../components/PasswordVerify/PasswordVerify'
import EmailVerify from '../../components/EmailVerify/EmailVerify'
import { isEmail } from '../../utils/ValidationUtil'
import { focusInputError } from '../../utils/inputUtil'
import { USER_ROLE } from '../../utils/common'

const HostLogin = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [showPass, setShowPass] = useState(false)
  const [showOTTProcess, setShowOTTProcess] = useState(false)
  const [passError, setPassError] = useState(false)
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [otpLoading, setOTPLoading] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [emailError, setEmailError] = useState(false)

  const { dispatch } = useUser()
  const [CHECK_EMAIL, { loading: emailLoading }] = useMutation(
    CHECK_ADMIN_REQUEST_EMAIL
  )

  const checkPassword = async () => {
    if (!password) setPassError(true)
    setPasswordLoading(true)
    const response = await api.checkUser(email, password, 'admin')
    setPasswordLoading(false)

    if (
      response.CheckUserDetails.success &&
      response.CheckUserDetails.status === 200
    ) {
      localStorage.setItem('userType', 'admin')
      dispatch({ type: 'USER_TYPE', payload: 'admin' })
      storeToken(response?.CheckUserDetails?.token)
      localStorage.setItem('otpVerified', true)
      navigate('/admin/request')
      return
    } else {
      toast.error('Invalid Password')
      setPassError(true)
      return
    }
  }

  const sendEmail = async () => {
    setEmailError(false)
    if (email == '' || !isEmail(email)) {
      focusInputError('emailId', true)
      setEmailError(true)
      return
    }
    setEmail(email?.trim()?.toLowerCase())
    const { data } = await CHECK_EMAIL({
      variables: {
        email: email?.trim()?.toLowerCase(),
        roleId: USER_ROLE.ADMIN,
      },
    })

    if (data?.CheckAdminOrRequestEmail?.success) {
      localStorage.setItem('emailKey', email)
      localStorage.setItem(
        'userPermissions',
        JSON.stringify(data?.CheckAdminOrRequestEmail?.permissions)
      )
      if (data?.CheckAdminOrRequestEmail?.isMHA) {
        setShowPass(true)
      } else {
        setShowOTTProcess(true)
      }
    } else {
      toast.error('Unauthorized User')
    }
  }

  const handleChangeEmail = () => {
    setEmail('')
    setShowOTTProcess(false)
    document.getElementById('emailId') &&
      document.getElementById('emailId').focus()
    return
  }

  const handleOtpChange = (index, value) => {
    setOtpError(false)
    const newOtp = [...otp]
    newOtp[index] = value

    if (index < 5 && value) {
      const nextInput = document.getElementById(`otp${index + 2}`)
      if (nextInput) {
        nextInput.focus()
      }
    }

    setOtp(newOtp)
  }

  const handleResend = async () => {
    setOtp(['', '', '', '', '', ''])
    setOtpError(false)
    const res = await api.resendOtp(email)
    if (res.ResendOtp.success && res.ResendOtp.status === 200) {
      toast.success('Otp send successfully in Email')
    } else {
      toast.error('Something went to wrong please try again')
    }
  }

  const handleOtpVerify = async () => {
    if (otp?.length < 6) {
      setOtpError(true)
      return
    }
    setOTPLoading(true)
    const res = await api.otpVerify(email, otp?.join(''), 'admin')
    if (res.VerifyOtp.success && res.VerifyOtp.status === 200) {
      localStorage.setItem('otpVerified', true)
      localStorage.setItem('userType', 'admin')
      dispatch({ type: 'USER_TYPE', payload: 'admin' })
      navigate('/admin/request')
      setOTPLoading(false)
    } else {
      setOtpError(true)
      setOTPLoading(false)
    }
    setOTPLoading(false)
  }

  return (
    <>
      <div className="row no-gutters pt-2 mb-1">
        <div className="col-4 ps-5">
          <div className="row no-gutters">
            <a
              href="https://www.mentalhealthfirstaid.org/become-an-instructor/"
              target="_blank"
            >
              <img
                alt="main-side"
                src={require('../../assets/png/header-logo.png')}
                height={'50px'}
              />
            </a>
          </div>
        </div>
      </div>
      <>
        {showOTTProcess ? (
          <>
            <OtpVerify
              email={email}
              otpError={otpError}
              handleOtpVerify={handleOtpVerify}
              handleResend={handleResend}
              handleChangeEmail={handleChangeEmail}
              handleOtpChange={handleOtpChange}
              otp={otp}
              otpLoading={otpLoading}
            />
          </>
        ) : (
          <>
            <div className="">
              <div className={'row no-gutters  rowReverse heightI'}>
                <>
                  <div className="col-lg-6 p-5   bgGradMaskGroupBg  ">
                    <div>
                      <img
                        className="image6Icon"
                        alt=""
                        src={require('../../assets/png/site-logo.png')}
                        height={500}
                        width={500}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 p-3 ">
                    <div className="row no-gutters">
                      <div className="formRight">
                        <div className="mt-5 row no-gutters emailHead">
                          Admin Account
                        </div>
                        {showPass ? (
                          <PasswordVerify
                            password={password}
                            passError={passError}
                            passwordLoading={passwordLoading}
                            setPassError={setPassError}
                            setPassword={setPassword}
                            checkPassword={checkPassword}
                          />
                        ) : (
                          <EmailVerify
                            email={email}
                            emailError={emailError}
                            emailLoading={emailLoading}
                            setEmail={setEmail}
                            setEmailError={setEmailError}
                            focusInputError={focusInputError}
                            isEmail={isEmail}
                            sendEmail={sendEmail}
                          />
                        )}
                        <div>
                          <div className="orienttext">
                            Orientation Information
                          </div>
                          <div className="line"></div>
                          <div>
                            <div className="row p-2">
                              <div className="circle">
                                <img src={ellipseSvg} />
                              </div>
                              <div className="col-11 litext">
                                Sed ut perspiciatis unde omnis iste natus error
                                sit voluptatem.
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="circle">
                                <img src={ellipseSvg} />
                              </div>
                              <div className="col-11 litext">
                                Qui dolorem ipsum quia dolor sit amet,
                                consectetur, adipisci velit.
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="circle">
                                <img src={ellipseSvg} />
                              </div>
                              <div className="col-11 litext">
                                Sed ut perspiciatis unde omnis iste natus error
                                sit voluptatem.
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="circle">
                                <img src={ellipseSvg} />
                              </div>
                              <div className="col-11 litext">
                                Sed ut perspiciatis unde omnis iste natus error
                                sit voluptatem.
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="circle">
                                <img src={ellipseSvg} />
                              </div>
                              <div className="col-11 litext">
                                Qui dolorem ipsum quia dolor sit amet,
                                consectetur, adipisci velit.
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="circle">
                                <img src={ellipseSvg} />
                              </div>
                              <div className="col-11 litext">
                                Sed quia non numquam eius modi tempora incidunt
                                ut labore
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </>
    </>
  )
}

export default HostLogin
