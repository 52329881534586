import React from "react";
import Image4 from "../../assets/png/image-6.png";
import Image5 from "../../assets/png/image-7-1.png";
import Interscet2 from "../../assets/png/intersect-1.png";
import Interscet3 from "../../assets/png/intersect.png";
const ImageOverlapLower = () => {
  return (
    <div className="group-3">
      <div className="div-wrapper">
        <div className="overlap-group-3">
          <img className="image-4" alt="" src={Image4} />
          <img className="image-5" alt="" src={Image5} />
          <img className="intersect-2" alt="Intersect" src={Interscet2} />
        </div>
      </div>
      <div className="intersect-wrapper">
        <img className="intersect-3" alt="Intersect" src={Interscet3} />
      </div>
    </div>
  );
};

export default ImageOverlapLower;
