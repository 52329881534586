import { useParams } from 'react-router-dom'
import Header from '../../components/Header/Header'
import NavBar from '../../components/Navbar/Navbar'
import { useMutation, useQuery } from '@apollo/client'
import { GET_SUPPORT_BY_ID, UPDATE_SUPPORT } from '../../api/graphql'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './AdminSupportSingleModule.css'
import { ChevronLeft } from '@mui/icons-material'
import Spinner from '../../components/Spinner/Spinner'
import ReactSelectField from '../../components/ReactSelectField/ReactSelectField'
import Button from '../../components/Button/Button'
import moment from 'moment'

export default function AdminSupportSingle() {
  const params = useParams()
  const { loading: supportLoading, refetch } = useQuery(GET_SUPPORT_BY_ID, {
    variables: { supportId: parseInt(params.supportId) },
  })
  const [updateSupport, { loading: updatingSupport }] =
    useMutation(UPDATE_SUPPORT)
  const [support, setSupport] = useState({})
  const [status, setStatus] = useState('')
  const navigate = useNavigate()
  const userType = localStorage.getItem('userType')
  const [supportNotes, setSupportNotes] = useState(<>&nbsp;</>)

  async function getSupport() {
    const { data } = await refetch()
    setSupport(data?.GetSupportById.data)
    setStatus({
      label: data?.GetSupportById.data?.status,
      value: data?.GetSupportById.data?.status,
    })
  }

  async function updateSupportStatus() {
    await updateSupport({
      variables: {
        supportId: parseInt(params.supportId),
        status: status.value,
      },
    })
    getSupport()
  }

  useEffect(() => {
    getSupport()
  }, [])
  return (
    <div className="row p-0 nav-area no-gutters">
      <div className="row no-gutters p-sm-0 p-lg-3">
        <Header />
        <div className="row p-0 no-gutters">
          <div className="col-2">
            <NavBar user={'admin'} />
          </div>
          <div className="col-10 row admin-request">
            <div className="row">
              <div className="mb-2 p-0">
                <a
                  href="javascript:void(0)"
                  onClick={(e) =>
                    navigate(userType === 'admin' ? `/admin/support` : '/')
                  }
                  className="back-link"
                >
                  <ChevronLeft />
                  Back
                </a>
              </div>
            </div>
            <div className="row">
              {supportLoading ? (
                <>
                  <Spinner />
                </>
              ) : (
                <>
                  <div className="support-item">
                    {/* Display Support Item here, with a dropdown for Status */}
                    <div className="row mb-4">
                      <div className="col-6">
                        <ReactSelectField
                          label={'Status'}
                          options={[
                            { label: 'New', value: 'New' },
                            { label: 'Open', value: 'Open' },
                            { label: 'Closed', value: 'Closed' },
                          ]}
                          clear={false}
                          value={status}
                          onChange={(e) => {
                            setStatus(e)
                          }}
                        />
                      </div>
                      <div
                        className="col-2"
                        style={{
                          display: 'flex',
                          alignItems: 'end',
                          marginBottom: '0.25rem',
                        }}
                      >
                        <Button
                          onClick={updateSupportStatus}
                          disabled={
                            status?.value === undefined ||
                            support?.status === status?.value
                          }
                        >
                          {updatingSupport ? 'Updating...' : 'Update'}
                        </Button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <label className="heading">Created At</label>
                        <p>
                          {new Date(
                            parseInt(support?.created_at)
                          ).toLocaleDateString('en-US')}
                        </p>
                      </div>
                      <div className="col-6">
                        <label>User ID</label>
                        <p>{support?.prevEmail}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <label className="heading">First Name</label>
                        <p>{support?.first_name}</p>
                      </div>
                      <div className="col-6">
                        <label>Last Name</label>
                        <p>{support?.last_name}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <label>Previous Email</label>
                        <p>{support?.email}</p>
                      </div>
                      <div className="col-6">
                        <label>State</label>
                        <p>{support?.state}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <label>Notes</label>
                        <p>
                          {support?.notes ? (
                            <>
                              <div
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Searched LearnUpon for {support?.email}:
                              </div>
                              {support?.notes?.lmsData?.map((note, index) => (
                                <div>
                                  {index + 1}: {note?.first_name}{' '}
                                  {note?.last_name}, {support?.state},{' '}
                                  {note?.email}, {note?.course_name},{' '}
                                  {note?.course_source_id},{' '}
                                  {note?.certificate_name},{' '}
                                  {moment(note?.date_completed).format(
                                    'MM-DD-YYYY'
                                  )}
                                </div>
                              ))}
                              {support?.notes?.lmsData?.length === 0 && (
                                <div>No Results Found</div>
                              )}
                              <div
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Searched CRM for {support?.email}:
                              </div>
                              {support?.notes?.crmData?.map((note, index) => (
                                <div>
                                  {index + 1}: {note?.first_name}{' '}
                                  {note?.last_name}, {support?.state},{' '}
                                  {note?.email}, {note?.course_name},{' '}
                                  {note?.course_id}, {note?.certificate_name},{' '}
                                  {moment(note?.date).format('MM-DD-YYYY')}
                                </div>
                              ))}
                              {support?.notes?.crmData?.length === 0 && (
                                <div>No Results Found</div>
                              )}
                            </>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
